import { Link } from "react-router-dom";
import logo from "../assets/logos/logo.png";
import 页脚宫殿 from "../assets/三国风插画/页脚_宫殿.webp";

import classes from "./footer.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

/**
 * 渲染一个页脚
 *
 * theme为light时，渲染一个浅色页脚
 */
function Footer({ theme = "dark" }) {
  // 根据theme属性动态设置data-theme属性
  const themeAttribute = theme === "dark" ? "dark" : "light";

  return (
    <footer data-theme={themeAttribute}>
      <div className={classes.about_us}>
        {/* <img src={logo} /> */}
        {/* <h2>曹操WIFI</h2> */}
        <p>
          曹操wifi 是一个基于云的平台，提供专业的WPA-PBKDF2-PMKID
          (EAPOL)类型密码强度测试和恢复服务。
        </p>
        <p>
          曹操wifi强调法律和道德合规性，为强度测试和恢复密码提供强大的工具，同时确保所有操作都符合专业标准。
        </p>
      </div>
      {/* <div className={classes.list}>
        <h3>联系我们</h3>
        <p>QQ:2694144457</p>
        <p>
          官网:<a href="https://www.ccwifi.cc">www.ccwifi.cc</a>
        </p>
        <p>
          B站:
          <a
            href="https://space.bilibili.com/1015503991/dynamic"
            target="_blank"
          >
            曹操wifi
          </a>
        </p>
      </div> */}

      <div className={classes.list}>
        <h3>友情链接</h3>
        <p>
          <a target="_blank" href="https://www.ccwifi.cc/blogs/">
            量子哈希
          </a>
        </p>
        <p>
          <a target="_blank" href="http://www.gdda.vip/">
            哈希极客
          </a>
        </p>
        <p>
          <a target="_blank" href="https://nnnxxx.cn/">
            奇伟华青
          </a>
        </p>
        <p>
          <a target="_blank" href="https://www.mzbky.com/">
            梦泽贝壳
          </a>
        </p>
        <p></p>
      </div>
      <div className={classes.list}>
        <h3>常见问题</h3>

        <p>
          <a
            href="https://www.ccwifi.cc/blogs/index.php/2024/04/19/%e5%b8%b8%e8%a7%81%e9%97%ae%e9%a2%98/"
            target="_blank"
          >
            {" "}
            常见问题
          </a>
        </p>

        <p>
          <a
            target="_blank"
            href="https://www.ccwifi.cc/blogs/index.php/2024/03/17/%e5%9f%ba%e7%a1%80%e3%80%81%e8%bf%9b%e9%98%b6%e3%80%81%e9%87%91%e5%88%9a%e5%8c%85%e9%83%bd%e6%98%af%e4%bb%80%e4%b9%88%ef%bc%9f/"
          >
            关于定价
          </a>
        </p>
        {/* <p>
          <a target="_blank" href="https://www.ccwifi.cc/blogs/">
            为什么要预付电费
          </a>
        </p>
        <p>
          <a target="_blank" href="https://www.ccwifi.cc/blogs/">
            什么是基础包 进阶包
          </a>
        </p> */}

        <p>
          <a
            target="_blank"
            href="https://www.ccwifi.cc/blogs/index.php/2024/03/17/%e4%bb%80%e4%b9%88%e6%98%af%e5%ae%9a%e5%88%b6%e5%8c%96%e4%bb%bb%e5%8a%a1/"
          >
            什么是定制化任务
          </a>
        </p>
      </div>

      <div id={classes.法律声明}>
        <h3>法律声明</h3>
        <p>使用我们的服务，您同意：</p>
        <ul>
          <li>
            您阅读、理解并同意我们的
            <a
              target="_blank"
              href="https://www.ccwifi.cc/blogs/index.php/2024/03/28/%e4%bd%bf%e7%94%a8%e6%9d%a1%e6%ac%be/"
            >
              条款、条件
            </a>
            和
            <a
              target="_blank"
              href="https://www.ccwifi.cc/blogs/index.php/2024/04/18/%e9%9a%90%e7%a7%81%e6%8c%87%e5%8d%97/"
            >
              隐私政策
            </a>
          </li>
          <li>您没有违反您所在国家/地区存在的任何法律或法规;</li>
          <li>文件或哈希值是以合法方式收集的; </li>
        </ul>
        {/* <p>
          <a target="_blank" href="https://www.ccwifi.cc/blogs/">
            为什么要预付电费
          </a>
        </p>
        <p>
          <a target="_blank" href="https://www.ccwifi.cc/blogs/">
            什么是基础包 进阶包
          </a>
        </p> */}
      </div>

      <LazyLoadImage
        offset={200}
        id={classes.页脚宫殿}
        src={页脚宫殿}
        alt="显卡工作站_三兵马"
        style={{ display: `${theme === "dark" ? "block" : "none"}` }}
      />
    </footer>
  );
}

export default Footer;
