import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { configure } from "../Stores/configure";

const serverAddress = configure.getInitialState().serverAddress;

export const TaskApi = createApi({
  reducerPath: "TaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serverAddress + "/task",
    prepareHeaders: (headers, { getState }) => {
      // 尝试从 localStorage 中获取 token
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["task"],
  endpoints(build) {
    return {
      getAllTasks: build.query({
        providesTags: ["task"],
        query() {
          return "/";
        },
      }),

      postOne: build.mutation({
        invalidatesTags: ["task"],
        query(stu) {
          return {
            url: `/`,
            method: "POST",
            body: stu,
          };
        },
      }),

      deleteOneTask: build.mutation({
        invalidatesTags: ["task"],
        query(stu) {
          console.log(stu);
          return {
            url: `/${stu}`,
            method: "DELETE",
            body: stu,
          };
        },
      }),

      specialDeleteOneTask: build.mutation({
        invalidatesTags: ["task"],
        query({ business_id, task_id }) {
          //console.log(business_id, task_id);
          return {
            url: `/${business_id}/${task_id}`,
            method: "DELETE",
          };
        },
      }),

      restartTask: build.mutation({
        query: ({ task_id, failedOnly }) => ({
          url: `restart/${task_id || ""}${failedOnly ? "?failedOnly=true" : ""}`,
          method: "POST",
        }),
      }),

      postChadui: build.mutation({
        query: ({ businessId }) => ({
          url: `/chadui/${businessId}`,
          method: "POST",
        }),
      }),
    };
  },
});

export const {
  useGetAllTasksQuery,
  usePostOneMutation,
  useDeleteOneTaskMutation,
  useSpecialDeleteOneTaskMutation,
  useRestartTaskMutation,
  usePostChaduiMutation,
} = TaskApi;
