import React, { useState, useEffect } from "react";
import 客户端电脑端 from "./Client.desktop";
import 客户端移动端 from "./Client.mobile";

const Client = () => {
  const [isDesktop, setIsDesktop] = useState(false); // 初始假设为移动端

  useEffect(() => {
    const updateDeviceType = () => {
      // 你可以根据需要调整这个阈值
      const isWidthGreaterThan768 = window.innerWidth > 768;
      setIsDesktop(isWidthGreaterThan768);
    };

    // 组件挂载后执行一次
    updateDeviceType();

    // 监听窗口大小变化
    const handleResize = () => {
      updateDeviceType();
    };

    window.addEventListener("resize", handleResize);

    // 组件卸载时清理
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 空依赖数组，确保只在组件挂载和卸载时运行

  // 根据设备类型条件渲染
  return isDesktop ? <客户端电脑端 /> : <客户端移动端 />;
};

export default Client;
