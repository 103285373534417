import { Button, Input, Space, message, Table } from "antd";
import Modal from "antd/es/modal/Modal";
import React, { useState } from "react";
import { calculatePossibilities } from "../Client/utils";
import { usePostOneMutation } from "../Api/TaskApi";

/**
 * # SnapchatAI组件
 * 导入json，快速批量新增Snapchat任务。
 *
 * ## State
 *
 *
 * ## 函数
 * - handleChange（）
 * - 确认解析（）
 */
const SnapchatAi = ({ visiableAi, toggle, business_id, wifi_name }) => {
  const [parsedJson, setparsedJson] = useState();
  const [taskList, settaskList] = useState([]);

  const [postTask] = usePostOneMutation();

  const handleChange = (e) => {
    // const json=e.target.value
    setparsedJson(e.target.value);
    settaskList([]);

    try {
      const json = JSON.parse(e.target.value);
      // console.log(json);

      Array.from(json).forEach((mask) => {
        const { total, Size } = calculatePossibilities(mask);
        const Task = {
          wifi_name: wifi_name,
          business_object_id: business_id,
          zidian_name: `Snapchat:AI：${mask}`,
          mask,
          status: "wating",
          priority: 4,
          total,
          Size,
        };

        Task.Snapchat = {
          type: "Snapchat",
          Snapchat_type: "3",
          ...Task,
        };

        settaskList((state) => {
          return [...state, Task];
        });
      });
    } catch (e) {
      // message.error("JSON格式错误");
    }
  };

  function 确认解析() {
    taskList.forEach((task) => {
      post(task);
    });
  }

  //2.封装的post 提交任务
  async function post(tem) {
    console.log(tem);

    await postTask(tem);
    message.success("任务提交成功");
  }

  return (
    <Modal
      title="Snapchat AI"
      open={visiableAi}
      footer={null}
      onCancel={toggle}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input.TextArea
          value={parsedJson}
          onChange={handleChange}
          rows={10}
          placeholder="在这里输入JSON"
        />

        <Button type="primary" onClick={确认解析} block>
          确认解析
        </Button>
      </Space>

      <Table
        style={{ marginTop: "1.2rem" }}
        columns={[
          {
            title: "任务名",
            dataIndex: "mask",
          },
          {
            title: "容量(GB)",
            dataIndex: "Size",
          },
          {
            title: "行数",
            dataIndex: "total",
          },
        ]}
        dataSource={taskList}
      />
    </Modal>
  );
};

export default SnapchatAi;
