import React from "react";
import classes from "./Control.module.css";
import Nav from "./Nav";
import { Outlet } from "react-router-dom";

/**
 * Control组件，负责整合左侧的导航和右侧的页面
 */
function Control() {
  return (
    <div className={classes.hander_nav}>
      <Nav />
      <Outlet />
    </div>
  );
}

export default Control;
