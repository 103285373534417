import { Modal, Spin, Statistic, message } from "antd";
import React, { useEffect, useState } from "react";
import { useCreate_orderMutation, useOrder_statusQuery } from "../Api/PayApi";
import { Alert } from "antd";
import Marquee from "react-fast-marquee";
const { Countdown } = Statistic;
import classes from "./Pay.module.css";
import alipay from "../assets/alipay-logo.svg";

/**
 * # Pay组件，用于处理支付信息。
 *
 * ## State
 * - `order_data`：存放创建的订单信息。
 *
 * ## 函数
 * - `create_order`：创建订单的mutation函数。
 * - `useOrder_statusQuery`：不停查询订单状态的query函数
 */
function Pay(params) {
  if (params.open === false) return;

  //存放创建的订单信息

  const [order_data, put_order_data] = useState();

  //api
  const [create_order, result] = useCreate_orderMutation();

  const wating_message = useOrder_statusQuery(order_data?.order_no, {
    pollingInterval: 1000,
  });

  useEffect(function init() {
    (async () => {
      if (!params?.data) return;
      const result = create_order({
        business_id: params.data.business_id,
        wifi_name: params.data.wifi_name,
        subject: params.data.subject,
        pay_type: params.data.pay_type,
      });
    })();
  }, []);

  useEffect(() => {
    if (!result?.isSuccess) return;
    if (result?.data) {
      put_order_data(result.data.data);
    }

    if (result?.data?.status !== "scuess") {
      console.log(result);
      message.error(`支付请求失败 请联系客服付款${result?.data?.reason}`);
    }
  }, [result]);

  useEffect(() => {
    if (wating_message?.data?.order_status === "success") {
      console.log("支付成功");
      message.success("支付成功");
      params.onCancel();
    }
  }, [wating_message]);

  return (
    <Modal
      open={params?.open}
      okButtonProps={{ disabled: true, style: { display: "none" } }}
      cancelButtonProps={{ disabled: true, style: { display: "none" } }}
      onCancel={params.onCancel}
    >
      {result.isLoading && <Spin />}
      <div className={classes.container}>
        {/* <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              请严格按照显示金额支付，否则无法到账
            </Marquee>
          }
        /> */}
        <img src={alipay} id={classes.alipay} />
        <h1 className={classes.charge}>￥{order_data?.realmoney}元</h1>
        <img src={order_data?.qr_img} className={classes.img} />
        <p id={classes.dingdanhao}>订单号：{order_data?.order_no}</p>
        <Countdown value={new Date(order_data?.expires_in).getTime()} />
      </div>
    </Modal>
  );
}

export default React.memo(Pay);
