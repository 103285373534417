import React from "react";
import classes from "./Info.module.css";
import {
  WifiHigh,
  HourglassHigh,
  Confetti,
  Flag,
  Package,
  Hammer,
  Gavel,
  SealQuestion,
  Question,
  PushPin,
  ReceiptX,
  ShoppingCartSimple,
  Crown,
  Bird,
} from "@phosphor-icons/react";
import {
  Button,
  notification,
  Spin,
  Alert,
  Card,
  Badge,
  Descriptions,
  Modal,
  Popover,
  message,
  Collapse,
  Popconfirm,
  Typography,
  theme,
} from "antd";

/**
 * ### Info组件
 * 用来现实反馈页上方的信息部分
 * @returns
 */
const Info = ({ BusinessData, put_pay_modal_data, put_pay_modal_open }) => {
  if (!BusinessData?.wifi_name) return <Spin />;

  return (
    <section data-description="信息部分" className={classes.info_section}>
      <div className={classes.title}>
        <Typography.Title level={1}>
          WIFI名称:{BusinessData.wifi_name}
        </Typography.Title>
      </div>
      <div className={classes.desctiption}>
        <article>
          <h3>任务信息</h3>

          <p>城市：{BusinessData.city}</p>
          <p>咸鱼昵称：{BusinessData.xianyu_name}</p>
          <p>
            提交时间：
            {new Date(BusinessData.create_at).toLocaleString("zh-CN")}
          </p>

          {(function return_包类型() {
            if (
              BusinessData?.charge_type === undefined ||
              BusinessData?.charge_type === null
            )
              return;

            switch (BusinessData?.charge_type) {
              case 0:
                return (
                  <Popover
                    title="菜鸟包"
                    content=<p>
                      免费任务 跑出收费15元 不收取预付电费
                      <a
                        href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                        target="_blank"
                      >
                        了解详情
                      </a>
                    </p>
                    placement="topLeft"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <p style={{ position: "relative" }}>
                        包类型：菜鸟包{" "}
                        <strong className={classes.money}>￥15</strong>
                      </p>
                      <Bird size={32} />
                    </div>
                  </Popover>
                );

              case 1:
                return (
                  <Popover
                    title="基础包"
                    content=<p>
                      预付电费1元 跑出收费20元 基础任务
                      <a
                        href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                        target="_blank"
                      >
                        了解详情
                      </a>
                    </p>
                    placement="topLeft"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <p style={{ position: "relative" }}>
                        包类型：基础包{" "}
                        <strong className={classes.money}>￥20</strong>
                      </p>

                      <Package size={32} />
                    </div>
                  </Popover>
                );
              case 2:
                return (
                  <Popover
                    title="进阶包"
                    content=<p>
                      预付电费2元 跑出收费35元 进阶任务
                      <a
                        href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                        target="_blank"
                      >
                        了解详情
                      </a>
                    </p>
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      包类型：进阶包
                      <Hammer size={32} />
                    </p>
                  </Popover>
                );
              case 3:
                return (
                  <Popover
                    title="进阶包"
                    content=<p>
                      预付电费5元 跑出收费50元 金刚任务
                      <a
                        href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                        target="_blank"
                      >
                        了解详情
                      </a>
                    </p>
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      包类型：金刚包
                      <Gavel size={32} />
                    </p>
                  </Popover>
                );
            }
          })()}

          <p>
            客户端：
            <a href="/client" target="_blank">
              查看其他订单
            </a>
          </p>
        </article>
        <article>
          <h3>定制信息</h3>
          <p>主人姓名：{BusinessData.wifi_owner_name}</p>
          <p>主人姓氏：{BusinessData.wifi_owner_xing}</p>
          <p>备注：{BusinessData.note}</p>
        </article>
        <article id={classes.renwuzhuangtai}>
          <h3>任务状态</h3>

          {(function render_status() {
            if (!BusinessData || BusinessData == "init") return;

            if (BusinessData?.charge_type === 0) {
              return (
                <Popover
                  title="开始基础包"
                  content={
                    <div>
                      <p>基础包 70GB+字典 需要预付一元电费</p>
                      <p>
                        跑出收费20 未跑出<strong>不收费</strong>
                      </p>
                      <a
                        href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                        target="_blank"
                      >
                        了解详情
                      </a>
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    // icon={<Package size={14} />}
                    size="middle"
                    style={{
                      marginTop: "12px",
                      display: `${
                        BusinessData?.status !== "solved" &&
                        BusinessData?.charge_type === 0
                          ? "block"
                          : "none"
                      }`,
                    }}
                    onClick={function 正式开始任务() {
                      if (BusinessData?.charge_type === 1) {
                        message.warning("已经是基础包");
                        return;
                      }

                      let pay_data = { subject: "prepaid_1" };

                      pay_data.business_id = BusinessData._id;
                      pay_data.wifi_name = BusinessData.wifi_name;
                      pay_data.pay_type = "43";

                      put_pay_modal_data(pay_data);
                      put_pay_modal_open(true);
                    }}
                  >
                    正式开始任务🚀
                  </Button>
                </Popover>
              );
            }

            const status = {
              waitCheck: (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <p>等待用户预付电费</p>
                  <HourglassHigh size={32} />
                </span>
              ),
              running: (
                <span style={{ marginBottom: "1.2rem", display: "block" }}>
                  <Spin tip="正在进行中" size="default">
                    <div className="content" />
                  </Spin>
                </span>
              ),
              finished: (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <p>😿跑完 未出 请升级任务😿</p>
                  {/* <Flag size={32} /> */}
                </span>
              ),
              solved: (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <p>🥳已跑出密码🥳</p>
                </span>
              ),
            };

            return status[BusinessData?.status];
          })()}

          <div
            data-description="负责操作升级，获取密码，付款等操作"
            className={classes.impove_level}
            style={{
              display: `${
                BusinessData?.status === "waitCheck" ? "none" : "grid"
              }`,
            }}
          >
            <Popover
              title="升级进阶包"
              content={
                <div>
                  <p>进阶包 额外70GB+字典 出包率35%-45% 需要预付两元电费</p>
                  <p>
                    跑出收费35 未跑出依然<strong>不收费</strong>
                  </p>
                  <a
                    href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                    target="_blank"
                  >
                    了解详情
                  </a>
                </div>
              }
            >
              <Button
                type="primary"
                size="middle"
                // icon={<Hammer size={14} style={{ color: "#ffffff" }} />}
                style={{
                  display: `${
                    BusinessData?.status !== "solved" &&
                    BusinessData?.charge_type === 1
                      ? "block"
                      : "none"
                  }`,
                }}
                onClick={function shengji_jinjie() {
                  if (BusinessData?.status == "solved" || false) {
                    message.warning("现在无法升级");
                    return;
                  }

                  if (BusinessData?.charge_type >= 2) {
                    message.warning("无法再次升级");
                    return;
                  }

                  let pay_data = { subject: "prepaid_2" };

                  pay_data.business_id = BusinessData._id;
                  pay_data.wifi_name = BusinessData.wifi_name;
                  pay_data.pay_type = "43";

                  put_pay_modal_data(pay_data);
                  put_pay_modal_open(true);

                  // patchStatus({
                  //   id: BusinessData._id,
                  //   status:
                  //     BusinessData?.status === "finished"
                  //       ? "waitCheck"
                  //       : undefined,
                  //   charge_type: 2,
                  // });
                }}
              >
                升级进阶包🔨
              </Button>
            </Popover>
            <Popover
              title="金刚包"
              content={
                <div>
                  <p>来吧 只要你不放弃 我们奉陪到底⚒️</p>
                  <p>跑出收费 50</p>
                  <a
                    href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                    target="_blank"
                  >
                    了解详情
                  </a>
                </div>
              }
            >
              <Button
                type="primary"
                size="middle"
                style={{
                  display: `${
                    BusinessData?.status !== "solved" &&
                    BusinessData?.charge_type === 2
                      ? "block"
                      : "none"
                  }`,
                }}
                onClick={function shengji_jingang() {
                  if (BusinessData?.status == "solved" || false) {
                    message.warning("现在无法升级");
                    return;
                  }

                  if (BusinessData?.charge_type >= 3) {
                    message.warning("无法再次升级");
                    return;
                  }

                  let pay_data = { subject: "prepaid_3" };

                  pay_data.business_id = BusinessData._id;
                  pay_data.wifi_name = BusinessData.wifi_name;
                  pay_data.pay_type = "43";

                  put_pay_modal_data(pay_data);
                  put_pay_modal_open(true);

                  // patchStatus({
                  //   id: BusinessData._id,
                  //   status:
                  //     BusinessData?.status === "finished"
                  //       ? "waitCheck"
                  //       : undefined,
                  //   charge_type: 2,
                  // });
                }}
              >
                ⚒️升级金刚包
              </Button>
            </Popover>

            <Popover
              title="获取密码"
              content={
                <div>
                  <p>包跑出可后付款</p>
                </div>
              }
            >
              <Button
                type="primary"
                icon={<ShoppingCartSimple size={14} />}
                style={{
                  display: `${
                    BusinessData?.status === "solved" ? "block" : "none"
                  }`,
                }}
                onClick={function fukuan() {
                  if (BusinessData?.payed === true) {
                    message.success("无需再次付款");
                    return;
                  }
                  let pay_data = {};

                  switch (BusinessData?.charge_type) {
                    case 0:
                      console.log("菜鸟包");
                      pay_data.subject = "pas_0";
                      break;
                    case 1:
                      console.log("基础包");
                      pay_data.subject = "pas_1";
                      break;
                    case 2:
                      console.log("进阶包");
                      pay_data.subject = "pas_2";
                      break;
                    case 3:
                      pay_data.subject = "pas_3";
                      break;
                  }

                  pay_data.business_id = BusinessData._id;
                  pay_data.wifi_name = BusinessData.wifi_name;
                  pay_data.pay_type = "43";

                  put_pay_modal_data(pay_data);
                  put_pay_modal_open(true);
                }}
              >
                付款
              </Button>
            </Popover>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Info;
