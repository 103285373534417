import classes from "./oldmain.module.css";
import hero from "../assets/旧移动端插画/hero.webp";
import kali from "../assets/旧移动端插画/kail4i.png";
import hashcat from "../assets/旧移动端插画/hashcatonline1.png";
import nodejs from "../assets/旧移动端插画/nodejs.png";
import video_card_2 from "../assets/旧移动端插画/video_card_ai_2.png";
import logo from "../assets/logos/logo.png";
import step1 from "../assets/旧移动端插画/step1.png";
import step2 from "../assets/旧移动端插画/step2.png";
import step3 from "../assets/旧移动端插画/step3.png";
import features from "../assets/旧移动端插画/features.webp";
import { Button, Dropdown, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { List } from "@phosphor-icons/react";
import price_img from "../assets/旧移动端插画/bg_1.jpg";
import { useSaleSmartly } from "../Client/utils";

function 移动端主页() {
  useSaleSmartly();
  const nav = useNavigate();

  return (
    <div className={classes.big_container}>
      <section className={classes.nav_mobile}>
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <Link style={{ fontSize: "1.4rem" }}>主页</Link>,
              },
              {
                key: "2",
                label: (
                  <a href="#advantage" style={{ fontSize: "1.4rem" }}>
                    我们的优势
                  </a>
                ),
              },
              {
                key: "3",
                label: (
                  <a target="_blank" style={{ fontSize: "1.4rem" }}>
                    定价
                  </a>
                ),
              },
              {
                key: "6",
                label: (
                  <a
                    target="_blank"
                    style={{ fontSize: "1.4rem" }}
                    href="http://api.ccwifi.cc:18888/tag/tong-zhi/"
                  >
                    公告
                  </a>
                ),
              },
              {
                key: "7",
                label: (
                  <a
                    target="_blank"
                    style={{ fontSize: "1.4rem" }}
                    href="http://api.ccwifi.cc:18888/tag/jcwt/"
                  >
                    常见问题
                  </a>
                ),
              },
              {
                key: "4",
                label: (
                  <Link to="/upload" style={{ fontSize: "1.4rem" }}>
                    立即开始
                  </Link>
                ),
              },
              {
                key: "5",
                label: (
                  <Link to="/client" style={{ fontSize: "1.4rem" }}>
                    查看进度
                  </Link>
                ),
              },
            ],
          }}
          placement="bottomLeft"
          arrow
          size="large"
        >
          <List size={48} color="white" />
        </Dropdown>
      </section>
      <section className={classes.nav}>
        {/* <img id={classes.logo} src={logo} /> */}
        <ul>
          {/* <li>主页</li> */}
          <li>
            <a href="#advantage">我们的优势</a>
          </li>
          {/* <li>定价</li> */}
          <li>
            <a href="http://api.ccwifi.cc:18888/tag/tong-zhi/" target="_blank">
              公告
            </a>
          </li>
          <li>
            <a href="http://api.ccwifi.cc:18888/tag/jcwt/" target="_blank">
              常见问题
            </a>
          </li>
          <li>
            <a href="/upload" target="_blank">
              立即开始
            </a>
          </li>
          <li>
            {" "}
            <a href="/client" target="_blank">
              查看状态
            </a>
          </li>
        </ul>
      </section>
      <section className={classes.hero}>
        <h1 className={classes.hero_title}>曹操WIFI</h1>
        {/* <img src={hero} className={classes.hero_img} /> */}
        <p className={classes.desctiption}>在线查找哈希 出结果再付款</p>
        <div className={classes.btns}>
          <button
            id={classes.main_button}
            onClick={() => {
              nav("/upload");
            }}
          >
            立即开始
          </button>
          <button
            id={classes.aside_button}
            onClick={() => {
              nav("/client");
            }}
          >
            查看进度
          </button>
        </div>
      </section>

      <section className={classes.gpu}>
        {/* <img src={video_card_1} /> */}
        <div className={classes.gpu_online}>
          <h2>显卡工作站</h2>
          <h3>在线</h3>
          <Progress
            size={["100%", 25]}
            percent={75}
            strokeColor={"#c847ff"}
            style={{ marginTop: "3.6rem" }}
            showInfo={false}
          />
        </div>
        <img className={classes.gpu_right} src={video_card_2} />
      </section>

      <section className={classes.logos}>
        <img src={kali}></img>
        <img src={hashcat}></img>
        <img src={nodejs} width={"200px"} style={{ opacity: "50%" }}></img>
      </section>

      <section className={classes.step}>
        <div className={classes.step1}>
          <article>
            <span className={classes.title01}>01</span>
            <h3>在线解析</h3>
            <p>网站支持解析CAP文件</p>
          </article>
          <img src={step1} />
        </div>
        <div className={classes.step2}>
          <article>
            <span className={classes.title02}>02</span>
            <h3>显卡工作站负责运算</h3>
            <p>使用显卡来检索HASH 全程透明</p>
          </article>

          <img src={step2} />
        </div>
        <div className={classes.step3}>
          <article>
            <span className={classes.title02}>03</span>
            <h3>在线支付 获得结果</h3>
            <p>支持支付宝在线支付</p>
          </article>

          <img src={step3} />
        </div>
      </section>

      <section className={classes.features} id="advantage">
        <div className={classes.advantage}>
          <h2>优点</h2>
          <p>为什么选择我们</p>
        </div>
        <span id={classes.advantage_1}>跑出再收费 没跑出不收跑包费</span>
        <span id={classes.advantage_2}>
          字典基于语料库自制 与网上流传字典质量不同
        </span>
        <span id={classes.advantage_3}>基于显卡工作站运行 速度快</span>
        <span id={classes.advantage_4}>跑包过程后台可看 全程透明 </span>
        <span id={classes.advantage_5}>基于数据调研 研究字典组成</span>
        <img src={features} />
      </section>

      <div className={classes.price}>
        {/* <img src={price_img} style={{ width: "100%" }} /> */}
        <div className={classes.price_title}>
          <h3>定价</h3>
        </div>
        <div className={classes.cards}>
          <div className={classes.price_card}>
            <h4>菜鸟包</h4>
            <ol>
              <li>电费:0</li>
              <li>成功率:15%</li>
              <li>字典:菜鸟字典</li>
              <li>实时进度:❌</li>
            </ol>
            <p>15元</p>
          </div>

          <div className={classes.price_card}>
            <h4>基础包</h4>
            <ol>
              <li>电费:1</li>
              <li>成功率:25%</li>
              <li>字典:基础70G</li>
              <li>实时进度:✅</li>
            </ol>
            <p>20元</p>
          </div>
          <div className={classes.price_card}>
            <h4>进阶包</h4>
            <ol>
              <li>电费:2</li>
              <li>成功率:35%</li>
              <li>字典:进阶100G</li>
              <li>实时进度:✅</li>
            </ol>
            <p>35元</p>
          </div>
          <div className={classes.price_card}>
            <h4>金刚包</h4>
            <ol>
              <li>电费:5</li>
              <li>成功率:??%</li>
              <li>字典:金刚字典</li>
              <li>实时进度:✅</li>
            </ol>
            <p>50</p>
          </div>
          <div className={classes.price_card}>
            <h4>定制包</h4>
            <ol>
              <li>成功率:70%</li>
              <li>字典:定制任务</li>
              <li>实时进度:✅</li>
            </ol>
            <p>20元</p>
          </div>
        </div>
      </div>

      <div className={classes.bg_color} style={{ background: "#120641" }}></div>
      <div className={classes.end}>
        <p>Copyright © 2023 WWW.CCWIFI.COM</p>
      </div>
    </div>
  );
}

export default 移动端主页;
