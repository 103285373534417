import {
  Button,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Upload,
  message,
  Popover,
} from "antd";
import React, { useEffect, useState } from "react";
import classes from "./Client.desktop.module.css";
import logo from "../../assets/logos/logo.png";
import Footer from "../../Components/footer";
import { useCrisp, useSaleSmartly } from "../utils";
import { useGetByMailQuery } from "../../Api/BusinessApi";
import { Bird, Gavel, Hammer, Package } from "@phosphor-icons/react";
import Upload_Business from "../Upload";

/**
 * # 客户端页电脑端
 *
 * ## State
 * - businessData：Business数据
 * - mail：用户输入的邮箱
 * - businessDataSource:表格用于展示的数据
 */
const 客户端电脑端 = () => {
  const [Mailvisible, setMailVisible] = useState(false); //邮件模态窗口
  const [Uploadvisible, setUploadVisible] = useState(false); //上传模态窗口
  const [email, setEmail] = useState(undefined);
  const { data: businessData } = useGetByMailQuery(email, {
    pollingInterval: 1000 * 30,
  });
  const [businessDataSource, setBusinessDataSource] = useState([]);

  // 检查localStorage中是否存在邮箱
  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (!storedEmail) {
      setMailVisible(true);
    } else {
      setEmail(storedEmail);
    }
  }, []);

  const onFinish = (values) => {
    // console.log("表单的数据:", values);
    const { mail } = values;
    setEmail(mail);
    localStorage.setItem("userEmail", mail);
    setMailVisible(false);
  };

  // useCrisp();
  useSaleSmartly(email);

  useEffect(() => {
    const data = businessData?.data;
    if (!data) return;
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      let 包类型;
      let status;

      switch (data[i].charge_type) {
        case 0:
          包类型 = (
            <span className={classes.包类型}>
              菜鸟包 <Bird size={24} />{" "}
            </span>
          );
          break;
        case 1:
          包类型 = (
            <span className={classes.包类型}>
              基础包 <Package size={24} />
            </span>
          );
          break;
        case 2:
          包类型 = (
            <span className={classes.包类型}>
              进阶包 <Hammer size={24} />
            </span>
          );
          break;
        case 3:
          包类型 = (
            <span className={classes.包类型}>
              金刚包 <Gavel size={24} />
            </span>
          );
          break;
        default:
          包类型 = <span className={classes.包类型}>其他包</span>;
          break;
      }

      switch (data[i].status) {
        case "waitCheck":
          status = "等待中⏳";
          break;
        case "running":
          status = "运行中🏃";
          break;
        case "finished":
          status = "已结束❄️";
          break;

        case "solved":
          if (data[i].payed === true) {
            status = "已支付🎉";
          } else {
            status = "成功 未支付❗";
          }

          break;
        default:
          status = "其他状态";
          break;
      }

      //data[i].status
      newData.push({
        key: i + 1,
        wifi_name: data[i].wifi_name,
        包类型,
        status,
        mac: data[i].MAC_AP.MAC,
      });
    }

    setBusinessDataSource(newData);
  }, [businessData]);

  return (
    <div id={classes.client}>
      <nav id={classes.top_nav}>
        <h2>
          <img src={logo} id={classes.nav_logo} />
          曹操wifi客户端
        </h2>

        <div id={classes.top_nav_right}>
          <p>欢迎你：{email}</p>
          <Popover content={"QQ：2694144457"}>联系客服</Popover>

          <Popover content={"在手机打开当前网址即可实时查看所有订单进度"}>
            手机查看
          </Popover>

          <Popconfirm
            title="确认退出吗"
            onConfirm={() => {
              localStorage.removeItem("userEmail");
              setEmail("");
              location.reload();
            }}
          >
            <Button size="small">退出</Button>
          </Popconfirm>
        </div>
      </nav>

      <div id={classes.下方}>
        <Menu
          style={{
            width: 256,
          }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          items={[
            {
              key: "1",
              icon: null, // 这里假设没有提供图标，您可以根据需要添加
              children: null, // 假设没有子项，您可以根据需要添加
              label: "我的订单",
              type: null, // 假设没有提供类型，您可以根据需要添加
            },
            {
              key: "2",
              icon: null, // 同上
              children: null, // 同上
              label: "在线客服",
              type: null, // 同上
              onClick: () => {
                // message.success("右下角有客服图标，点击即可联系客服");
                if (window.ssq) {
                  window.ssq.push("chatOpen");
                }
              },
            },
            {
              key: "3",
              icon: null, // 同上
              children: null, // 同上
              label: "常见问题",
              type: null, // 同上
              onClick: () => {
                window.open(
                  "https://www.ccwifi.cc/blogs/index.php/category/qa/"
                );
              },
            },
          ]}
        />
        <div id={classes.content}>
          <div id={classes.btns}>
            <Button
              type="primary"
              onClick={() => {
                setUploadVisible(true);
              }}
            >
              立即上传
            </Button>
          </div>
          <Table
            columns={[
              {
                title: "wifi名",
                dataIndex: "wifi_name",
                key: "wifi_name",
              },
              {
                title: "包类型",
                dataIndex: "包类型",
                key: "包类型",
              },
              {
                title: "状态",
                dataIndex: "status",
                key: "status",
              },
              {
                title: "操作",
                dataIndex: "operation",
                key: "operation",
                render: (text, record) => (
                  <a
                    onClick={() => {
                      //   console.log(record);

                      window.open(`/feedback?mac_ap=${record.mac}`);
                    }}
                  >
                    查看反馈页
                  </a>
                ),
              },
            ]}
            dataSource={businessDataSource}
          />
        </div>
      </div>

      <Footer theme="light" />
      <Modal
        title="请输入你的邮箱"
        open={Mailvisible}
        // onCancel={() => setMailVisible(false)}
        footer={null}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            name="mail"
            label="邮箱"
            rules={[{ required: true, message: "请输入邮箱!" }]}
          >
            <Input
              placeholder="请输入邮箱"
              rules={[{ required: true, message: "请输入邮箱!" }]}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>

        {/* <Button type="primary" onClick={handleConfirm}>
          确认
        </Button> */}
      </Modal>

      <Upload_Business
        open={Uploadvisible}
        width={"50%"}
        handleCancel={() => {
          setUploadVisible(false);
        }}
        email={email}
      />
    </div>
  );
};

export default 客户端电脑端;
