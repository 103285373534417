import { useLocation, useNavigate } from "react-router";
import classes from "./Running.module.css";
import {
  Check,
  Pen,
  Trash,
  Bell,
  RssSimple,
  Package,
  Hammer,
  Gavel,
  LockOpen,
  FadersHorizontal,
  Synagogue,
  ArrowClockwise,
  Bird,
  Question,
} from "@phosphor-icons/react";

import {
  useLookForFresh_And_NotBeConfirmedQuery,
  useUpdataOneBusinessMutation,
} from "../Api/BusinessApi";
import {
  useGetAllTasksQuery,
  useDeleteOneTaskMutation,
  useRestartTaskMutation,
} from "../Api/TaskApi";
import { useEffect, useState, memo } from "react";
import { configure } from "../Stores/configure";
import { useGetAllQuery } from "../Api/ZidianApi";
import { useSelector } from "react-redux";
import Nav from "./Nav";
import { Outlet } from "react-router-dom";
import {
  Table,
  Tooltip,
  Popconfirm,
  Descriptions,
  Button,
  Space,
  message,
  Badge,
  Alert,
  Tag,
  Skeleton,
  Progress,
  Row,
  Col,
  Statistic,
  Input,
} from "antd";
import nzhcn from "nzh/cn";
import Card from "antd/es/card/Card";
import Meta from "antd/es/card/Meta";
import Avatar from "antd/es/avatar/avatar";
import { Helmet } from "react-helmet";
import CountUp from "react-countup";

/**
 * Running组件，用来显示运行中的任务
 *
 * ## State
 * - freshData：新鲜数据，用于渲染进行中的Business
 * - currentPage：当前Tabel的页数，用于保持状态
 * - filterKeyWord：过滤关键字，用于搜索
 *
 * ## 函数
 * - `renderDaiChuLi`：渲染进行中的Business（上部分）
 *
 * - `renderRunningTask`：渲染正在运行的任务(下部分)
 *    - `running`：渲染正在运行的任务
 *    - `wating`：渲染等待中的任务
 *    - `fail`：渲染失败的任务
 * - `handlerRestart`：负责处理重启任务并进行通知
 */
function Running() {
  //导航能力
  const nav = useNavigate();

  const [filterKeyWord, setFilterKeyWord] = useState("");

  //----------钩子函数----------

  //获取api服务器地址

  const { speed } = useSelector((state) => state.configure);

  //当前地址
  const thisUrl = useLocation();

  //API1.获取所有新鲜数据
  const { data: freshData } = useLookForFresh_And_NotBeConfirmedQuery(null, {
    pollingInterval: 500,
  });

  //API2.修改Business
  const [patchOneBusness] = useUpdataOneBusinessMutation();

  //API3.获取所有Tasks
  const { data: allTaskResult } = useGetAllTasksQuery(null, {
    pollingInterval: 1000,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  //API4.获取字典任务 用于计算时间

  const { data: allZidianData } = useGetAllQuery();

  //API5.删除任务 尤其是running状态的任务

  const [deleteOneTask] = useDeleteOneTaskMutation();

  //API6.重启任务

  const [restartTask] = useRestartTaskMutation();

  function handlerRestart({ task_id, failedOnly }) {
    restartTask({ task_id, failedOnly }).then((res) => {
      message.success(
        `重启成功，重启${res.data.data.affectedDocumentsCount}个任务`
      );
    });
  }

  const [allTaskData, putAllTaskData] = useState();

  useEffect(() => {
    if (!allTaskResult) return;
    putAllTaskData(allTaskResult.data);
  }, [allTaskResult]);

  const [currentPage, setCurrentPage] = useState(() => {
    // 从 localStorage 中读取当前页数
    const savedPage = localStorage.getItem("currentPage");
    return savedPage ? Number(savedPage) : 1;
  });

  useEffect(() => {
    // 当 currentPage 改变时，将其保存到 localStorage 中
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  //----------自定义函数-----------

  //上部分 进行中的Business
  function renderDaiChuLi() {
    if (!freshData) return;

    let dataSource = [];

    for (let index = 0; index < freshData.data.length; index++) {
      const sing = freshData.data[index];

      if (
        filterKeyWord &&
        !String(sing.wifi_name).includes(filterKeyWord) &&
        !String(sing.mail).includes(filterKeyWord)
      ) {
        continue;
      }

      // 检查备注是否存在
      const hasNotes = sing?.note || sing?.commercialNote;

      // 构造备注内容
      let noteContent = "";
      if (sing?.note) {
        noteContent += `客户备注：${sing.note}; `;
      }
      if (sing?.commercialNote) {
        noteContent += `商家备注：${sing.commercialNote}; `;
      }

      const newObject = {
        key: index,
        index,
        wifi_name: (
          <Tooltip title={sing.wifi_name}>
            <p className={classes.charge_type}>
              {(() => {
                switch (sing?.charge_type) {
                  case 0:
                    return <Bird size={24} />;

                  case 1:
                    return <Package size={24} />;

                  case 2:
                    return <Hammer size={24} />;

                  case 3:
                    return <Gavel size={24} />;
                  default:
                    return <Question size={24} />;
                }
              })()}
              {sing.wifi_name.length > 10
                ? `${sing.wifi_name.slice(0, 10)}...`
                : sing.wifi_name}
            </p>
          </Tooltip>
        ),
        // xianyu_name: sing.xianyu_name,
        city: (
          <Tooltip title={`${sing?.mail}`}>
            <p>{sing.city}</p>
          </Tooltip>
        ),
        create_at: (
          <Tooltip title={noteContent}>
            <p style={{ fontWeight: `${hasNotes ? "bold" : ""}` }}>
              {new Date(sing.create_at).toLocaleString("zh-CN").slice(5, 9)}
            </p>
          </Tooltip>
        ),
        note: sing?.note ?? "",
        mail: sing?.mail ?? "",
        status: (
          <p>
            {sing.status === "waitCheck"
              ? "⏱️等待"
              : sing.status === "running"
                ? "👌运行"
                : sing.status === "finished"
                  ? "🥲跑完"
                  : sing.status === "solved" && sing.payed !== true
                    ? "💲收款"
                    : sing.status === "solved" && sing.payed === true
                      ? "🎆付款"
                      : "🤔未知"}
          </p>
        ),
        // charge_type: sing.charge_type,
        caozuo: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "1.2rem",
            }}
          >
            <Popconfirm
              title="确认通知"
              onConfirm={() => {
                const tem = { ...sing };
                tem.confirmed = true;
                patchOneBusness(tem);
              }}
            >
              <span
                style={{
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  display: `${
                    sing.status === "solved" && sing.payed === true
                      ? "flex"
                      : "none"
                  }`,
                }}
              >
                <Check color="green" size={22} />
                <p style={{ color: "green" }}>已通知</p>
              </span>
            </Popconfirm>
            <Popconfirm
              title="确认知晓"
              onConfirm={() => {
                const tem = { ...sing };
                tem.confirmed = true;
                patchOneBusness(tem);
              }}
            >
              <span
                style={{
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  display: `${sing.status === "finished" ? "flex" : "none"}`,
                }}
              >
                <Bell color="grey" size={22} />
                <p style={{ color: "grey" }}>已知晓</p>
              </span>
            </Popconfirm>
            <span
              style={{
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                display: `${
                  sing.status === "waitCheck" ||
                  sing.status === "running" ||
                  sing.status === "finished" ||
                  sing.status === "solved"
                    ? "flex"
                    : "none"
                }`,
              }}
              onClick={() => {
                //console.log(sing._id);
                nav(`/control/xiugairenwu/${sing._id}`);
              }}
            >
              <Pen size={22} /> 修改
            </span>
          </div>
        ),
        feedback: (
          <span>
            <RssSimple
              size={26}
              className={classes.feedback}
              onClick={() => {
                const url = `${window.location.origin}/feedback?mac_ap=${sing?.MAC_AP?.MAC}`;

                if (!navigator.clipboard) {
                  window.open(`${url}&notrack=true`);
                  return;
                }

                navigator.clipboard
                  ?.writeText(
                    `🛜${sing.wifi_name}进度实时查看：${url} 请复制到浏览器打开`
                  )
                  .then(() => {
                    window.open(`${url}&notrack=true`);
                  })
                  .catch((err) => {
                    message.error("复制链接失败: ", err);
                  });
              }}
            />
          </span>
        ),
        // lastViewDate: sing?.lastViewDate,
        // totalMoney: sing?.totalMoney,
        data: (
          <div className={classes.little_data}>
            <Tag color={getMoneyColor(sing.totalMoney)}>
              {sing.totalMoney}元
            </Tag>
            {getTimeSinceLastView(sing.lastViewDate)}
            {sing?.fileExtension === "cap" || !sing?.fileExtension ? null : (
              <Tag>{sing?.fileExtension}</Tag>
            )}

            {/* <span>10G</span> */}
          </div>
        ),
        charge_type: sing.charge_type,
      };

      dataSource.push(newObject);
    }

    // 通过时间差返回Tag组件
    function getTimeSinceLastView(lastViewDate) {
      const lastView = new Date(lastViewDate);
      const now = new Date();
      const timeDifference = now - lastView;

      // 获取对应的颜色
      const color = getTimeColor(timeDifference);

      const oneHour = 1000 * 60 * 60;
      const sixHours = 6 * oneHour;
      const twelveHours = 12 * oneHour;
      const oneDay = 24 * oneHour;
      const twoDays = 2 * oneDay;
      const threeDays = 3 * oneDay;
      const fourDays = 4 * oneDay;
      const fiveDays = 5 * oneDay;

      // 判断时间差属于哪个时间段，并返回对应的Tag标签
      if (timeDifference < oneHour) {
        return <Tag color={color}>一小时内</Tag>;
      } else if (timeDifference < 8 * oneHour) {
        return <Tag color={color}>8小时内</Tag>;
      } else if (timeDifference < oneDay) {
        return <Tag color={color}>24小时内</Tag>;
      } else if (timeDifference < 2 * oneDay) {
        return <Tag color={color}>2天内</Tag>;
      } else if (timeDifference < 3 * oneDay) {
        return <Tag color={color}>3天内</Tag>;
      } else if (timeDifference < 4 * oneDay) {
        return <Tag color={color}>4天内</Tag>;
      } else if (timeDifference < 5 * oneDay) {
        return <Tag color={color}>5天内</Tag>;
      } else {
        return <Tag color={color}>超过5天</Tag>;
      }

      // 根据时间差计算颜色值（从绿色到红色）
      function getTimeColor(timeDifference) {
        const oneHour = 1000 * 60 * 60;
        const oneDay = 24 * oneHour;
        const twoDays = 2 * oneDay;
        const threeDays = 3 * oneDay;
        const fourDays = 4 * oneDay;
        const fiveDays = 5 * oneDay;

        if (timeDifference < oneHour) {
          return "#90EE90"; // 浅绿色
        } else if (timeDifference < 8 * oneHour) {
          return "#008000"; // 深绿色
        } else if (timeDifference < oneDay) {
          return "#f08c00"; // 金色
        } else if (timeDifference < twoDays) {
          return "#e64980"; // 粉色
        } else if (timeDifference < threeDays) {
          return "#FF0000"; // 红色
        } else if (timeDifference < fourDays) {
          return "#800080"; // 紫色
        } else if (timeDifference < fiveDays) {
          // 可以为5天添加一个新的颜色，或者继续使用上一个颜色
          return "#800080"; // 紫色，或者你可以选择一个新的颜色
        } else {
          return "#000000"; // 没有设置颜色，或者可以设置为黑色
        }
      }
    }

    //根据金额，返回对应的颜色

    function getMoneyColor(money) {
      let color;

      if (money === 0) {
        color = "default"; // 0元是灰色
      } else if (money === 1) {
        color = "lime"; // 1元
      } else if (money === 2) {
        color = "green"; // 2元
      } else if (money <= 5) {
        color = "gold"; //
      } else if (money <= 10) {
        color = "red"; //
      } else {
        color = "#108ee9";
      }

      return color;
    }

    return (
      <>
        <Table
          data-description="电脑版表格"
          className={classes.computer}
          columns={[
            { title: "序号", dataIndex: "index" },
            {
              title: "任务名",
              dataIndex: "wifi_name",
              // render: (item) => item.wifi_name,
              sorter: (a, b) => a.charge_type - b.charge_type,
            },
            // { title: "咸鱼昵称", dataIndex: "xianyu_name" },
            {
              title: "城市",
              dataIndex: "city",
            },
            { title: "提交时间", dataIndex: "create_at" },
            {
              title: "状态",
              dataIndex: "status",
            },
            // { title: "备注", dataIndex: "note" },
            // { title: "邮件", dataIndex: "mail" },
            // {
            //   title: "包类型",
            //   dataIndex: "charge_type",
            // },
            { title: "操作", dataIndex: "caozuo" },
            { title: "反馈页", dataIndex: "feedback" },
            {
              title: "数据",
              dataIndex: "data",
            },
          ]}
          dataSource={dataSource}
          pagination={{
            current: currentPage,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
        />
        <Table
          data-description="手机版表格"
          className={classes.moblie}
          columns={[
            // { title: "序号", dataIndex: "index" },
            {
              title: "任务名",
              dataIndex: "wifi_name",
              render: (text) => text,
              width: 10,
            },
            {
              title: "状态",
              dataIndex: "status",
            },
            {
              title: "数据",
              dataIndex: "data",
            },
          ]}
          expandable={{
            expandedRowRender: (record) => {
              let items = [];
              const duizhao = {
                create_at: "提交时间",
                city: "城市",
                status: "状态",
                note: "备注",
                charte_type: "包类型",
                caozuo: "操作",
                feedback: "反馈页",
                wifi_name: "任务名",
                xianyu_name: "闲鱼昵称",
                mail: "邮箱",
                data: "数据",
              };

              for (let [key, value] of Object.entries(record)) {
                //if (!Object.keys(duizhao).some((e) => e === key)) continue;
                if (["key", "index"].some((e) => e === key)) continue;
                items.push({
                  key: `${record.wifi_name}_${record.index}`,
                  label: duizhao[key],
                  children: (
                    <div
                      style={{
                        margin: 0,
                      }}
                    >
                      {value}
                    </div>
                  ),
                });
              }

              return <Descriptions items={items} />;
            },
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          dataSource={dataSource}
          pagination={{
            current: currentPage,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
        />
      </>
    );
  }

  //下部分 进行中的任务
  function renderRunningTask(type) {
    if (!allTaskData || !allZidianData) return;
    let ready_return;

    // console.log("allTaskData", allTaskData);

    const sortedData = [...allTaskData].sort((a, b) => a._id - b._id);

    ready_return = sortedData.map((sing) => {
      if (sing.status === "running" && type === "running") {
        return running(sing);
      }

      if (sing.status === "fail" && type === "fail") {
        return fail(sing);
      }
    });

    if (type === "wating") {
      const all_wating_tasks = allTaskData.filter((e) => e.status === "wating");
      ready_return = wating(all_wating_tasks);
    }

    return ready_return;

    //用来渲染running block
    function running(sing) {
      const hashcatStatus = sing.hashcatStatus;
      // 判断是否过期
      const now = new Date();
      const isOutdated =
        hashcatStatus?.TimeEstimated &&
        new Date(hashcatStatus.TimeEstimated) < now;

      const formatter = (value) => <CountUp end={value} separator="," />;
      //用来渲染动画 不重要

      //颜色随温度改变
      const getTemperatureColor = (temp) => {
        if (temp < 65) {
          return "green";
        } else if (temp < 80) {
          return "#f59f00";
        } else {
          return "red";
        }
      };

      return (
        <Badge.Ribbon
          color={isOutdated ? "red" : "green"}
          text={`${isOutdated ? "已过时" : "进行中"}`}
        >
          <Card
            // className={classes.BlockTaskRunning}
            // key={sing._id}
            style={{ position: "relative" }}
            actions={[
              <Trash
                size={24}
                onClick={() => {
                  const confirmBoolean = confirm(
                    "确定删除此任务吗？此任务状态未正在运行 请谨慎删除"
                  );
                  if (!confirmBoolean) return;
                  deleteOneTask(sing._id);
                  alert("删除成功");
                }}
              />,
              <ArrowClockwise
                size={24}
                onClick={() => {
                  const confirmBoolean = confirm(
                    "重启此任务吗？此任务状态未正在运行 请谨慎重启"
                  );
                  if (!confirmBoolean) return;
                  handlerRestart({ task_id: sing._id });
                }}
              />,
            ]}
          >
            <Meta
              avatar={
                <Avatar
                  src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${sing?.business_object_id}`}
                />
              }
              title={sing.wifi_name}
              // description="This is the description"
            />

            {/* <h3>闲鱼用户：{sing.xianyu_name}</h3> */}

            <div style={{ marginTop: "1.2rem" }}>
              <p>正在运行的包：{sing.zidian_name}</p>

              <p>Hashcat状态：{hashcatStatus?.Status}</p>
              <p>
                进度：
                <Progress percent={Number.parseFloat(hashcatStatus?.jindu)} />
              </p>
              <p>速度：{hashcatStatus?.theSpeed.join("---")}</p>
              <p>总数：{hashcatStatus?.allAmount}</p>
              <p>
                开始时间：
                {new Date(hashcatStatus?.TimeStarted).toLocaleString("zh-CN")}
              </p>
              <p>
                预计结束时间：
                {new Date(hashcatStatus?.TimeEstimated).toLocaleString("zh-CN")}
              </p>

              <p>候选项:{hashcatStatus?.theCandidates.join("到")}</p>

              <p>正在猜测的掩码：{hashcatStatus?.GuessMask}</p>
              <p>运行机器：{hashcatStatus?.machine}</p>

              <Row gutter={16}>
                <Col span={8}>
                  <Statistic
                    title="利用率"
                    value={hashcatStatus?.Mon?.[0]?.Util}
                    suffix="%"
                    formatter={formatter}
                    valueStyle={{ fontSize: "18px" }} // 设置字体大小为 18px
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="显卡温度"
                    value={hashcatStatus?.Mon?.[0]?.Temp}
                    suffix="°C"
                    formatter={formatter}
                    valueStyle={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: getTemperatureColor(hashcatStatus?.Mon?.[0]?.Temp),
                    }} // 设置字体大小为 18px
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="风扇转速"
                    value={hashcatStatus?.Mon?.[0]?.Fan}
                    suffix="RPM"
                    formatter={formatter}
                    valueStyle={{ fontSize: "18px" }} // 设置字体大小为 18px
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </Badge.Ribbon>
      );
    }

    function wating(all_wating_tasks) {
      const dataSource = all_wating_tasks.map((sing) => {
        return {
          wifi_name: sing.wifi_name,
          status: "等待中",
          zidian_name: sing.zidian_name,
          amount: count_data(sing, "total"),
          size: count_data(sing, "size"),
          _amount: sing.total,
        };
      });

      return (
        <Table
          columns={[
            { title: "Wifi名", dataIndex: "wifi_name" },
            { title: "状态", dataIndex: "status" },
            { title: "字典名", dataIndex: "zidian_name" },
            { title: "数量", dataIndex: "amount" },
            { title: "容量", dataIndex: "size" },
          ]}
          dataSource={dataSource}
          summary={() => {
            let 总个数 = dataSource.length;
            const parseFloatFromSize = (size) => {
              const parts = size.split("GB");
              const numberPart = parts[0].trim();
              return parseFloat(numberPart);
            };
            let 总容量 = Array.from(dataSource).reduce((acc, cur) => {
              let tem = parseFloatFromSize(cur.size);
              if (!isNaN(tem)) {
                return acc + tem; // 使用tem而不是重新转换cur.size
              } else {
                return acc; // 如果转换结果为NaN，则直接返回累加器
              }
            }, 0);

            let 总条数 = Array.from(dataSource).reduce((acc, cur) => {
              let tem = cur._amount;
              if (!isNaN(tem)) {
                return acc + tem; // 使用tem而不是重新转换cur.size
              } else {
                return acc; // 如果转换结果为NaN，则直接返回累加器
              }
            }, 0);

            总容量 = 总容量.toFixed(2);

            function number_to_chinese(a) {
              if (!a) return "无";
              let b = String(a);
              if (b.length > 7) {
                //后七位变0
                for (let i = 1; i <= 7; i++) {
                  const index = b.length - i;
                  if (i === 1) {
                    b = `${b.slice(0, index)}0`;
                  } else {
                    b = `${b.slice(0, index)}0${b.slice(index + 1)}`;
                  }
                }

                return `${nzhcn.encodeS(Number(b))}个`;
              } else {
                for (let i = 1; i <= b.length - 1; i++) {
                  const index = b.length - i;
                  if (i === 1) {
                    b = `${b.slice(0, index)}0`;
                  } else {
                    b = `${b.slice(0, index)}0${b.slice(index + 1)}`;
                  }
                }

                return `${nzhcn.encodeS(Number(b))}个`;
              }
            }

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1}>无</Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>无</Table.Summary.Cell>

                  <Table.Summary.Cell index={3}>{总个数}个</Table.Summary.Cell>

                  <Table.Summary.Cell index={4}>
                    {number_to_chinese(总条数)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={5}>{总容量}G</Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      );

      //计算待运行的字典数量
      function count_data(running_task, type) {
        if (!allZidianData.data) return;
        const the_zidian = allZidianData.data.find((zidian) => {
          return zidian._id === running_task.zidian_object_id;
        });
        if (type === "total") {
          return number_to_chinese(the_zidian?.total);
        }

        if (type === "size") {
          if (the_zidian?.Size) {
            return `${the_zidian.Size}GB`;
          } else {
            return "未知";
          }
        }

        //中文转数字
        function number_to_chinese(a) {
          if (!a) return "无";
          let b = String(a);
          if (b.length > 7) {
            //后七位变0
            for (let i = 1; i <= 7; i++) {
              const index = b.length - i;
              if (i === 1) {
                b = `${b.slice(0, index)}0`;
              } else {
                b = `${b.slice(0, index)}0${b.slice(index + 1)}`;
              }
            }

            return `${nzhcn.encodeS(Number(b))}个`;
          } else {
            for (let i = 1; i <= b.length - 1; i++) {
              const index = b.length - i;
              if (i === 1) {
                b = `${b.slice(0, index)}0`;
              } else {
                b = `${b.slice(0, index)}0${b.slice(index + 1)}`;
              }
            }

            return `${nzhcn.encodeS(Number(b))}个`;
          }
        }
      }
    }

    function fail(sing) {
      return (
        <Alert
          message={<strong>{sing.wifi_name}</strong>}
          description={`执行字典《${sing.zidian_name}》错误，错误原因：${sing.fail_reason ?? "无"}——来自${sing?.hashcatStatus?.machine ?? "未知机器"}`}
          type="error"
          // showIcon
        />
      );

      return (
        <div className={classes.BlockFailWating}>
          <h3>项目名：{sing.wifi_name}</h3>
          <h3>运行的包：{sing.zidian_name}</h3>
          <p>状态：失败</p>
          <p>失败原因：{sing.fail_reason}</p>
          <p>执行机器：{sing?.hashcatStatus?.machine}</p>
        </div>
      );
    }
  }

  if (!freshData) return <Skeleton style={{ marginTop: "2.4rem" }} active />;

  return (
    <div>
      <Helmet>
        <title>运行中的任务</title>
      </Helmet>
      <div>
        <section className={` ${classes.container} ${classes.dengdaichuli}`}>
          <span id={classes.标题搜索框一体}>
            <h2 className={classes.generalh2}>进行中的Business</h2>
            <Input
              value={filterKeyWord}
              onChange={(e) => {
                setFilterKeyWord(e.target.value);
              }}
            />
          </span>

          {renderDaiChuLi()}
        </section>

        <section className={`${classes.container} ${classes.runningTask}`}>
          <h2 className={classes.generalh2}>进行中的Tasks</h2>

          <Space data-description="按钮集合" style={{ marginBottom: "1.2rem" }}>
            <Popconfirm
              title="你确定要重启所有任务吗？"
              onConfirm={() => {
                handlerRestart({ failedOnly: false });
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button danger>重启所有任务⚠️</Button>
            </Popconfirm>
            <Popconfirm
              title="你确定要重启失败的任务吗？"
              onConfirm={() => {
                handlerRestart({ failedOnly: true });
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="default">重启失败任务</Button>
            </Popconfirm>
          </Space>

          <div className={classes.taskBlockContainer}>
            <div className={classes.taskBlockRunningContainer}>
              {renderRunningTask("running")}
            </div>
            <div className={classes.taskBlockWatingContainer}>
              {renderRunningTask("wating")}
            </div>

            <div className={classes.taskBlockFailContainer}>
              {renderRunningTask("fail")}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Running;
