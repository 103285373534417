const reactdom = require("react-dom/client");
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import store from "./Stores";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

const root = reactdom.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#9e339f",
            colorInfo: "#9e339f",
            colorSuccess: "#e88ff7",
          },
        }}
      >
        <App></App>
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
