import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Menu, Dropdown, Space, Button } from "antd";
import { useState } from "react";
import {
  TreeStructure,
  ListBullets,
  Books,
  Nut,
  Checks,
  List,
} from "@phosphor-icons/react";
import classes from "./Nav.module.css";

/**
 * 作用：生成菜单
 */

function getItem(label, key, children, icon, drop_menu = false) {
  return {
    key,
    children,
    label: drop_menu === false ? label : <Link to={key}>{label}</Link>,
    icon,
  };
}

//电脑端菜单
const items = [
  getItem("数据大厅", "/control/data", undefined, <TreeStructure size={26} />),
  getItem("运行中任务", "/control/running", undefined, <Checks size={26} />),
  getItem(
    "历史任务",
    "/control/capxiangmu",
    undefined,
    <ListBullets size={26} />
  ),
  getItem("字典管理", "/control/dics", undefined, <Books size={26} />),
  getItem(
    "订单明细",
    "/control/dingdan",
    undefined,
    <TreeStructure size={26} />
  ),
];

//移动端菜单
const items_drop = [
  getItem(
    "数据大厅",
    "/control/data",
    undefined,
    <TreeStructure size={26} />,
    true
  ),
  getItem("运行中任务", "/control", undefined, <Checks size={26} />, true),
  getItem(
    "历史任务",
    "/control/capxiangmu",
    undefined,
    <ListBullets size={26} />,
    true
  ),
  getItem("字典管理", "/control/dics", undefined, <Books size={26} />, true),
  getItem(
    "订单明细",
    "/control/dingdan",
    undefined,
    <TreeStructure size={26} />,
    true
  ),
];

/**
 * ### Nav组件
 * 可以生成一个菜单Menu。
 * @returns
 */
function Nav() {
  //================钩子函数===========

  //用来跳转

  const nav = useNavigate();

  const [current, setCurrent] = useState("1");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
    nav(e.key);
  };

  return (
    <>
      <p className={classes.tianchong}>123</p>
      <div className={classes.drop_menu}>
        <Dropdown
          menu={{
            items: items_drop,
          }}
          arrow
        >
          <List size={32} />
        </Dropdown>
      </div>
      <Menu
        style={{ width: "100%" }}
        onClick={onClick}
        theme={"light"}
        defaultOpenKeys={["data"]}
        selectedKeys={[current]}
        mode="inline"
        items={items}
        className={classes.menu}
      />
    </>
  );
}

export default Nav;
