import { Card, Col, Row, Menu, Typography, message, Button, Alert } from "antd";
import classes from "./Data.module.css";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useTongjiMutation, useTongji_initQuery } from "../Api/BusinessApi";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cloneDeep from "lodash/cloneDeep";
import _money from "../assets/music/money.mp3";
import _chuizi from "../assets/music/chuizi.mp3";
import _dingding from "../assets/music/dingding.mp3";
import Title from "antd/es/skeleton/Title";
import { Helmet } from "react-helmet";
import Marquee from "react-fast-marquee";
// import createG2 from "g2-react";
// import { Stat } from "g2";

const money = new Audio(_money);
const chuizi = new Audio(_chuizi);
const dingding = new Audio(_dingding);

/**
 * ### Data组件
 * 用来显示
 * - 今日营收、出单、订单。
 * - 接单量
 * - 出包率
 * - 收入
 *
 * ## State
 *
 * - init_data - 通过useTongji_initQuery钩子获取的初始化数据，不断刷新当天的收入，以便实时显示。
 * - jinri_dingdan - 存储今日订单数据的状态。
 * - data - 存储七天内（默认），或者选择的统计数据的状态。
 * - options_chubaolv - 存储出包率图表配置的状态。
 * - options_danshu - 存储订单数图表配置的状态。
 * - options_srtj - 存储收入统计图表配置的状态。
 *
 * ## 函数
 *
 * should_change_handler - 当点击不同的数据统计形式时的事件处理函数。
 */
function Data() {
  //-----------钩子函数-----------

  //API
  const [tongji_use, tongji_data] = useTongjiMutation();
  const { data: init_data } = useTongji_initQuery(null, {
    pollingInterval: 5 * 60 * 1000, //五分钟刷新一次
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  //储存今日订单（上面的）
  const [jinri_dingdan, put_jinri_dingdan] = useState();

  //储存的是统计数据（下面的）

  const [data, put_data] = useState();

  //  init数据改变
  useEffect(() => {
    if (init_data?.message === "初始化") {
      const _tem = Object.values(init_data?.data)[0];
      let tem = cloneDeep(_tem);
      if (tem) {
        //比较是否相同 播放生意
        put_jinri_dingdan((state) => {
          if (state?.接单 !== undefined && state?.接单 < tem?.接单) {
            console.log("接单改变");
            dingding.play().catch((error) => {
              message.error(`播放音频失败${error.message}`);
            });
          }
          if (state?.出包 !== undefined && state?.出包 < tem?.出包) {
            console.log("出包改变");
            chuizi.play().catch((error) => {
              message.error(`播放音频失败${error.message}`);
            });
          }
          if (
            state?.totalRevenue !== undefined &&
            tem?.totalRevenue - state?.totalRevenue >= 2
          ) {
            console.log("营收改变");
            money.play().catch((error) => {
              message.error(`播放音频失败${error.message}`);
            });
          }
          return tem;
        });
      }

      //此时 再次去获取最近七日的统计数据
      const _blank = new Date();
      _blank.setHours(0, 0, 0, 0);
      const endDate = new Date(_blank);
      endDate.setDate(endDate.getDate() + 1);
      const startDate = new Date(_blank);
      startDate.setDate(startDate.getDate() - 7 + 1);

      tongji_use({
        end_at: endDate.getTime() - 1,
        start_at: startDate.getTime(),
        unit: "day",
        message: "统计数据",
      });
    }
  }, [init_data]);

  useEffect(() => {
    if (tongji_data?.data?.message === "统计数据") {
      put_data(tongji_data?.data.data);
    }
  }, [tongji_data]);

  //统计数据改变 重新生成数据源
  // useEffect(() => {
  //   if (!data) return;

  //   //处理订单数
  //   put_options_danshu((state) => {});

  //   //处理出包率
  //   put_options_chubaolv((state) => {});

  //   //处理收入统计
  //   put_options_srtj((state) => {});
  // }, [data]);

  //自定义函数

  //当点击不同的数据统计形式时
  const should_change_handler = useCallback(({ key }) => {
    console.log("key", key);
    const _blank = new Date();
    _blank.setHours(0, 0, 0, 0);

    let unit;
    let range;

    // 根据 key 的格式确定 unit 和 range
    switch (key) {
      case "month_day":
        unit = "day";
        range = 30;
        break;
      case "month_duan":
        unit = "part";
        range = 30;
        break;
      case "3_month_day":
        unit = "day";
        range = 90;
        break;
      case "3_month_duan":
        unit = "part";
        range = 90;
        break;
      case "year_day":
        unit = "day";
        range = 180;
        break;
      case "year_duan":
        unit = "part";
        range = 180;
        break;
      case "year_month":
        unit = "month";
        range = 180;
        break;
      case "day":
        unit = "day";
        range = 7;
        break;
      case "3_month_yue":
        unit = "month";
        range = 90;
        break;

      case "ayear_day":
        unit = "day";
        range = 360;
        break;

      case "ayear_duan":
        unit = "part";
        range = 360;
        break;

      case "ayear_month":
        unit = "month";
        range = 360;
        break;

      case "2year_month":
        unit = "month";
        range = 360;
        break;

      case "2year_duan":
        unit = "duan";
        range = 360;
        break;

      case "2year_day":
        unit = "day";
        range = 360;
        break;

      default:
        message.error(`Invalid key format:, ${key}`);
        return;
    }

    // 设置开始和结束日期
    const endDate = new Date(_blank);
    endDate.setDate(endDate.getDate() + 1);

    const startDate = new Date(_blank);
    startDate.setDate(startDate.getDate() - range + 1);
    // 调用 tongji_use 函数
    tongji_use({
      end_at: endDate.getTime() - 1,
      start_at: startDate.getTime(),
      unit: unit,
      message: "统计数据",
    });

    // 输出日期和时间单位
    // console.log(
    //   startDate.toLocaleString("zh-CN"),
    //   endDate.toLocaleString("zh-CH"),
    //   unit
    // );
  }, []);

  return (
    <div className={classes.container}>
      <Helmet>
        <title>数据大厅</title>
      </Helmet>
      <section className={classes.gailan}>
        <Row gutter={24}>
          <Col span={8}>
            <Card
              title={<h3 className={classes.card_title}>今日订单</h3>}
              bordered={false}
            >
              <span id={classes.dingdan}>
                {jinri_dingdan?.接单 ? jinri_dingdan.接单 : "0"}
              </span>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={<h3 className={classes.card_title}>今日出单</h3>}
              bordered={false}
            >
              <span id={classes.chudan}>
                {jinri_dingdan?.出包 ? jinri_dingdan.出包 : 0}
              </span>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={<h3 className={classes.card_title}>今日营收</h3>}
              bordered={false}
            >
              <span id={classes.yingshou}>
                {" "}
                {jinri_dingdan?.totalRevenue ? jinri_dingdan.totalRevenue : 0}元
              </span>
            </Card>
          </Col>
        </Row>
      </section>

      <Menu
        onClick={should_change_handler}
        mode="horizontal"
        items={[
          {
            label: "近七日",
            key: "day",
          },
          {
            label: "近一个月",
            key: "month",
            children: [
              {
                label: "单位：日",
                key: "month_day",
              },
              {
                label: "单位：段",
                key: "month_duan",
              },
            ],
          },
          {
            label: "近三个月",
            key: "3_month",
            children: [
              {
                label: "单位：日",
                key: "3_month_day",
              },
              {
                label: "单位：段",
                key: "3_month_duan",
              },
              {
                label: "单位：月",
                key: "3_month_yue",
              },
            ],
          },
          {
            label: "近半年",
            key: "year",
            children: [
              {
                label: "单位：日",
                key: "year_day",
              },

              {
                label: "单位：段",
                key: "year_duan",
              },
              {
                label: "单位：月",
                key: "year_month",
              },
            ],
          },

          {
            label: "近一年",
            key: "ayear",
            children: [
              {
                label: "单位：日",
                key: "ayear_day",
              },
              {
                label: "单位：段",
                key: "ayear_duan",
              },
              {
                label: "单位：月",
                key: "ayear_month",
              },
            ],
          },

          {
            label: "近两年",
            key: "2year",
            children: [
              {
                label: "单位：日",
                key: "2year_day",
              },
              {
                label: "单位：段",
                key: "2year_duan",
              },
              {
                label: "单位：月",
                key: "2year_month",
              },
            ],
          },

          {
            label: "复制数据",
            key: "copy",
            onClick: () => {
              // 在这里复制数据

              // 将数据转换为字符串
              const dataString = JSON.stringify(data);

              // 将字符串写入剪贴板
              navigator.clipboard
                .writeText(
                  `
                ${dataString}

                我给你解释一下这些字段的含义
                {
                  key:"10.1",//用来表示一个时间段
                  接单:10,//接单数据
                  菜鸟: 0,//菜鸟包收了几个
                  基础: 0,//基础包收了几个
                  进阶: 0,//进阶包收了几个
                  金刚: 0,//金刚包收了几个
                  出包:2,//完成的单子数量
                  出包_菜鸟:0,//菜鸟包成功几个
                  出包_基础:0,//基础包成功几个
                  出包_进阶:0,//进阶包成功几个
                  出包_金刚:0,//金刚包成功几个
                  出包率:"0.20",//完成的单子数量除以接单数量
                  prepaid_2:6,//进阶包预付电费
                  prepaid_1:7,//基础包预付电费
                  prepaid_3:15,//金刚包预付电费
                  jump:3,//插队费用
                  pas_1:56,//基础包费用
                  pas_2:156,//进阶包费用
                  pas_3:256,//金刚包费用
                  totalRevenue:100,//总营收
                  Size:346,//产能字典大小GB
                  Amount:3333333333,//产能密码条数
              
                  addedSize:516G,//订单字典大小GB,
                  addedAmount:9999999999,//订单密码条数,
                
                
              }
                `
                )
                .then(() => {
                  message.success("数据已复制");
                })
                .catch((err) => {
                  message.error("复制数据失败: ", err);
                });
            },
          },
        ]}
        style={{ margin: "2.4rem 0" }}
      />

      <Shujutongji should_change_handler={should_change_handler} data={data} />
    </div>
  );
}

/**
 * 专门显示下半部分的数据统计，因为上面数据统计会实时刷新。
 *
 * 这里面所有的函数都是AI生成的，有问题找AI🥳
 *
 */
const Shujutongji = React.memo(({ should_change_handler, data }) => {
  if (!data) return;
  console.log("shujutongjiRender", data);

  // const [options_chubaolv, put_options_chubaolv] = useState(_options_chubaolv);
  // const [options_danshu, put_options_danshu] = useState(_options_danshu);
  // const [options_srtj, put_options_srtj] = useState(_options_srtj);

  const 接单出单 = (data) => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },

        formatter: function (params) {
          let res = params[0].name + "<br/>";
          let 接单Total = 0;
          let 出包Total = 0;

          for (let i = 0; i < params.length; i++) {
            const seriesName = params[i].seriesName;
            const value = params[i].value;
            res += seriesName + ": " + value + "<br/>";

            if (seriesName.includes("出包")) {
              出包Total += value;
            } else {
              接单Total += value;
            }
          }

          res += "接单: " + 接单Total + "<br/>";
          res += "出包: " + 出包Total + "<br/>";

          return res;
        },
      },
      legend: {
        data: [
          "菜鸟",
          "基础",
          "进阶",
          "金刚",
          "出包_菜鸟",
          "出包_基础",
          "出包_进阶",
          "出包_金刚",
        ],
      },
      xAxis: {
        type: "category",
        data: data.map((item) => item.key),
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "菜鸟",
          type: "bar",
          stack: "接单",
          data: data.map((item) => item.菜鸟),
        },
        {
          name: "基础",
          type: "bar",
          stack: "接单",
          data: data.map((item) => item.基础),
        },
        {
          name: "进阶",
          type: "bar",
          stack: "接单",
          data: data.map((item) => item.进阶),
        },
        {
          name: "金刚",
          type: "bar",
          stack: "接单",
          data: data.map((item) => item.金刚),
        },
        {
          name: "出包_菜鸟",
          type: "bar",
          stack: "出包",
          data: data.map((item) => item.出包_菜鸟),
        },
        {
          name: "出包_基础",
          type: "bar",
          stack: "出包",
          data: data.map((item) => item.出包_基础),
        },
        {
          name: "出包_进阶",
          type: "bar",
          stack: "出包",
          data: data.map((item) => item.出包_进阶),
        },
        {
          name: "出包_金刚",
          type: "bar",
          stack: "出包",
          data: data.map((item) => item.出包_金刚),
        },
      ],
    };
  };

  const 成功率 = () => {
    // 初始化X轴和Y轴的数据数组
    const xAxisData = [];
    const seriesData = [];

    // 遍历原始数据，填充X轴和Y轴的数据
    data.forEach((item) => {
      xAxisData.push(item.key); // 添加X轴的日期数据
      const successRate = (item.出包率 * 100).toFixed(2); // 计算成功率并转换为百分比
      seriesData.push(successRate); // 添加Y轴的成功率数据
    });

    // 构建ECharts的配置对象
    const option = {
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          return `${params[0].name}: ${params[0].value}%`; // 添加百分号
        },
        axisPointer: {
          type: "cross",
        },
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        name: "成功率 (%)",
        min: 0,
        max: 100,
        interval: 10, // 可以根据数据范围调整
      },
      series: [
        {
          name: "成功率",

          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 5,
          itemStyle: {
            normal: {
              color: "#c23531",
              lineStyle: {
                color: "#c23531",
              },
            },
          },
          data: seriesData,
        },
      ],
    };

    return option;
  };

  function 订单产能容量() {
    // 初始化数据数组
    let xAxisData = [];
    let seriesSizeData = [];
    let seriesAddedSizeData = [];

    // 遍历原始数据
    data.forEach((item) => {
      // 添加到X轴数据数组
      xAxisData.push(item.key);

      // 添加产能容量（Size）数据
      seriesSizeData.push(item.Size.toFixed(0));

      // 添加当天添加的Task的大小（addedSize）数据
      seriesAddedSizeData.push(item.addedSize.toFixed(0));
    });

    // 构建ECharts配置
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: ["产能容量 (GB)", "订单容量 (GB)"],
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        name: "容量 (GB)",
        axisLabel: {
          formatter: "{value} GB",
        },
      },
      series: [
        {
          name: "产能容量 (GB)",
          type: "line",
          smooth: true,
          symbol: "none", // 不显示数据点
          itemStyle: {
            normal: {
              color: "#ff4500",
              areaStyle: {}, // 启用面积图效果
            },
          },
          data: seriesSizeData,
        },
        {
          name: "订单容量 (GB)",
          type: "line",
          smooth: true,
          symbol: "none", // 不显示数据点
          itemStyle: {
            normal: {
              color: "#4caf50",
              areaStyle: {}, // 启用面积图效果
            },
          },
          data: seriesAddedSizeData,
        },
      ],
    };

    // 返回配置对象
    return option;
  }

  function 订单产能条数() {
    // 初始化数据数组
    let xAxisData = [];
    let seriesAmountData = [];
    let seriesAddedAmountData = [];

    // 遍历原始数据
    data.forEach((item) => {
      // 添加到X轴数据数组
      xAxisData.push(item.key);

      // 添加产能条数（Amount）数据
      seriesAmountData.push(item.Amount || 0);

      // 添加当天添加的条数（addedAmount）数据
      seriesAddedAmountData.push(item.addedAmount || 0);
    });

    // 构建ECharts配置
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
        formatter: function (params) {
          let result = params[0].name + "<br/>";
          params.forEach(function (param) {
            result +=
              param.seriesName +
              " : " +
              (param.value / 1e8).toFixed(1) +
              " 亿条<br/>";
          });
          return result;
        },
      },
      legend: {
        data: ["产能条数", "订单条数"],
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        name: "条数",
        axisLabel: {
          formatter: function (value) {
            return value / 1e8 + " 亿"; // 将条数除以 1e8（即一亿），然后添加 ' 亿'
          },
        },
      },
      series: [
        {
          name: "产能条数",
          type: "line", // 线型图
          data: seriesAmountData,
          areaStyle: {}, // 添加区域样式
          smooth: true, // 平滑曲线
          itemStyle: {
            normal: {
              color: "#ff4500", // 指定颜色
            },
          },
        },
        {
          name: "订单条数",
          type: "line", // 线型图
          data: seriesAddedAmountData,
          areaStyle: {}, // 添加区域样式
          smooth: true, // 平滑曲线
          itemStyle: {
            normal: {
              color: "#4caf50", // 指定颜色
            },
          },
        },
      ],
    };

    // 返回配置对象
    return option;
  }

  function 营收() {
    // 初始化X轴数据数组和系列数据对象
    let xAxisData = [];
    let seriesData = {};
    const totalRevenueData = [];

    // 商品销售额映射
    const salesMap = {
      pas_0: [],
      pas_1: [],
      pas_2: [],
      pas_3: [],
      jump: [],
      prepaid_1: [],
      prepaid_2: [],
      prepaid_3: [],
      prepaid_mao: [],
    };

    // 遍历原始数据
    data.forEach((item) => {
      // 添加到X轴数据数组
      xAxisData.push(item.key);

      // 累加每个商品的销售额
      [
        "pas_0",
        "pas_1",
        "pas_2",
        "pas_3",
        "jump",
        "prepaid_1",
        "prepaid_2",
        "prepaid_3",
        "prepaid_mao",
      ].forEach((key) => {
        if (item[key] !== undefined) {
          salesMap[key].push(item[key]);
        } else {
          salesMap[key].push(0); // 如果某个商品在某天没有销售，则添加0
        }
      });

      // 添加 totalRevenue 到 totalRevenueData
      totalRevenueData.push(item.totalRevenue);
    });

    // 构建ECharts配置
    let option = {
      grid: {
        top: "20%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          // let result = params[0].name + "<br/>";
          // params.forEach(function (param) {
          //   result += param.seriesName + " : " + param.value + " 元<br/>";
          // });
          // // 添加总营收到提示框
          // // let totalRevenue = seriesData.totalRevenue[params[0].dataIndex] || 0;
          // // result += "总收入 : " + totalRevenue + "元" + "<br/>";

          // result += `<br/>总营收 : ${totalRevenueData[params[0].dataIndex]}`;

          // return result;

          let res = params[0].name;
          params.forEach((param) => {
            res += `<br/>${param.marker} ${param.seriesName} : ${param.value}`;
          });
          res += `<br/>总营收 : ${totalRevenueData[params[0].dataIndex]}`;
          return res;
        },
      },
      legend: {
        data: Object.keys(salesMap).map((key) =>
          key.replace("_", " ").toUpperCase()
        ),
      },
      xAxis: {
        type: "category",
        data: xAxisData,
      },
      yAxis: {
        type: "value",
        name: "Amount (元)",
      },
      series: Object.keys(salesMap)
        .map((key) => ({
          name: key.replace("_", " ").toUpperCase(),
          type: "bar",
          stack: "总量",
          data: salesMap[key],
        }))
        .concat({
          name: "Total Revenue",
          type: "bar",
          stack: "总量",
          data: seriesData.totalRevenue || [],
          itemStyle: {
            normal: {
              color: "#ff4500",
            },
          },
        }),
    };

    return option;
  }

  function 营收矩形树() {
    // 准备用于构建矩形树图的数据
    let treemapData = {
      name: "Total",
      value: 0, // 初始化总金额为0
      children: [],
    };

    // 遍历原始数据，构建矩形树图的数据结构
    data.forEach((item) => {
      treemapData.value += item.totalRevenue;
      // 假设每个商品代号对应的收入存储在以该代号命名的字段中
      Object.keys(item).forEach((key) => {
        if (
          key.startsWith("pas_") ||
          key === "jump" ||
          key.startsWith("prepaid")
        ) {
          // 提取商品名称
          const productName = key.replace(/_(\d)/g, " $1");
          // 检查商品是否已经在children中，如果不在，则添加一个新的商品节点
          let productExists = treemapData.children.some(
            (node) => node.name === productName
          );
          if (!productExists) {
            treemapData.children.push({
              name: productName,
              value: 0, // 初始化商品的收入为0
              children: [],
            });
          }
          // 获取商品节点
          let productNode = treemapData.children.find(
            (node) => node.name === productName
          );
          // 累加商品的收入
          productNode.value += item[key];
        }
      });
    });

    // 构建 ECharts 配置
    let option = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `${params.name}: ${params.value}元 (总金额: ${treemapData.value}元)`;
        },
      },
      series: [
        {
          type: "treemap",
          roam: true,
          itemStyle: {
            color: function (params) {
              // 使用 params.dataIndex 来计算颜色
              const colors = ["#123456", "#654321", "#abcdef", "#fedcba"];
              return colors[params.dataIndex % colors.length];
            },
          },
          leaves: {
            label: {
              show: true,
              formatter: "{b}: {c}元",
            },
          },
          data: [treemapData],
        },
      ],
    };

    return option;
  }

  function 接单类型图() {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow", // 显示阴影指针
        },
      },
      legend: {
        data: ["cap", "hccap", "hccapx", "hc22000", "pcapng", "pcap"],
      },
      xAxis: {
        type: "category",
        data: data.map((item) => item.key), // 假设每个数据项包含一个 'key' 字段标识类别或时间
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "cap",
          type: "bar",
          stack: "接单类型",
          data: data.map((item) => item.cap),
        },
        {
          name: "hccap",
          type: "bar",
          stack: "接单类型",
          data: data.map((item) => item.hccap),
        },
        {
          name: "hccapx",
          type: "bar",
          stack: "接单类型",
          data: data.map((item) => item.hccapx),
        },
        {
          name: "hc22000",
          type: "bar",
          stack: "接单类型",
          data: data.map((item) => item.hc22000),
        },
        {
          name: "pcapng",
          type: "bar",
          stack: "接单类型",
          data: data.map((item) => item.pcapng),
        },
        {
          name: "pcap",
          type: "bar",
          stack: "接单类型",
          data: data.map((item) => item.pcap),
        },
      ],
    };
  }

  return (
    <section className={classes.shujutongji}>
      <Typography.Title level={3}>订单产能（条数）</Typography.Title>

      <ReactECharts
        option={订单产能条数()}
        theme={"light"} // 如果需要，可以选择主题
      />

      <Typography.Title level={3}>接单/出单</Typography.Title>

      <ReactECharts
        option={接单出单(data)}
        theme={"light"} // 如果需要，可以选择主题
      />

      <Typography.Title level={3}>成功率</Typography.Title>

      <ReactECharts
        option={成功率(data)}
        theme={"light"} // 如果需要，可以选择主题
      />

      <Typography.Title level={3}>营收</Typography.Title>
      <ReactECharts
        option={营收()}
        theme={"light"} // 如果需要，可以选择主题
        style={{ height: "500px" }} // 设置高度
      />

      <Typography.Title level={3}>订单产能（容量）</Typography.Title>

      <Alert
        banner
        message={
          <Marquee pauseOnHover gradient={false}>
            1.订单只统计等待中，进行中，已完成的任务；2.产能不统计isUnneed为true的任务；3.订单可和产能都可以正确统计定制化；4.产能条数是由hashcat反馈，最客观的数据📊；5.产能容量受制于Business的时间，可能不全面，扩大时间维度可以统计全；
          </Marquee>
        }
      />

      <ReactECharts
        option={订单产能容量()}
        theme={"light"} // 如果需要，可以选择主题
      />

      <Typography.Title level={3}>收入矩形图</Typography.Title>
      <ReactECharts
        option={营收矩形树()}
        style={{ height: "300px", width: "100%" }}
      />

      <Typography.Title level={3}>接单类型图</Typography.Title>
      <ReactECharts
        option={接单类型图()}
        style={{ height: "300px", width: "100%" }}
      />

      <div style={{ height: "200px" }}></div>
    </section>
  );
});

export default Data;
