import { Routes, Route, Navigate } from "react-router";
import Upload from "./Client/Upload";
import Running from "./Control/Running";
import CapXiangmu from "./Control/HistoricalTasks";
import Dics from "./Control/Dics";
import XiugaiRenwu from "./Control/XiugaiRenwu";
import Feedback from "./Client/Feedback/index.js";
import Dingdan from "./Control/Dingdan";
import Data from "./Control/Data";
import Client from "./Client/Client";
import Control from "./Control/Control";
import 主页 from "./主页/index.js";
import React, { useEffect, useState } from "react";
import Upload背景 from "./assets/三国风插画/用户反馈背景1.webp";
import Login from "./Control/Login.js";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/client" element={<Client />}></Route>
        <Route path="/upload" element={<UploadComponent />}></Route>
        <Route path="/" element={<主页 />}></Route>
        {/* <Route path="/" element={<OldMain />}></Route> */}
        {/* <Route path="upload" element={<Upload />}></Route> */}
        <Route path="feedback" element={<Feedback />}></Route>
        <Route path="三国许可证" element={<Login />} />
        <Route path="三国通行证" element={<Login />} />
        <Route path="control" element={<Control />}>
          <Route index element={<Navigate to="running" />} />
          <Route path="running" element={<Running />} />
          <Route path="capxiangmu" element={<CapXiangmu />} />
          <Route path="data" element={<Data />} />
          <Route path="dics" element={<Dics />} />
          <Route path="xiugairenwu/:id" element={<XiugaiRenwu />} />
          <Route path="dingdan" element={<Dingdan />} />
        </Route>
      </Routes>
    </div>
  );
}

//对Upload组件的封装
const UploadComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 假设768px是移动设备和桌面设备的分界点
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 组件挂载后立即执行一次，以确定初始状态

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 空依赖数组确保effect只运行一次（挂载时）和清理函数只运行一次（卸载时）

  return (
    <div
      style={{
        backgroundColor: "#5827ce",
        height: "100vh",
        backgroundImage: `url(${Upload背景})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      data-description="对Upload的封装"
    >
      <Upload open={true} width={isMobile ? "100%" : "50%"} />
    </div>
  );
};

export default App;
