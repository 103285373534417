import classes from "./Dics.module.css";
import { useGetAllQuery } from "../Api/ZidianApi";
import { useEffect, useState } from "react";
import { Pen, XSquare, CheckSquare } from "@phosphor-icons/react";
import { configure } from "../Stores/configure";
import {
  Button,
  Drawer,
  Modal,
  Popover,
  Progress,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from "antd";
import DicEditModal from "../ModelWindows/DicEditModal";
import { Helmet } from "react-helmet";

const Tem_Data = {};
const { speed } = configure.getInitialState();

/**
 * # Dics组件
 * 用来显示字典，对字典进行增删改查。
 *
 * ## 函数
 *
 * - toggle()：切换模态框的显示状态
 *
 * ## State
 * - allDicTaskData:所有的字典数据
 * - visiable:是否显示Modal
 * - updateData:修改的数据
 * - drawerContent:抽屉的内容
 * - isDrawerVisible:抽屉是否显示
 */
function Dics() {
  //----------钩子函数----------

  const [allDicTaskData, putAllDicTaskData] = useState();

  const [visiable, putVisiable] = useState(false);

  const [updateData, setUpdateData] = useState();

  const [drawerContent, setDrawerContent] = useState("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const toggle = () => {
    putVisiable((state) => !state);
  };

  //API0.获得所有字典任务

  const { data: allDicTaskResult } = useGetAllQuery();

  useEffect(() => {
    putAllDicTaskData(allDicTaskResult?.data);
  }, [allDicTaskResult]);

  //当前是新建字典任务还是修改字典任务
  const [whatAmiDo, putWhatAmiDo] = useState();

  //----------自定义函数-----------

  //负责渲染所有的字典任务列表
  function renderTask() {
    if (!allDicTaskData) return <Skeleton />;

    // 使用reduce函数根据parent字段对数据进行分组，数据演示：
    //[{parent:"进阶",children:[{},{},{}]}]
    const groupedData = allDicTaskData.reduce((groups, item) => {
      const parent = item.parent || "0.未定义";
      const group = groups.find((group) => group.parent === parent);
      if (group) {
        group.children.push(item);
      } else {
        groups.push({ parent: parent, children: [item] });
      }
      return groups;
    }, []);

    // 定义表格的列
    const columns = [
      {
        title: "字典名称",
        dataIndex: "zidian_name",
        key: "zidian_name",
        render: (zidian_name, record) => {
          const content = record?.successHistory?.map(
            (item) => `${item.wifi_name}: ${item.password_text}`
          );
          return (
            <div
              onClick={() => {
                setDrawerContent(content);
                setIsDrawerVisible(true);
              }}
            >
              {zidian_name}
            </div>
          );
        },
      },
      {
        title: "容量（GB）",
        dataIndex: "Size",
        key: "Size",
        sorter: (a, b) => a.Size - b.Size,
      },
      {
        title: "优先级",
        dataIndex: "priority",
        key: "priority",
        sorter: (a, b) => a.priority - b.priority,
        render: (priority) => {
          switch (priority) {
            case 1:
              return "滞后";
            case 2:
              return "普通";
            case 3:
              return "优先";
            case 4:
              return "紧急";

            default:
              return "未定义";
          }
        },
      },
      {
        title: "自动启用",
        dataIndex: "auto_enable",
        key: "auto_enable",
        render: (auto_enable) => (auto_enable ? "✅" : "❌"),
      },
      {
        title: "运行次数",
        dataIndex: "runTimes",
        key: "runTimes",
        sorter: (a, b) => a.runTimes - b.runTimes,
      },
      {
        title: "成功次数",
        dataIndex: "successTimes",
        key: "successTimes",
        sorter: (a, b) => a.successTimes - b.successTimes,
        render: (successTimes, record) => (
          <Popover content={record?.note}>
            <p>{successTimes}</p>
          </Popover>
        ),
      },
      {
        title: "成功率",
        sorter: (a, b) => {
          const successRateA = a.runTimes
            ? (a.successTimes / a.runTimes) * 100
            : 0;
          const successRateB = b.runTimes
            ? (b.successTimes / b.runTimes) * 100
            : 0;
          return successRateA - successRateB;
        },
        key: "successRate",
        width: 200, // 设置列的宽度为200像素
        render: (record) => {
          const successRate = record.runTimes
            ? (record.successTimes / record.runTimes) * 100
            : 0;

          const visualSuccessRate = successRate * 10; // 使用最小的成功率作为基准
          return (
            <Tooltip title={`${visualSuccessRate.toFixed(2)}‰`}>
              <Progress
                percent={visualSuccessRate.toFixed(2)}
                format={(_p) => `${_p}‰`}
              />
            </Tooltip>
          );
        },
      },
      {
        title: "操作",
        render: (record) => (
          <Button
            size="small"
            onClick={() => {
              setUpdateData(record), putVisiable(true);
              putWhatAmiDo("xiugai");
            }}
          >
            修改
          </Button>
        ),
      },
    ];

    return (
      <div>
        <Helmet>
          <title>字典管理</title>
        </Helmet>
        {groupedData
          .sort((a, b) => a.parent.localeCompare(b.parent)) // 对groupedData进行排序
          .map((group) => (
            <div key={group.parent} className={classes.table_container}>
              <h2>{group.parent}</h2>
              <Table
                pagination={{
                  defaultPageSize: 20,
                  pageSizeOptions: ["10", "20", "30", "40"],
                  showSizeChanger: true,
                }}
                dataSource={group.children}
                columns={columns}
                rowKey="_id"
                summary={(pageData) => {
                  const totalSize = pageData.reduce(
                    (sum, record) => sum + record.Size,
                    0
                  );

                  const totalSize_autoEnable = pageData.reduce(
                    (sum, record) => {
                      if (record?.auto_enable) return sum + record.Size;
                      return sum;
                    },
                    0
                  );

                  const totalSuccessTimes = pageData.reduce(
                    (sum, record) =>
                      sum + record?.successTimes ? record.successTimes : 0,
                    0
                  );
                  const totalRunTimes = pageData.reduce(
                    (sum, record) => sum + record.runTimes,
                    0
                  );
                  const totalSuccessRate = pageData.reduce((sum, record) => {
                    const successRate = record.runTimes
                      ? record.successTimes / record.runTimes
                      : 0;
                    return sum + successRate;
                  }, 0);

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>总计</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {Number(totalSize).toFixed(1)} GB
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>无</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {totalSize_autoEnable.toFixed(1)}GB
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>次数</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {totalSuccessTimes}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Tooltip
                            title={`${Number(totalSuccessRate * 100).toFixed(2)}%`}
                          >
                            <Progress
                              percent={Number(totalSuccessRate * 100).toFixed(
                                2
                              )}
                              format={(_p) => `${_p}%`}
                            />
                          </Tooltip>
                        </Table.Summary.Cell>

                        {/* 更多列... */}
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </div>
          ))}
      </div>
    );
  }

  //负责修改Parent Name
  function xiugaiParentName(e, id) {}
  //db.zidians.updateMany({parent:"进阶"},{$set:{parent:"2.进阶"}})

  return (
    <section className={classes.TaskContainer}>
      <div className={classes.Title}>
        <h1>字典列表</h1>
        <div style={{ display: "flex", gap: "4.8rem" }}>
          <Button
            color="green"
            onClick={() => {
              putWhatAmiDo("xinjian");
              setUpdateData({});
              putVisiable(true);
            }}
          >
            新建字典任务
          </Button>
        </div>
      </div>

      <section className={classes.main}>
        <ul className={classes.TaskList}>{renderTask()}</ul>
      </section>

      <DicEditModal
        whatAmiDo={whatAmiDo}
        visiable={visiable}
        toggle={toggle}
        _updateData={updateData}
      />

      <Drawer
        title="详细信息"
        placement="right"
        closable={false}
        onClose={() => setIsDrawerVisible(false)}
        open={isDrawerVisible}
      >
        {Array.isArray(drawerContent)
          ? drawerContent.map((item, index) => (
              <Typography.Paragraph key={index}> {item}</Typography.Paragraph>
            ))
          : "错误：drawerContent 不是一个数组"}
      </Drawer>
    </section>
  );
}

export default Dics;
