import { useEffect, useState } from "react";
import {
  useGet_allQuery,
  usePostoneMutation,
  usePutoneMutation,
  useDeleteMutation,
} from "../Api/PayApi";
import {
  Button,
  DatePicker,
  Table,
  Modal,
  Form,
  Input,
  Checkbox,
  Popconfirm,
  Select,
  Alert,
} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import "dayjs/locale/zh-cn";
import { Trash, HandPointing } from "@phosphor-icons/react";
import dayjs from "dayjs";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";

/**
 * # 订单组件
 *
 * 用来显示交易订单，对订单进行增删改查。
 *
 * ## State
 *
 * - query 查询条件
 *
 * ## 函数
 *
 * - date_change 修改查询条件
 *
 * ## return
 *
 * 0. 按钮集合
 * 1. 表格
 * 2. 模态窗口
 *
 *
 * @returns
 */
function Dingdan() {
  //钩子函数

  const [form] = Form.useForm();

  //模态窗口显示

  const [modal, put_modal] = useState(false);

  //API
  const [query, put_query] = useState();
  const { data: result, refetch } = useGet_allQuery(query);
  const [post_one, post_result] = usePostoneMutation();
  const [delete_one, delete_result] = useDeleteMutation();
  const [putone, put_result] = usePutoneMutation();

  //当前要修改的目标

  const [target, put_target] = useState();

  //当前的动作 是新增 还是修改 决定模态窗口的作用

  const [action, put_action] = useState();

  //自定义函数

  function data_change(date, diy) {
    let start, end, query;
    if (date) {
      start = new Date(date[0].$y, date[0].$M, date[0].$D).getTime();
      end = new Date(date[1].$y, date[1].$M, date[1].$D).getTime();
      query = `?create_at[gte]=${start}&create_at[lte]=${end}`;
    } else {
      start = new Date(diy).getTime();
      end = new Date(diy);
      end.setDate(new Date(diy).getDate() + 1);
      end = end.getTime();
      query = `?create_at[gte]=${start}&create_at[lte]=${end}`;
      console.log(query);
    }

    //getData(query);
    put_query(query);
  }

  //模态窗口提交

  const onFinish = (values) => {
    const data = {
      subject: values.商品名称,
      wifi_name: values.WiFi名,
      money: values.金额,
      entry: values.缴清,
      note: values.备注,
    };
    console.log(data);
    if (action === "post") {
      data.status = "diy";
      data.create_at = new Date(
        values.创建时间.$y,
        values.创建时间.$M,
        values.创建时间.$D
      ).setHours(12);
      post_one(data);
      put_modal(false);
    }
    if (action === "put") {
      console.log(values);
      console.log("是修改");

      data.create_at = target.create_at;

      putone({
        id: target._id,
        body: data,
      });
      put_modal(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ marginTop: "3.6rem" }}>
      <Helmet>
        <title>订单明细</title>
      </Helmet>
      <div style={{ display: "flex", gap: "1.2rem" }}>
        <DatePicker.RangePicker
          onChange={data_change}
          locale={locale}
          picker="date"
        />
        <Button
          onClick={function look_today() {
            const tem = new Date();
            tem.setHours(0);
            tem.setMinutes(0);
            tem.setSeconds(0);
            data_change(undefined, tem);
          }}
        >
          看今天
        </Button>

        <Button
          onClick={function look_today() {
            const tem = new Date(
              new Date(Date.now()).setDate(new Date().getDate() - 1)
            );
            tem.setHours(0);
            tem.setMinutes(0);
            tem.setSeconds(0);
            tem.setMilliseconds(0);
            data_change(undefined, tem);
          }}
        >
          看昨天
        </Button>

        <Button
          onClick={function zengjia() {
            put_action("post");
            put_modal(true);
          }}
        >
          手动新增订单
        </Button>
      </div>

      <Table
        style={{ marginTop: "2.4rem", width: "100%", overflowX: "auto" }}
        dataSource={result?.data}
        columns={[
          {
            title: "日期",
            dataIndex: "create_at",
            key: "create_at",
            render: (date) => (
              <p>{new Date(date).toLocaleString("zh-CN").slice(5)}</p>
            ),
            sorter: (a, b) => {
              return (
                new Date(a.create_at).getTime() -
                new Date(b.create_at).getTime()
              );
            },
          },
          {
            title: "商品",
            dataIndex: "subject",
            key: "subject",
            render: (text) => {
              switch (text) {
                case "prepaid_1":
                  return "基础包预付款";
                case "prepaid_2":
                  return "进阶包预付款";
                case "prepaid_3":
                  return "金刚包预付款";
                case "prepaid_mao":
                  return "光猫包预付款";
                case "pas_0":
                  return "菜鸟包密码";
                case "pas_1":
                  return "基础包密码";
                case "pas_2":
                  return "进阶包密码";
                case "pas_3":
                  return "金刚包密码";
                case "jump":
                  return "插队";
              }
              return text;
            },
          },
          {
            title: "用户",
            dataIndex: "wifi_name",
            key: "widi_name",
          },
          {
            title: "订单金额",
            dataIndex: "money",
            key: "money",
            render: (yuan) => <p>{yuan}元</p>,
          },
          {
            title: "交易状态",
            dataIndex: "status",
            key: "status",
            // defaultFilteredValue: "success",
            filters: [
              {
                text: "已支付",
                value: "success",
              },
              {
                text: "未支付",
                value: "init",
              },
              {
                text: "手动订单",
                value: "diy",
              },
            ],
            filterMode: "tree",
            filterSearch: true,
            onFilter: (value, record) => {
              return record.status === value;
            },
            width: "30%",
            render: (record) => {
              switch (record) {
                case "success":
                  return "已支付";
                case "init":
                  return "未支付";
                case "diy":
                  return "手动订单";
              }
            },
          },
          {
            title: "备注",
            dataIndex: "note",
            key: "note",
          },
          {
            title: "是否入账",
            key: "ruzhang",
            render: (all) => {
              if (all.status === "success") {
                return "是";
              } else if (all.entry === true) {
                return "是";
              } else return "否";
            },
          },
          {
            title: "操作",
            render: (test) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "1.2rem",
                    justifyContent: "flex-start",
                  }}
                >
                  <Popconfirm
                    title="是否删除？"
                    onConfirm={function shanchu() {
                      console.log(test._id);
                      delete_one(test._id);
                    }}
                  >
                    <Trash size={24} />
                  </Popconfirm>
                  <HandPointing
                    size={24}
                    onClick={function xiugai() {
                      const date = new Date(test.create_at);
                      // date.setHours(0);
                      // date.setMinutes(0);
                      // date.setSeconds(0),
                      form.setFieldsValue({
                        创建时间: dayjs(date),
                        商品名称: test.subject,
                        WiFi名: test.wifi_name,
                        金额: test.money,
                        备注: test?.note,
                        缴清: test?.entry,
                      });
                      put_modal(true);
                      put_action("put");
                      put_target(test);
                    }}
                  />
                </div>
              );
              //return <>{JSON.stringify(test)}</>;
            },
          },
        ]}
      />

      <Modal
        title="自定义订单"
        open={modal}
        footer={[]}
        onCancel={() => {
          put_modal(false);
        }}
      >
        <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              手动添加的订单，会被统计，但是不会被记录到小数据台里。
            </Marquee>
          }
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="创建时间" name="创建时间">
            <DatePicker
              locale={locale}
              disabled={action === "post" ? false : true}
            />
          </Form.Item>
          <Form.Item label="商品名称" name="商品名称">
            <Select>
              {/* 菜鸟包密码 */}
              <Option value="pas_0">菜鸟包密码</Option>
              {/* 基础包密码 */}
              <Option value="pas_1">基础包密码</Option>
              {/* 进阶包密码 */}
              <Option value="pas_2">进阶包密码</Option>
              {/* 金刚包密码 */}
              <Option value="pas_3">金刚包密码</Option>
              {/* 插队 */}
              <Option value="jump">插队</Option>
              {/* 基础包预付电费 */}
              <Option value="prepaid_1">基础包预付电费</Option>
              {/* 进阶包预付电费 */}
              <Option value="prepaid_2">进阶包预付电费</Option>
              {/* 金刚包预付电费 */}
              <Option value="prepaid_3">金刚包预付电费</Option>
              {/* 光猫专跑预付费 */}
              <Option value="prepaid_mao">光猫专跑预付费</Option>
            </Select>
          </Form.Item>
          <Form.Item label="WiFi名" name="WiFi名">
            <Input />
          </Form.Item>
          <Form.Item label="金额" name="金额">
            <Input />
          </Form.Item>
          <Form.Item label="备注" name="备注">
            <Input />
          </Form.Item>
          <Form.Item label="缴清" name="缴清" valuePropName="checked">
            <Checkbox
              disabled={
                action === "put" && target?.status !== "diy" ? true : false
              }
            >
              已缴清
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {`${
                action === "post"
                  ? "新增"
                  : action === "put"
                    ? "修改"
                    : "未知操作"
              }`}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Dingdan;
