import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { configure } from "../Stores/configure";

const serverAddress = configure.getInitialState().serverAddress;

export const ZidianApi = createApi({
  reducerPath: "ZidianApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serverAddress + "/zidian",
    prepareHeaders: (headers, { getState }) => {
      // 尝试从 localStorage 中获取 token
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["zidian"],
  endpoints(build) {
    return {
      getAll: build.query({
        providesTags: ["zidian"],
        query() {
          return "/";
        },
      }),

      getDepent: build.query({
        query() {
          return "getDepent";
        },
      }),

      getDicFileLists: build.query({
        query() {
          return "getDicFileLists";
        },
      }),

      postOneZidian: build.mutation({
        invalidatesTags: ["zidian"],
        query(stu) {
          //console.log(stu);
          return {
            url: "/",
            method: "POST",
            body: stu,
          };
        },
      }),

      patchOneZidian: build.mutation({
        invalidatesTags: ["zidian"],
        query(stu) {
          return {
            url: `/${stu._id}`,
            method: "PATCH",
            body: stu,
          };
        },
      }),

      deleteOneZidian: build.mutation({
        invalidatesTags: ["zidian"],
        query(stu) {
          return {
            url: `/${stu._id}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useGetDepentQuery,
  useGetDicFileListsQuery,
  usePostOneZidianMutation,
  useGetAllQuery,
  usePatchOneZidianMutation,
  useDeleteOneZidianMutation,
} = ZidianApi;
