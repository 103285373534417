import { Modal, Input, Space, Form, Checkbox, Button, message } from "antd";
import { useState } from "react";
import axios from "axios";
import { configure } from "../Stores/configure";

const { serverAddress } = configure.getInitialState();

function Login() {
  const [USER, setUSER] = useState("");
  const [PASS, setPASS] = useState("");

  const onFinish = (values) => {
    console.log(USER, PASS);

    (async () => {
      try {
        const result = await axios({
          url: `${serverAddress}/sanguoxukezheng`,
          method: "POST",
          data: {
            USER,
            PASS,
          },
        });

        localStorage.setItem("token", result.data.token);
        message.success("登录成功");
      } catch (error) {
        message.error(`登录失败${error.message}`);
      }
    })();
  };

  return (
    <Modal open={true} onOk={onFinish}>
      <h1 style={{ marginBottom: "1.2rem" }}>登录</h1>

      <Form.Item
        label="USER"
        name="USER"
        rules={[
          {
            required: true,
            message: "Please input your USER!",
          },
        ]}
      >
        <Input
          value={USER} // 将USER状态变量绑定到Input组件的value属性
          onChange={(e) => setUSER(e.target.value)} // 使用onChange事件处理器更新USER状态
        />
      </Form.Item>

      <Form.Item
        label="PASS"
        name="PASS"
        rules={[
          {
            required: true,
            message: "Please input your PASS!",
          },
        ]}
      >
        <Input
          value={PASS} // 将USER状态变量绑定到Input组件的value属性
          onChange={(e) => setPASS(e.target.value)} // 使用onChange事件处理器更新USER状态
        />
      </Form.Item>
    </Modal>
  );
}

export default Login;
