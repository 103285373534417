import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import {
  useGetOneByMacQuery,
  usePatchStatusMutation,
} from "../../Api/BusinessApi";
import classes from "./FeedBack.module.css";
import {
  Button,
  notification,
  Spin,
  Alert,
  Card,
  Badge,
  Descriptions,
  Modal,
  Popover,
  message,
  Collapse,
  Popconfirm,
  Skeleton,
  Result,
  Image,
  Typography,
  Progress,
  Tooltip,
  App,
} from "antd";
import { Bird, Crown, Package } from "@phosphor-icons/react";
import nzhcn from "nzh/cn";
import nzh from "nzh";
import Marquee from "react-fast-marquee";
import Pay from "../Pay";
import { useCrisp, useSaleSmartly, useSendSaleSmartlyMessage } from "../utils";
import Info from "./Components/Info";
import 四个包 from "../../assets/四个包.png";
import EChartsReact from "echarts-for-react";
import { Graph } from "@phosphor-icons/react/dist/ssr";
import Footer from "../../Components/footer";
const { Paragraph } = Typography;

let noticed = false; //控制finished模态窗口 只弹出一次
let noticed_scuess = false;

/**
 * # 用于显示用户反馈的页面
 *
 * ## State
 * - `mac_ap`：存储路由器mac地址。
 * - `notrack`：存储notrack的状态。
 * - `BusinessData`：存储加载到的Business数据。
 * - `ZidianData`：存储Zidian数据的状态。
 * - `taskData`：存储Task数据的状态。
 * - `pay_modal_open`：存储支付模态窗口的状态。
 * - `pay_modal_data`：存储支付模态窗口的数据。
 * - `scuess_modal`：控制scuess模态窗口的状态。
 * - `finished_modal`：控制finished模态窗口的状态。
 * - `cainiao_modal`：控制cainiao模态窗口的状态。
 * - `isnew`：如果是true，代表这是刚提交的，需要向客服系统发消息。
 * - ``
 *
 * ## 函数
 * - `useCrisp`：使用Crisp服务。
 * - `put_mac_ap`：更新mac_ap的函数。
 * - `put_notrack`：更新notrack的函数。
 * - `useGetOneByMacQuery`：获取mac_ap和notrack的查询函数。
 * - `usePatchStatusMutation`：更新状态的函数。
 * - `put_BusinessData`：更新BusinessData的函数。
 * - `put_zidiandata`：更新ZidianData的函数。
 *
 * - `render_task_section`：渲染下方的两组数据🦋
 *
 *
 */
function Feedback() {
  // ---------------------------钩子函数----------------------------
  //勾出当前的请求参数 其中包含的mac_ap是请求的基础,notrack=true不更新lastViewDate

  const { search } = useLocation();
  //加载时初始化，获取mac_ap和notrack
  useEffect(function init() {
    const searchParams = new URLSearchParams(search);
    mac_ap = searchParams.get("mac_ap");
    put_mac_ap(mac_ap);
    put_notrack(searchParams.get("notrack"));
    put_is_new(Boolean(searchParams.get("new")));
    // alert(searchParams.get("new"));
  }, []);

  //存储路由器mac地址
  let [mac_ap, put_mac_ap] = useState();
  const [notrack, put_notrack] = useState();
  const [is_new, put_is_new] = useState(false);

  //API
  const BusinessResult = useGetOneByMacQuery(
    { mac_ap, notrack },
    { pollingInterval: 3000 }
  );
  // const [patchStatus] = usePatchStatusMutation();弃用，现在升级使用Pay自动通知后台

  //存储加载到的Business Tasks Zidians数据
  const [BusinessData, put_BusinessData] = useState("init");
  const [ZidianData, put_zidiandata] = useState();
  const [TaskData, put_taskdata] = useState();

  // console.log("is_new", is_new);
  useSaleSmartly(BusinessData?.mail);
  useSendSaleSmartlyMessage(
    notrack === "true"
      ? null
      : (BusinessData?.status === "solved" && !BusinessData?.payed) === true
        ? "跑出未付款"
        : is_new === true
          ? "新提交"
          : null,
    BusinessData?.wifi_name
  );

  //存储支付相关的数据

  const [pay_modal_open, put_pay_modal_open] = useState(false);
  const [pay_modal_data, put_pay_modal_data] = useState();

  //Business完成时 弹出对话框 询问客户是否需要升级包
  const [finished_modal, put_finished_modal] = useState();

  //Business跑出且未付款时 询问客服是否需要付款
  const [scuess_modal, put_scuess_modal] = useState();
  const [cainiao_modal, put_cainiao_modal] = useState(true);

  useEffect(
    function init_businessdata() {
      if (BusinessResult.isSuccess) {
        put_BusinessData(BusinessResult?.data?.data?.business);
        put_zidiandata(BusinessResult?.data?.data?.zidian);
        put_taskdata(BusinessResult?.data?.data?.tasks);
        document.title = `WIFI《${BusinessResult?.data?.data?.business?.wifi_name}》实时状态--曹操wifi`;
      }
    },
    [BusinessResult]
  );

  // ---------------------------自定义函数----------------------------

  // 用于Pay的函数 防止过度刷新 使用useCallback
  const cancel = useCallback(() => {
    put_pay_modal_open(false);
  }, []);

  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgressPercent((prevPercent) => {
        if (prevPercent >= 100) {
          return 0; // 重置为0，实现循环
        }
        return prevPercent + 1; // 每次增加1
      });
    }, 1000); // 每秒更新一次进度

    // 清除定时器，避免在组件卸载后继续运行
    return () => clearInterval(intervalId);
  }, []); // 空依赖数组，确保effect只运行一次

  // ---------------------------渲染----------------------------

  if (BusinessResult?.error) {
    // 在这里处理错误
    return (
      <Result
        status="500"
        title="500"
        subTitle="服务器正在维护，请稍等片刻恢复。"
      />
    );
  }

  if (BusinessResult?.isLoading || BusinessResult.isUninitialized) {
    return <Skeleton style={{ marginTop: "2.4rem" }} active />;
  }
  if (BusinessResult.isSuccess && !BusinessData && BusinessData !== "init") {
    return <Result status="404" title="404" subTitle="查找错误，没有此哈希" />;
  }
  return (
    <App style={{ marginBottom: "12.8rem" }}>
      <Info
        BusinessData={BusinessData}
        put_pay_modal_data={put_pay_modal_data}
        put_pay_modal_open={put_pay_modal_open}
      />
      {/* <Alert
        banner
        message={
          <Marquee pauseOnHover gradient={false}>
            请收藏此页面 用于查看任务状态 在线付款或升级 想找回此页面 可在浏览器
            <span style={{ color: "#1971c2" }}>浏览记录</span>查看
          </Marquee>
        }
      /> */}

      <section
        data-description="排队数量"
        style={{
          display: BusinessData.status === "running" ? "flex" : "none",
          flexDirection: "column",
          marginTop: "1.8rem",
        }}
      >
        <Typography.Title className={classes.tips} level={4}>
          任务进度：
          {BusinessData?.排队数量
            ? `前方${BusinessData.排队数量}任务排队，等待...`
            : "你的任务马上开始"}
        </Typography.Title>
        <span className={classes.tips}>
          <Progress
            percent={progressPercent}
            size={["100%", 10]}
            format={() => {}}
          />
          {/* <p>提示：排队中，前方有{BusinessData.排队数量}个任务，请耐心等待</p> */}
          <Popover
            title={
              <p style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Crown size={32} weight="duotone" color="#f08c00" />
                插队
              </p>
            }
            content={
              <p>
                如果您急需密码 可以点击此按钮付费 <br />
                您的任务将被提高至最高优先级 <br />
              </p>
            }
          >
            <Button
              type="primary"
              onClick={function chadui() {
                let pay_data = {
                  subject: "jump",
                };

                pay_data.business_id = BusinessData._id;
                pay_data.wifi_name = BusinessData.wifi_name;
                pay_data.pay_type = "43";

                put_pay_modal_data(pay_data);
                put_pay_modal_open(true);
              }}
            >
              插队
            </Button>
          </Popover>
        </span>
      </section>

      <section aria-description="任务部分" className={classes.task_section}>
        {/* render_task函数作用：将所有字典任务 分组显示 并且根据当前Task的状态 显示等待 运行在 或者已结束 */}
        {(function render_task_section() {
          //0.提示消息 完成或者要求升级
          switch (BusinessData?.status) {
            case "solved":
              if (BusinessData?.payed === false) {
                if (!noticed_scuess) {
                  put_scuess_modal(true);
                  noticed_scuess = true;
                }
                break;
              } else {
                if (!BusinessData?.password?.text) {
                  return (
                    <Modal title="查看密码过程出现错误" open={true}>
                      <p>
                        您的密码已跑出，但是网站自动查看时出现错误，请不要着急，将此情况截图反馈，我们的售后会进行处理💜
                      </p>
                    </Modal>
                  );
                }
                const parts = BusinessData?.password?.text.split(":");
                const hash = parts[0];
                const password = parts[parts.length - 1];
                return (
                  <Result
                    style={{ gridColumn: "1/span2" }}
                    status="success"
                    title={`逆向成功，密码为${password}`}
                    subTitle={`哈希值：${hash}`}
                    extra={[
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(password)
                            .then(() => {
                              message.success("密码已复制");
                            })
                            .catch((err) => {
                              message.error("复制密码失败: ", err);
                            });
                        }}
                      >
                        立即复制
                      </Button>,
                    ]}
                  >
                    <div className="desc">
                      <Paragraph>
                        请直接复制以上结果进行连接，尝试密码是否正确，如有问题请联系客服进行售后。
                        {/* <a>Thaw immediately &gt;</a> */}
                      </Paragraph>
                      <Paragraph>
                        欢迎下次光临🟪
                        {/* <a>Apply Unlock &gt;</a> */}
                      </Paragraph>
                    </div>
                  </Result>
                );
              }

            case "finished":
              if (BusinessData?.confirmed) {
                return (
                  <Result
                    style={{ gridColumn: "1/span2" }}
                    status="info"
                    title={`此任务已关闭`}
                    subTitle={`升级任务自动解封`}
                  >
                    <div className="desc">
                      <Paragraph>
                        尊敬的客户，您的任务没有逆向成功，且任务已经被系统关闭，所有任务已不再运行。
                      </Paragraph>
                      <Paragraph>
                        请勿重复提交同一任务，如果需要继续运行，请于上方升级任务，或联系客服进行处理。
                      </Paragraph>
                    </div>
                  </Result>
                );
              } else {
                if (!noticed) {
                  put_finished_modal(true);
                  noticed = true;
                }
              }
          }

          if (!ZidianData) return;

          //0.处理数据 将Zidian与Task 以及Business的runned_task融合在一起 并且根据parent分组
          let zidian_list;
          zidian_list = Array.from(ZidianData).reduce(function merge_by_parent(
            acc,
            cur
          ) {
            //1.融合Task
            const task_status = TaskData.find(
              (e) => e.zidian_object_id === cur._id
            );
            let the_zidian_with_task = { ...cur, ...task_status };
            //2.融合runned_task
            const runned_task = BusinessData.runned_task.find(
              (e) => e.origin_id === cur._id
            );
            if (runned_task) {
              the_zidian_with_task = { ...cur, ...runned_task };
              the_zidian_with_task.status = "finished";
            }
            if (cur?.parent) {
              //对于存在父集合的情况
              if (acc[cur.parent]) {
                acc[cur.parent].push(the_zidian_with_task);
              } else {
                acc[cur.parent] = [];
                acc[cur.parent].push(the_zidian_with_task);
              }
            } else {
              //对于不存在父集合的情况
              if (!acc.default) {
                acc.default = [];
              }
              acc.default.push(the_zidian_with_task);
            }
            return acc;
          }, []);

          //1.渲染

          let order = []; //负责article的排序
          let ready_left = ["1.基础", "2.进阶", "3.金刚"]; //准备显示在网站左边的数据
          let ready_right = ["4.特殊"]; //准备显示在网站右边的数据
          let items_left = [];
          let items_right = [];

          ready_left.forEach((parent, index) => {
            let card_list = []; //用来存储article之下的card

            const lists = zidian_list[parent];

            if (!lists) return;

            lists.forEach((sing_zidian) => {
              //sing_zidian是融合过的数据
              const card = generate_Card(sing_zidian);
              //把有状态的 优先放在最上面
              if (sing_zidian?.status === "running") {
                card_list.unshift(card);
              } else {
                card_list.push(card);
              }
            });

            items_left.push({
              key: parent,
              label: parent,
              children: card_list,
            });

            //负责顺序
          });

          ready_right.forEach((parent, index) => {
            let card_list = []; //用来存储article之下的card

            const lists = zidian_list[parent];

            if (!lists) return;

            lists.forEach((sing_zidian) => {
              //sing_zidian是融合过的数据
              const card = generate_Card(sing_zidian);
              //把有状态的 优先放在最上面
              if (sing_zidian?.status) {
                card_list.unshift(card);
              } else {
                card_list.push(card);
              }
            });

            items_right.push({
              key: parent,
              label: parent,
              children: card_list,
            });

            //负责顺序

            // if (isNaN(Number.parseFloat(parent))) {
            //   //不是数字 添加在最后
            //   ready_return.push(article);
            // } else {
            //   //是数字 排序后插入
            //   order.push(Number.parseFloat(parent));
            //   order.sort((a, b) => a - b);
            //   const index = order.indexOf(Number.parseFloat(parent));
            //   ready_return = [
            //     ...ready_return.slice(0, index),
            //     article,
            //     ...ready_return.slice(index),
            //   ];
            // }
          });

          //处理定制化的包
          const all_dingzhi_tasks = Array.from(TaskData).filter(
            (task) => task?.Snapchat?.type === "Snapchat"
          ); //从Task数据库 获取所有snapchat

          const runned_dingzhi_tasks = BusinessData?.runned_task?.filter(
            (task) =>
              String(task.origin_name).toLowerCase().startsWith("snapchat")
          ); //从Business runned 获取所有snapchat

          let children = [];

          all_dingzhi_tasks.forEach((sing_snapchat) => {
            const card = generate_snapchat_Card(sing_snapchat, "task");
            children.push(card);
          });

          runned_dingzhi_tasks.forEach((sing_snapchat) => {
            const card = generate_snapchat_Card(sing_snapchat, "runned");
            children.push(card);
          });

          let dingzhi_card_list;
          items_right.unshift({
            key: "定制化",
            label: "定制化",
            children,
          });

          return (
            <>
              <Collapse
                defaultActiveKey={[
                  BusinessData?.charge_type <= 1
                    ? "1.基础"
                    : BusinessData?.charge_type === 2
                      ? "2.进阶"
                      : BusinessData?.charge_type === 3
                        ? "3.金刚"
                        : undefined,
                ]}
                items={items_left}
              ></Collapse>
              <Collapse
                defaultActiveKey={["定制化"]}
                items={items_right}
              ></Collapse>
            </>
          );

          //附加函数一 基于task信息 返回Card
          function generate_Card(info) {
            // console.log("info", info);

            switch (info?.status) {
              //Task库中 等待中的任务
              case "wating":
                const wating_items = [
                  {
                    key: 1,
                    label: "总个数：",
                    children: number_to_chinese(info?.total),
                  },
                  {
                    key: 2,
                    label: "字典大小：",
                    children: `${info?.Size ? `${info.Size}Gb` : "未指定大小"}`,
                  },
                ];
                return (
                  <Badge.Ribbon text="排队中" color="#22b8cf">
                    <Card
                      title={`${info?.zidian_name}`}
                      size="small"
                      headStyle={{ background: "#e599f7" }}
                    >
                      <Descriptions items={wating_items} layout="vertical" />
                    </Card>
                  </Badge.Ribbon>
                );

              //Task库中 运行中的任务
              case "running":
                const items = [
                  // {
                  //   key: 1,
                  //   label: "状态",
                  //   children: info.hashcatStatus.Status,
                  // },
                  {
                    key: 2,
                    label: "总个数：",
                    children: number_to_chinese(info.hashcatStatus.allAmount),
                    span: 1,
                  },
                  {
                    key: 3,
                    label: "任务开始时间",
                    children: new Date(info.hashcatStatus?.TimeStarted)
                      .toLocaleString("zh-CN")
                      .slice(5),
                    span: 1,
                  },
                  // {
                  //   key: 4,
                  //   label: "预计完成时间",
                  //   children: new Date(info.hashcatStatus.TimeEstimated)
                  //     .toLocaleString("zh-CN")
                  //     .slice(5),
                  // },
                  {
                    key: 5,

                    label: "当前速度",
                    children: info.hashcatStatus?.theSpeed.join(""),
                    span: 1,
                  },
                  {
                    key: 6,
                    label: "进度",
                    children: (
                      <Progress
                        style={{ width: "100%" }}
                        percent={parseFloat(info.hashcatStatus.jindu).toFixed(
                          1
                        )}
                        status="active"
                      />
                    ),
                    span: 3,
                  },
                  // {
                  //   key: 7,
                  //   label: "正在尝试",
                  //   children:
                  //     info.hashcatStatus.theCandidates?.length > 0
                  //       ? info.hashcatStatus.theCandidates.join("到")
                  //       : "未报告进度，任务正在进行中",
                  //   span: 1,
                  // },

                  {
                    key: 8,
                    label: "显卡状态",
                    children: (
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          justifyContent: "space-around",
                        }}
                      >
                        <p>
                          ❄️ 显卡温度: {info.hashcatStatus?.Mon?.[0]?.Temp}°
                        </p>
                        <p>
                          💨 风扇转速: {info.hashcatStatus?.Mon?.[0]?.Fan} %
                        </p>
                        <p>📊 利用率: {info.hashcatStatus?.Mon?.[0]?.Util} %</p>
                      </div>
                    ),
                    span: 3,
                  },
                ];
                return (
                  <Badge.Ribbon text="显卡检索中">
                    <Card
                      title={`${info?.zidian_name}`}
                      size="small"
                      headStyle={{ background: "#e599f7" }}
                    >
                      <Descriptions
                        bordered
                        items={items}
                        layout="vertical"
                        style={{ gap: "5px" }}
                        size="small"
                      />
                    </Card>
                  </Badge.Ribbon>
                );

              case "fail":
                return (
                  <Badge.Ribbon text="任务失败">
                    <Card
                      title={info?.zidian_name}
                      size="small"
                      headStyle={{ background: "#fa5252" }}
                    >
                      <p>字典大小：{info?.Size}GB</p>
                      <p>状态：出错</p>
                    </Card>
                  </Badge.Ribbon>
                );

              //Business中 runned的任务
              case "finished":
                const items_finished = [
                  {
                    key: 1,
                    label: "总个数：",
                    children: number_to_chinese(info.Amount),
                  },
                  {
                    key: 3,
                    label: "开始时间",
                    children: new Date(info.TimeStarted)
                      .toLocaleString("zh-CN")
                      .slice(5),
                  },
                  {
                    key: 4,
                    label: "消耗时间",
                    children: `${(
                      (new Date(info.actualEndTime) -
                        new Date(info.TimeStarted)) /
                      1000 /
                      60 /
                      60
                    ).toFixed(2)}小时`,
                  },
                  {
                    key: 4,
                    label: "曾经尝试",
                    children: info?.theCandidates?.join("\n"),
                  },
                ];
                return (
                  <Badge.Ribbon text="任务完成" color="#868e96">
                    <Card
                      title={info?.zidian_name}
                      size="small"
                      headStyle={{ background: "#ffffff" }}
                    >
                      <Descriptions items={items_finished} layout="vertical" />
                    </Card>
                  </Badge.Ribbon>
                );

              //Zidian存在 但是没出现在Task 也没有出现在Businedd的runned

              case undefined:
                return (
                  <Badge.Ribbon
                    text="不可跑"
                    color="#f59f00"
                    className={classes.badge}
                  >
                    <Card
                      size="small"
                      title={info?.zidian_name}
                      headStyle={{
                        background: "#adb5bd",
                      }}
                      className={classes.card}
                    >
                      {/* <h3>字典名:{info?.zidian_name}</h3> */}
                      <p>总个数：{number_to_chinese(info?.total)}</p>
                      <p>字典大小：{info?.Size}GB</p>
                    </Card>
                  </Badge.Ribbon>
                );
            }

            function number_to_chinese(a) {
              if (!a) return "未指定";
              let b = String(a);
              if (b.length > 7) {
                //后七位变0
                for (let i = 1; i <= 7; i++) {
                  const index = b.length - i;
                  if (i === 1) {
                    b = `${b.slice(0, index)}0`;
                  } else {
                    b = `${b.slice(0, index)}0${b.slice(index + 1)}`;
                  }
                }

                return `${nzhcn.encodeS(Number(b))}个`;
              } else {
                for (let i = 1; i <= b.length - 1; i++) {
                  const index = b.length - i;
                  if (i === 1) {
                    b = `${b.slice(0, index)}0`;
                  } else {
                    b = `${b.slice(0, index)}0${b.slice(index + 1)}`;
                  }
                }

                return `${nzhcn.encodeS(Number(b))}个`;
              }
            }
          }
          //附加函数二 根据定制化包信息生成

          function generate_snapchat_Card(info, type) {
            switch (type) {
              case "task":
                let _info = { ...info };
                _info.zidian_name = String(info.zidian_name).replace(
                  "snapchat",
                  "定制化任务"
                );
                return generate_Card(_info);

              case "runned":
                let _info_runned = { ...info };
                _info_runned.status = "finished";
                _info_runned.zidian_name = info.origin_name.replace(
                  "snapchat",
                  "定制化任务"
                );
                return generate_Card(_info_runned);
            }
          }
        })()}
      </section>

      <div data-description="专用用于弹出模态窗口">
        <Modal
          open={finished_modal}
          footer={[
            <Button
              onClick={function cancel_finished_modal() {
                put_finished_modal(false);
              }}
            >
              已知晓
            </Button>,
          ]}
        >
          <div className={classes.finished_modal}>
            <Typography.Title level={3}>
              🚀
              {BusinessData?.charge_type === 0
                ? "菜鸟包"
                : BusinessData?.charge_type === 1
                  ? "基础包"
                  : BusinessData?.charge_type === 2
                    ? "进阶包"
                    : BusinessData?.charge_type === 3
                      ? "金刚包"
                      : "此任务"}{" "}
              任务全部完成
            </Typography.Title>

            <Typography.Paragraph>
              {BusinessData?.wifi_name}执行完了全部的预定任务，没有发现此Hash😿
            </Typography.Paragraph>
            <Typography.Paragraph>请升级更高级别的任务</Typography.Paragraph>

            <p>
              {BusinessData?.charge_type === 0 ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography.Paragraph>
                    菜鸟包用户，请升级到基础包，正式开始任务。
                  </Typography.Paragraph>
                </div>
              ) : (
                ""
              )}
            </p>
          </div>
        </Modal>

        <Modal
          title="🥳请付款🥳"
          open={scuess_modal}
          footer={[
            <Button
              onClick={function cancel_finished_modal() {
                put_scuess_modal(false);
              }}
            >
              已知晓
            </Button>,
          ]}
        >
          <div className={classes.finished_modal}>
            <p>密码已跑出 付款后获得密码</p>

            <p>
              提示：请放心支付，如遇到任何问题，我们售后到底。{" "}
              <a
                href="https://www.ccwifi.cc/blogs/index.php/2024/04/06/%e5%94%ae%e5%90%8e%e5%8d%8f%e8%ae%ae/"
                target="_blank"
              >
                《曹操wifi售后协议》
              </a>
            </p>
          </div>
        </Modal>

        <Pay open={pay_modal_open} data={pay_modal_data} onCancel={cancel} />

        <Modal
          okText="正式开始任务🚀"
          open={
            BusinessData?.charge_type === 0 &&
            cainiao_modal &&
            BusinessData?.status !== "solved"
              ? true
              : false
          }
          onCancel={() => {
            put_cainiao_modal(false);
          }}
          onOk={function 正式开始任务() {
            if (BusinessData?.charge_type === 1) {
              message.warning("已经是基础包");
              return;
            }

            let pay_data = { subject: "prepaid_1" };

            pay_data.business_id = BusinessData._id;
            pay_data.wifi_name = BusinessData.wifi_name;
            pay_data.pay_type = "43";

            put_pay_modal_data(pay_data);
            put_pay_modal_open(true);
          }}
        >
          <Typography>
            <Typography.Title
              level={4}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                color: "green",
              }}
            >
              {BusinessData?.wifi_name} 处于：菜鸟包
              <Bird size={32} />
            </Typography.Title>
            <Typography>
              <Paragraph>您好！感谢您选择我们的服务。</Paragraph>
              <Paragraph>“菜鸟包”跑出后收费15元。无需预付电费！</Paragraph>
              <Paragraph>
                菜鸟包运行我们精心收集😻菜鸟小字典，容量小于1G。
              </Paragraph>
              <Paragraph>
                菜鸟包任务无法实时查看进度，只有基础包及更高级别包支持进度反馈。
              </Paragraph>
              <Paragraph>
                请立即升级{" "}
                <span>
                  {" "}
                  <Package size={32} style={{ color: "#1864ab" }} /> 基础包
                </span>{" "}
                正式开始任务,享受70G+字典。
              </Paragraph>
              <Paragraph>
                点击“
                <a
                  href="https://www.ccwifi.cc/blogs/index.php/2024/03/17/%e5%9f%ba%e7%a1%80%e3%80%81%e8%bf%9b%e9%98%b6%e3%80%81%e9%87%91%e5%88%9a%e5%8c%85%e9%83%bd%e6%98%af%e4%bb%80%e4%b9%88%ef%bc%9f/"
                  target="_blank"
                >
                  这里
                </a>
                ”了解我们的任务安排。
              </Paragraph>
            </Typography>
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Image width="50%" src={四个包} />
          </div>
        </Modal>
      </div>

      <Footer theme="light" />
    </App>
  );
}

export default Feedback;
