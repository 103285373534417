import { useEffect, useState, memo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import classes from "./XiugaiRenwu.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useGetAllQuery } from "../Api/ZidianApi";
import {
  useGetOneMutation,
  useDeleteOneBusinessMutation,
  useUpdataOneBusinessMutation,
} from "../Api/BusinessApi";

import {
  useGetAllTasksQuery,
  usePostOneMutation,
  useDeleteOneTaskMutation,
  useSpecialDeleteOneTaskMutation,
  usePostChaduiMutation,
} from "../Api/TaskApi";
import {
  Trash,
  Hand,
  ThumbsDown,
  Bird,
  Package,
  Hammer,
  Gavel,
  Question,
} from "@phosphor-icons/react";
// import { Modal, ConfigProvider } from "@douyinfe/semi-ui";
// import en_GB from "@douyinfe/semi-ui/lib/es/locale/source/en_GB";
import Snapchat from "../ModelWindows/Snapchat";
import { Button, Popover, Tooltip, message, Table } from "antd";
import SnapchatAi from "../ModelWindows/SnapchatAI";
import { Helmet } from "react-helmet";

let globalId;

/**
 * `XiugaiRenwu` 是一个页面组件，用于修改任务。
 *
 * ## State
 * - `theBusinessData`,`putTheBusinessData`: 用于存储和修改Business数据
 * - `visiable`：负责Snapchat（普通）模态窗口的显示与隐藏
 * - `visiableAi`：负责Snapchat（AI）模态窗口的显示与隐藏
 * - `id`：当前Business的ID
 * - `ableTasks`：所Zidian数据
 *
 * ## 函数
 * - `onChangeHandler`：负责所有输入内容的双向绑定
 * - `visiableToggle`：切换Snapchat（普通）模态窗口的显示与隐藏
 * - `visiableAiToggle`：切换Snapchat（AI）模态窗口的显示与隐藏
 */

function XiugaiRenwu() {
  //获取此次操作的ID
  const { id } = useParams();
  globalId = id;
  const nav = useNavigate();

  //-----------钩子函数----------

  //API:删除Business
  const [deleteBusiness] = useDeleteOneBusinessMutation();

  //API:修改Business
  const [updataBusiness] = useUpdataOneBusinessMutation();

  //API:（特殊删除）删除Business中运行过的Task

  const [specialDeleteTask] = useSpecialDeleteOneTaskMutation();

  //API:插队

  const [postChadui] = usePostChaduiMutation();

  //双向绑定Business数据
  const [updateData, setUpdateData] = useState({
    xianyu_name: "",
    city: "",
    wifi_name: "",
    wifi_owner_xing: "",
    wifi_owner_name: "",
    payed: false,
  });

  //1.able型数据

  const { data: allTasks } = useGetAllQuery(null, {
    pollingInterval: 3000,
  });

  const [ableTasks, putAbleTasks] = useState();

  //负责把查询到的所有字典放入state

  useEffect(() => {
    if (!allTasks) return;
    putAbleTasks(allTasks);
  }, [allTasks]);

  //2.Task数据

  const { data: taskData } = useGetAllTasksQuery(null, {
    pollingInterval: 3000,
  });

  const [postOneTask] = usePostOneMutation();

  const [deleteOneTask] = useDeleteOneTaskMutation();

  //3.Business数据

  const [getOneBusiness, theBusinessResult] = useGetOneMutation();
  const [theBusinessData, putTheBusinessData] = useState({
    xianyu_name: "",
    city: "",
    wifi_name: "",
    wifi_owner_xing: "",
    wifi_owner_name: "",
  });

  //负责执行查询Business的数据
  useEffect(() => {
    getOneBusiness(id);
  }, []);

  //负责把查询到的Business数据放入state
  useEffect(() => {
    if (!theBusinessResult.data) return;
    putTheBusinessData(theBusinessResult.data.data);
    console.log("放置数据");
  }, [theBusinessResult]);

  //负责snapchat（普通） 模态窗口
  const [visiable, putVisiable] = useState(false);

  const [visiableAi, putVisiableAi] = useState(false);

  function visiableToggle() {
    putVisiable((state) => !state);
  }

  function visiableAiToggle() {
    putVisiableAi((state) => !state);
  }

  //-----------自定义函数----------

  //负责所有输入内容的双向绑定
  function onChangeHandler(e) {
    const key = e.target.id;
    const value = e.target.value;

    console.log(key, value);

    putTheBusinessData((state) => {
      if (key === "mac") {
        return { ...state, MAC_AP: { ...state.MAC, MAC: value } };
      } else {
        return { ...state, [key]: value };
      }
    });
  }

  //用于嵌套的一些数据
  let wait_classified;
  let able_classified;
  let finished_classified;

  //负责渲染所有的任务
  function renderTasks(type, color) {
    if (!ableTasks || theBusinessData.wifi_name === "" || !taskData) return;

    //进行中的数据
    const theWait = taskData.data.filter((sing) => {
      return (
        sing.business_object_id === id &&
        sing.status !== "finished" &&
        sing.status !== "unneed"
      );
    });
    //已跑完的数据
    const theFinished = theBusinessData.runned_task;
    //不可跑的数据
    const theUnable = ableTasks.data.reduce((acc, cur) => {
      if (cur.depend && !theBusinessData[cur.depend]) {
        acc.push(cur);
        return acc;
      } else {
        return acc;
      }
    }, []);
    //可跑的数据=所有字典-进行中-已结束-不可跑
    const theAble = ableTasks.data.filter((sing) => {
      return (
        !theFinished.some((e) => e.origin_id === sing._id) &&
        !theUnable.some((e) => e._id === sing._id) &&
        !theWait.some((e) => e.zidian_object_id === sing._id)
      );
    });

    //父级元素列表 包含父级元素名称以及Id
    const parent_list = { default: [] };
    ableTasks.data.forEach((a) => {
      if (a?.parent) {
        const parent = a.parent;
        if (parent_list[parent]) {
          const tem = [...parent_list[parent]];
          tem.push(a._id);
          parent_list[parent] = tem;
        } else {
          parent_list[parent] = [];
          const tem = [...parent_list[parent]];
          tem.push(a._id);
          parent_list[parent] = tem;
        }
      } else {
        const tem = [...parent_list["default"]];
        tem.push(a._id);
        parent_list["default"] = tem;
      }
    });

    //console.log("parent_list", parent_list);

    if (type === "able") {
      able_classified = guolv(theAble);
      let tem = [];
      let index = 0;

      for (let [key, value] of Object.entries(able_classified)) {
        tem.push(nestBlock(key, value, index, "able_", { isAble: true }));
        index += 1;
      }

      return tem;
    }

    if (type === "finished") {
      finished_classified = guolv(theFinished, "finished");
      let tem = [];
      let index = 0;

      for (let [key, value] of Object.entries(finished_classified)) {
        tem.push(
          nestBlock(key, value, index, "finished_", {
            isFinished: true,
          })
        );
        index += 1;
      }

      return tem;

      // const tem = theFinished.map((sing, index) => {
      //   return returnDraggable(
      //     sing._id,
      //     index,
      //     sing.origin_name,
      //     JSON.stringify({
      //       isFinished: true,
      //       data: sing,
      //     })
      //   );
      // });

      // return tem;
    }

    if (type === "unable") {
      const tem = theUnable.map((sing, index) => {
        return returnDraggable(sing._id, index, sing.zidian_name);
      });

      return tem;
    }

    if (type === "wait") {
      //console.log(theWait);
      wait_classified = guolv(theWait, "wait");

      let tem = [];
      let index = 0;

      for (let [key, value] of Object.entries(wait_classified)) {
        tem.push(nestBlock(key, value, index, "wait_", { isRunning: true }));
        index += 1;
      }

      return tem;

      // const tem = theWait.map((sing, index) => {
      //   return returnDraggable(
      //     sing._id,
      //     index,
      //     sing.zidian_name,
      //     `${
      //       sing.status === "running"
      //         ? JSON.stringify({ special: true, color: "purple" })
      //         : undefined
      //     }`
      //   );
      // });

      // return tem;
    }

    //附加函数1：负责返回Draggable
    function returnDraggable(id, index, content, speciala, all) {
      //处理一些基本函数
      let special;
      if (speciala !== "undefined" && speciala) {
        special = speciala;
      }

      return baseBlock();

      function baseBlock() {
        return (
          <Draggable
            draggableId={id}
            key={id}
            index={index}
            isDragDisabled={special?.special}
          >
            {(provided) => (
              <div
                className={`${classes.tuodong_block} ${
                  special?.isFinished ? classes.finished_block : undefined
                } ${(() => {
                  //判断时间的函数 如果跑的时间不对显示深红色
                  const panduanShijian = () => {
                    if (!special?.isFinished || !special?.data?.Speed)
                      return false;
                    let theSpeed;
                    if (special?.data?.Speed?.length === 1) {
                      theSpeed = special.data.Speed[0];
                    } else {
                      theSpeed =
                        special.data.Speed[special.data.Speed.length - 1];
                    }

                    if (theSpeed.includes("k")) {
                      theSpeed = Number.parseFloat(theSpeed, 10) * 1000;
                    } else {
                      theSpeed = Number.parseFloat(theSpeed);
                    }
                    const spendTime = Number(
                      (new Date(special.data.actualEndTime) -
                        new Date(special.data.TimeStarted)) /
                        1000 /
                        60 /
                        60
                    ).toFixed(2);

                    const yujiSpendTime =
                      special.data.Amount / theSpeed / 60 / 60;

                    if (yujiSpendTime - spendTime >= 0.16) {
                      return classes.bigProblem_finished;
                    } else {
                      return "";
                    }
                  };

                  //这里负责的是 检测哪个finished数据不存在 显示粉红色
                  //如果跑的时间不对 显示深红色
                  if (
                    special?.isFinished &&
                    (!special?.data.Amount ||
                      !special?.data.Speed.join("") ||
                      !special?.data.theCandidates.join("") ||
                      !special?.data.TimeStarted ||
                      !special?.data.actualEndTime)
                  ) {
                    return classes.problem_finished;
                  } else if (special?.isFinished && panduanShijian()) {
                    return classes.bigProblem_finished;
                  } else {
                    return "";
                  }
                })()}`}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <p
                  style={{
                    textAlign: "center",
                    position: "relative",
                    color: special?.color
                      ? special.color
                      : special?.data?.isUnneed === true
                        ? "grey"
                        : speciala?.data?.status === "running"
                          ? "purple"
                          : speciala?.data?.status === "fail"
                            ? "red"
                            : "black",
                  }}
                >
                  {content}

                  {special?.data?.isUnneed === true ? " （跳过）" : ""}

                  {special?.isAble === true &&
                  special.data.auto_enable === false ? (
                    <ThumbsDown
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "-5px",
                      }}
                      size={18}
                    />
                  ) : undefined}
                </p>
                {(() => {
                  //这是是真针对Special的情况

                  //针对finished
                  if (special?.isFinished) {
                    const spendTime = Number(
                      (new Date(special.data.actualEndTime) -
                        new Date(special.data.TimeStarted)) /
                        1000 /
                        60 /
                        60
                    ).toFixed(2);

                    // 以下是finished专属
                    return (
                      <div
                        style={{
                          display:
                            special?.data?.isUnneed === true ? "none" : "grid",
                        }}
                        className={classes.finished_miaoshu}
                      >
                        <Trash
                          className={classes.trashIcon}
                          onClick={() => {
                            const tem = confirm(`确定删除 &${content}& 吗`);
                            if (!tem) return;
                            console.log(special.data);

                            specialDeleteTask({
                              business_id: globalId,
                              task_id: special.data._id,
                            });
                            //console.log(id);
                            //location.reload();
                            getOneBusiness(globalId);
                          }}
                          size={24}
                        />
                        <p>
                          花费时间：
                          {spendTime}小时
                        </p>
                        <p>速度：{special?.data?.Speed?.join(" ")}</p>
                        <p>总数量：{special?.data.Amount}</p>
                        <p>
                          曾经尝试：
                          {special?.data.theCandidates?.join("").split("->")[0]}
                        </p>
                        <p>
                          功勋机器：
                          {special?.data.machine}
                        </p>
                      </div>
                    );
                  }
                })()}
              </div>
            )}
          </Draggable>
        );
      }
    }

    //附加函数2： 返回一个嵌套
    function nestBlock(nestName, value, index, qianzhui, special) {
      if (value.length === 0) return <></>;

      return (
        <Draggable
          draggableId={`${qianzhui} ${nestName}`}
          key={`${qianzhui} ${nestName}`}
          index={index}
        >
          {(nest1) => (
            <div
              className={classes.nest1}
              ref={nest1.innerRef}
              {...nest1.draggableProps}
            >
              <h3 {...nest1.dragHandleProps}>{nestName}</h3>
              <Droppable
                droppableId={qianzhui + nestName}
                key={qianzhui + nestName}
              >
                {(nest2) => (
                  <div
                    className={classes.nest2}
                    ref={nest2.innerRef}
                    {...nest2.droppableProps}
                  >
                    {value.map((sing, index) => {
                      //这是进一步定义关于finished
                      let theFinishedRunnedData = {};
                      if (special?.isFinished === true) {
                        theFinishedRunnedData = {
                          isFinished: true,
                          data: sing,
                        };
                      }

                      //定义running的special数据
                      let theRunningSpecialData = {};
                      if (special?.isRunning === true) {
                        theRunningSpecialData = {
                          isRunning: true,
                          data: sing,
                        };
                      }

                      //定义able的special数据()
                      let theAbleSpecialData = {};
                      if (special?.isAble === true) {
                        theAbleSpecialData = {
                          isAble: true,
                          data: sing,
                        };
                      }

                      return returnDraggable(
                        sing._id,
                        index,
                        sing.zidian_name ? sing.zidian_name : sing.origin_name,
                        theFinishedRunnedData.isFinished
                          ? theFinishedRunnedData
                          : theRunningSpecialData.isRunning
                            ? theRunningSpecialData
                            : theAbleSpecialData.isAble
                              ? theAbleSpecialData
                              : undefined
                      );
                    })}
                    {nest2.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </Draggable>
      );
    }
    //附加函数3：将数据根据不同的parent分类
    function guolv(who, type) {
      return who.reduce(
        (acc, cur, index) => {
          //统一化id

          let id = cur._id;
          if (type === "wait") {
            id = cur.zidian_object_id;
          }
          if (type === "finished") {
            id = cur.origin_id;
          }
          let key;

          for (let [_key, value] of Object.entries(parent_list)) {
            if (value.includes(id)) {
              key = _key;
              break;
            } else key = undefined;
          }

          if (key) {
            if (acc[key]) {
              acc[key].push(cur);
            } else {
              acc[key] = [];
              acc[key].push(cur);
            }
          } else {
            acc["default"].push(cur);
          }

          return acc;
        },
        { default: [] }
      );
    }
  }

  //拖拽事件
  function dragEndHandler(run) {
    const source = run.source;
    const destination = run.destination;
    if (!destination || !source) return;

    //判断的是拖动嵌套 从可跑到待跑  意即添加

    if (
      run.draggableId.startsWith("able") &&
      run.destination.droppableId.startsWith("wait")
    ) {
      console.log(run);

      const key = run.draggableId.split("_")[1].trim(); //字典集合的名字 基础还是进阶
      const tem = able_classified[key]; //获取这个集合中所有的数据
      tem.forEach((sing) => {
        console.log(sing);
        if (sing.auto_enable === true) {
          //批量添加时 要筛选"允许批量添加"
          addTask(sing);
        }
      });
      return;
    }

    //判断的是拖动嵌套 从待跑到可跑 意即删除

    if (
      run.draggableId.startsWith("wait") &&
      run.destination.droppableId.startsWith("able")
    ) {
      const key = run.draggableId.split("_")[1].trim();
      const tem = wait_classified[key];
      tem.forEach((sing) => {
        removeTask(sing, "fromwait");
      });
      return;
    }

    //现在判断的拖动单个

    if (run.draggableId)
      if (
        source.droppableId === "finished" &&
        destination.droppableId === "wait"
      )
        //再判断拖动单个任务的情况

        alert("已经运行过的任务不可以再运行");

    if (source.droppableId === "unable")
      alert(
        `无依赖 无法跑这个包 缺少依赖： ${
          ableTasks.data.find((e) => e._id === run.draggableId).depend
        }`
      );

    if (
      source.droppableId.startsWith("able") &&
      destination.droppableId === "wait"
    ) {
      addTask();
      //alert("已添加");
    }

    if (
      source.droppableId.startsWith("wait") &&
      destination.droppableId === "able"
    ) {
      removeTask();
      //alert("已删除");
    }

    // 附加函数 增加Task
    function addTask(nestData) {
      let id;
      if (!nestData) {
        id = run.draggableId;
      } else {
        id = nestData._id;
      }
      const readyToAdd = ableTasks.data.find((e) => e._id === id);
      console.log(readyToAdd);
      postOneTask({
        wifi_name: theBusinessData.wifi_name,
        business_object_id: theBusinessData._id,
        zidian_name: readyToAdd.zidian_name,
        zidian_object_id: readyToAdd._id,
        xianyu_name: theBusinessData.xianyu_name,
        status: "wating",
        start_time: new Date(),
      });
    }

    function removeTask(nestData, type) {
      let id;
      if (!nestData) {
        id = run.draggableId;
      } else {
        if (type === "fromwait") {
          id = nestData._id;
        }
      }
      deleteOneTask(id);
    }
  }

  return (
    <section className={classes.container}>
      <Helmet>
        <title>修改{`${theBusinessData?.wifi_name}`}</title>
      </Helmet>
      <article>
        <h2>Business信息</h2>

        <div className={classes.form}>
          <div className={classes.block}>
            <label>QQ号</label>
            <input
              type="text"
              id="qq"
              onChange={onChangeHandler}
              value={theBusinessData?.qq}
            ></input>
          </div>
          <div className={classes.block}>
            <label>邮箱</label>
            <input
              type="text"
              id="mail"
              onChange={onChangeHandler}
              value={theBusinessData?.mail}
            ></input>
          </div>
          <div className={classes.block}>
            <label>抓包城市</label>
            <input
              type="text"
              id="city"
              onChange={onChangeHandler}
              value={theBusinessData?.city}
            ></input>
          </div>
          <div className={classes.block}>
            <label>Wifi名称</label>
            <input
              type="text"
              id="wifi_name"
              onChange={onChangeHandler}
              value={theBusinessData.wifi_name}
            ></input>
          </div>
          <div className={classes.block}>
            <label>Wifi户主姓氏</label>
            <input
              type="text"
              id="wifi_owner_xing"
              onChange={onChangeHandler}
              value={theBusinessData.wifi_owner_xing}
            ></input>
          </div>
          <div className={classes.block}>
            <label>Wifi户主姓名</label>
            <input
              type="text"
              id="wifi_owner_name"
              onChange={onChangeHandler}
              value={theBusinessData.wifi_owner_name}
            ></input>
          </div>

          <div className={classes.block}>
            <label>状态</label>
            <select
              id="status"
              onChange={onChangeHandler}
              value={theBusinessData.status}
            >
              <option value="waitCheck">等待管理员添加任务</option>
              <option value="running">正在运行</option>
              <option value="finished">跑完</option>
              <option value="solved">跑出密码</option>
            </select>
          </div>

          <div className={classes.block}>
            <label>是否通知过</label>
            <select
              id="confirmed"
              onChange={onChangeHandler}
              value={theBusinessData.confirmed}
            >
              <option value={true}>已通知</option>
              <option value={false}>未通知</option>
            </select>
          </div>

          <div className={classes.block}>
            <label>是否付款</label>
            <select
              id="payed"
              onChange={onChangeHandler}
              value={theBusinessData.payed}
            >
              <option value={true}>已付款</option>
              <option value={false}>未付款</option>
            </select>
          </div>

          <div className={classes.block}>
            <label>包类型</label>
            <select
              id="charge_type"
              onChange={onChangeHandler}
              value={theBusinessData.charge_type}
            >
              <option value={0}>菜鸟包</option>
              <option value={1}>基础包</option>
              <option value={2}>进阶包</option>
              <option value={3}>金刚包</option>
            </select>
          </div>

          {/* <div className={classes.block}>
            <label>Vip</label>
            <select
              id="vip"
              onChange={onChangeHandler}
              value={theBusinessData.vip}
            >
              <option value={true}>是</option>
              <option value={false}>否</option>
            </select>
          </div> */}

          <div className={classes.block}>
            <label>备注</label>
            <input
              type="text"
              id="note"
              onChange={onChangeHandler}
              value={theBusinessData.note}
            ></input>
          </div>

          <div className={classes.block}>
            <label>密码</label>

            <input
              disabled
              type="text"
              id="password"
              value={`${
                theBusinessData?.password?.text.slice(
                  String(theBusinessData?.password?.text).lastIndexOf(":") + 1
                ) ?? "未跑出"
              }（${
                ableTasks?.data?.find(
                  (sing) => sing._id === theBusinessData?.password?.origin_id
                )?.zidian_name ?? "无"
              }）`}
            ></input>
          </div>

          <div className={classes.block}>
            <label>IP</label>
            <input
              type="text"
              id="ip"
              value={theBusinessData?.ip}
              onChange={onChangeHandler}
            ></input>
          </div>

          <div className={classes.block}>
            <label>MAC</label>
            <input
              type="text"
              id="mac"
              value={theBusinessData?.MAC_AP?.MAC}
              onChange={onChangeHandler}
            ></input>
          </div>

          <div className={classes.block} style={{}}>
            <label>HASH文件名</label>
            <input
              type="text"
              id="allHashName"
              value={theBusinessData?.allHashName}
              disabled
              style={{ width: "100%" }}
            ></input>
          </div>

          <div className={classes.block} style={{}}>
            <label>HASH值</label>
            <input
              type="text"
              id="allHashName"
              value={theBusinessData?.HASHLINE}
              disabled
              style={{ width: "100%" }}
            ></input>
          </div>

          <div className={classes.block} style={{}}>
            <label>商家备注</label>
            <input
              onChange={onChangeHandler}
              type="text"
              id="commercialNote"
              value={theBusinessData?.commercialNote}
              style={{ width: "100%" }}
            ></input>
          </div>

          <div className={classes.btns}>
            <Button
              className={classes.btn}
              onClick={() => {
                updataBusiness(theBusinessData);
                message.success("修改成功");
              }}
            >
              修改
            </Button>

            <Button
              className={classes.btn}
              onClick={() => {
                const tem = confirm("确认删除吗");
                if (!tem) return;
                deleteBusiness(id);
                nav("/control");
              }}
            >
              删除
            </Button>

            <Button
              onClick={() => {
                const tem = confirm("确认插队吗");
                if (!tem) return;

                postChadui({ businessId: theBusinessData._id })
                  .then(() => {
                    message.success("插队成功");
                  })
                  .catch((error) => {
                    message.error("插队失败");
                  });
              }}
            >
              插队
            </Button>

            <Button
              onClick={() => {
                window.open(
                  `https://app.salesmartly.com/chat?user_id=6821d363d9dbf4cd9917292fb2f22e02&user_name=${theBusinessData.mail}&session_status=active`
                );
              }}
            >
              在线聊天
            </Button>
          </div>
        </div>

        <div id={classes.附加信息}>
          <h2>更多附加信息</h2>
          <p>
            上次访问：
            {new Date(theBusinessData?.lastViewDate).toLocaleString("zh-CN")}
          </p>
          <p>历史金额：{theBusinessData?.totalMoney}</p>
          <Tooltip title={theBusinessData?.HASH_ATTEND}>
            <p>哈希扩展信息{}</p>
          </Tooltip>

          <p>文件格式：{theBusinessData?.fileExtension}</p>

          <Table
            className={classes.附加信息表格}
            dataSource={theBusinessData?.mail_businesses}
            style={{ width: "100%", overflowX: "auto" }}
            columns={[
              {
                title: "wifi名",

                render: (sing) => {
                  return (
                    <Tooltip title={sing.wifi_name}>
                      <p className={classes.charge_type}>
                        {(() => {
                          switch (sing?.charge_type) {
                            case 0:
                              return <Bird size={24} />;

                            case 1:
                              return <Package size={24} />;

                            case 2:
                              return <Hammer size={24} />;

                            case 3:
                              return <Gavel size={24} />;
                            default:
                              return <Question size={24} />;
                          }
                        })()}
                        {sing.wifi_name.length > 10
                          ? `${sing.wifi_name.slice(0, 10)}...`
                          : sing.wifi_name}
                      </p>
                    </Tooltip>
                  );
                },
              },
              {
                title: "时间",
                render: (record) =>
                  new Date(record.create_at).toLocaleDateString("zh-CN"),
              },

              {
                title: "状态",
                dataIndex: "status", // 假设数据源中有'status'字段表示状态
                key: "status",

                render: (status, record) => {
                  const statusMessages = {
                    waitCheck: "⏱️等待",
                    running: "👌运行",
                    finished: {
                      false: "🥲跑完",
                      true: "🥲关闭",
                    },
                    solved: {
                      false: "💲未收款",
                      true: "💲已赚钱",
                    },
                  };

                  // 默认状态
                  const defaultMessage = "🤔未知状态";

                  // 根据record.status和record.confirmed获取状态消息
                  const statusMessage =
                    record.status === "finished"
                      ? statusMessages[record.status][record.confirmed] ||
                        defaultMessage
                      : record.status === "solved"
                        ? statusMessages[record.status][record.payed] ||
                          defaultMessage
                        : statusMessages[record.status] || defaultMessage;

                  return statusMessage;
                },
              },

              {
                title: "备注",
                render: (record) => {
                  // 检查备注是否存在
                  const hasNotes = record?.note || record?.commercialNote;

                  // 构造备注内容
                  let noteContent = "";
                  if (record?.note) {
                    noteContent += `客户备注：${record.note}; `;
                  }
                  if (record?.commercialNote) {
                    noteContent += `商家备注：${record.commercialNote}; `;
                  }

                  // 根据备注是否存在来渲染 Tooltip
                  const TooltipComponent = hasNotes ? (
                    <Tooltip title={noteContent}>备注</Tooltip>
                  ) : (
                    ""
                  );

                  return TooltipComponent;
                },
              },

              {
                title: "已付金额",
                render: (record) => <p>{record.totalMoney}</p>,
              },
            ]}
          />
        </div>
      </article>

      <article className={classes.rightContainer}>
        <h2 style={{ gridColumn: "1/3" }}>任务管理</h2>

        <DragDropContext onDragEnd={dragEndHandler}>
          <Droppable key="wait" droppableId="wait">
            {(provided) => (
              <div
                className={classes.running}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1.2rem",
                  }}
                >
                  <h3 style={{ margin: 0 }} className={classes.taskTitle}>
                    待跑
                  </h3>

                  <Snapchat
                    toggle={visiableToggle}
                    visiable={visiable}
                    business_id={id}
                    wifi_name={theBusinessData.wifi_name}
                    xianyu_name={theBusinessData.xianyu_name}
                  />

                  <SnapchatAi
                    visiableAi={visiableAi}
                    toggle={visiableAiToggle}
                    business_id={id}
                    wifi_name={theBusinessData.wifi_name}
                  />

                  <Button
                    size="small"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      putVisiableAi(true);
                    }}
                  >
                    🤖AI
                  </Button>

                  <Button
                    size="small"
                    style={{ marginLeft: "10px" }}
                    onClick={visiableToggle}
                  >
                    增加Snapchat
                  </Button>
                </div>

                {renderTasks("wait", "#495057")}

                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Droppable key="able" droppableId="able">
            {(provided) => {
              return (
                <div
                  className={classes.able}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                  <h3 className={classes.taskTitle}>可跑</h3>

                  {renderTasks("able", "#495057")}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>

          <Droppable key="finished" droppableId="finished">
            {(provided) => (
              <div
                className={classes.finished}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <h3 className={classes.taskTitle} style={{ gridColumn: "1/3" }}>
                  已跑完
                </h3>

                {renderTasks("finished", "#f08c00")}

                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Droppable key="unable" droppableId="unable">
            {(provided) => (
              <div
                className={classes.unable}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <h3 className={classes.taskTitle}>不可跑</h3>
                {renderTasks("unable", "#495057")}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </article>
    </section>
  );
}

export default XiugaiRenwu;
