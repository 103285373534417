import { Question } from "@phosphor-icons/react/dist/ssr";
import classes from "./Client.mobile.module.css";
import { Tabs, Badge } from "antd-mobile";
import { useCrisp, useSaleSmartly } from "../utils";
import { Helmet } from "react-helmet";
import { Button, Card, Form, Input, Modal, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { useGetByMailQuery } from "../../Api/BusinessApi";
import { Bird, Gavel, Hammer, Package } from "@phosphor-icons/react";

/**
 * # 客户端移动端
 *
 * ## state
 *
 * - current: 当前tab
 *
 * ## 函数
 * - tabChange: 切换tabs
 * - renderCards: 渲染卡片
 */
function 客户端移动端() {
  const [Mailvisible, setMailVisible] = useState(false); //邮件模态窗口
  const [Uploadvisible, setUploadVisible] = useState(false); //上传模态窗口
  const [email, setEmail] = useState(undefined);
  const { data: businessData, isError } = useGetByMailQuery(email, {
    pollingInterval: 3000,
  });
  const [current, setCurrent] = useState("全部");

  //   useEffect(() => {
  //     if (isError) {
  //       alert("请求出错");
  //       //   console.log(businessData.message);
  //     }
  //   }, [isError]);

  // useCrisp();
  useSaleSmartly(email);

  // 检查localStorage中是否存在邮箱
  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (!storedEmail) {
      setMailVisible(true);
    } else {
      setEmail(storedEmail);
    }
  }, []);

  function tabChange(key) {
    // console.log("change", key);
    setCurrent(key);
  }

  const onFinish = (values) => {
    // console.log("表单的数据:", values);
    const { mail } = values;
    setEmail(mail);
    localStorage.setItem("userEmail", mail);
    setMailVisible(false);
  };

  function renderCards() {
    if (!businessData?.data) return <div>加载中...</div>;
    let data;

    switch (current) {
      case "全部":
        data = businessData.data;
        break;
      case "已结束":
        data = businessData.data.filter((item) => item.status === "finished");
        break;
      case "进行中":
        data = businessData.data.filter((item) => item.status === "running");
        break;
      case "成功":
        data = businessData.data.filter((item) => item.status === "solved");
        break;
      default:
        data = businessData.data;
        break;
    }

    return data.map((item, index) => {
      let 包类型;
      let status;
      let mac = item.MAC_AP.MAC;

      switch (item.charge_type) {
        case 0:
          包类型 = (
            <span className={classes.包类型}>
              菜鸟包 <Bird size={24} />{" "}
            </span>
          );
          break;
        case 1:
          包类型 = (
            <span className={classes.包类型}>
              基础包 <Package size={24} />
            </span>
          );
          break;
        case 2:
          包类型 = (
            <span className={classes.包类型}>
              进阶包 <Hammer size={24} />
            </span>
          );
          break;
        case 3:
          包类型 = (
            <span className={classes.包类型}>
              金刚包 <Gavel size={24} />
            </span>
          );
          break;
        default:
          包类型 = <span className={classes.包类型}>其他包</span>;
          break;
      }

      switch (item.status) {
        case "waitCheck":
          status = "等待中⏳";
          break;
        case "running":
          status = "运行中🏃";
          break;
        case "finished":
          status = "已结束❄️";
          break;

        case "solved":
          if (item.payed === true) {
            status = "已支付🎉";
          } else {
            status = "成功 未支付❗";
          }

          break;
        default:
          status = "其他状态";
          break;
      }

      return (
        <Card
          actions={[<a href={`/feedback?mac_ap=${mac}`}>查看</a>]}
          size="small"
          key={index}
        >
          <div className={classes.card}>
            <p>
              <span>wifi名:</span> <span>{item.wifi_name}</span>
            </p>
            <p>
              <span>包类型:</span>
              <span>{包类型}</span>
            </p>
            <p>
              <span>状态:</span>
              <span>{status}</span>
            </p>
          </div>
        </Card>
      );
    });
  }

  return (
    <div id={classes.page}>
      <Helmet>
        <title>曹操wifi客户端</title>
      </Helmet>
      <header id={classes.header_part}>
        {/* <span></span> */}
        <Popconfirm
          title="确认退出吗"
          onConfirm={() => {
            localStorage.removeItem("userEmail");
            setEmail("");
            location.reload();
          }}
        >
          <h2>
            <span>{email}</span> 的订单
          </h2>
        </Popconfirm>

        {/* <span>
          <Question size={24} />
          常见问题
        </span> */}
      </header>

      <Tabs onChange={tabChange}>
        <Tabs.Tab title="全部" key="全部" />

        <Tabs.Tab title="已结束" key="已结束" />
        <Tabs.Tab title="进行中" key="进行中" />

        <Tabs.Tab
          title={
            <Badge
              content={
                businessData?.data.filter((item) => item.status === "solved")
                  .length
              }
              style={{ "--right": "-10px", "--top": "8px" }}
            >
              成功🎉
            </Badge>
          }
          key="成功"
        />
      </Tabs>

      <div id={classes.内容}>{renderCards()}</div>

      <Modal
        title="请输入你的邮箱"
        open={Mailvisible}
        // onCancel={() => setMailVisible(false)}
        footer={null}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            name="mail"
            label="邮箱"
            rules={[{ required: true, message: "请输入邮箱!" }]}
          >
            <Input
              placeholder="请输入邮箱"
              rules={[{ required: true, message: "请输入邮箱!" }]}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>

        {/* <Button type="primary" onClick={handleConfirm}>
          确认
        </Button> */}
      </Modal>
    </div>
  );
}

export default 客户端移动端;
