import { useCallback, useEffect, useRef, useState } from "react";
import classes from "./Upload.module.css";
import Captcha from "react18-verify-code";
import { useLocation } from "react-router-dom";
import axios from "axios";
import configure from "../Stores/configure";
import {
  usePostBusinessMutation,
  usePostWhatHaveMutation,
} from "../Api/BusinessApi";
import {
  WhatsappLogo,
  WifiHigh,
  FileArrowUp,
  SmileyWink,
  Cursor,
} from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Modal,
  message,
  notification,
  Form,
  Input,
  Upload,
  Select,
  AutoComplete,
  Spin,
  Popover,
  List,
} from "antd";
const { Dragger } = Upload;
import { Crisp } from "crisp-sdk-web";
import { ArrowDown } from "@phosphor-icons/react";
import { useAllcityQuery } from "../Api/MagicApi";
import { Helmet } from "react-helmet";
import { useCrisp, useSaleSmartly } from "./utils";
import { Question } from "@phosphor-icons/react/dist/ssr";
import { Checkbox } from "antd-mobile/es/components/checkbox/checkbox";

// 阻止浏览器默认拖动行为
["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
  document.body.addEventListener(
    eventName,
    (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
    false
  );
});

/**
 * `Upload_Business` 是一个用于上传业务的组件。
 *
 * ## 示例
 *
 * ```jsx
 * <Upload_Business open={true} width="100%" />
 * ```
 *
 * ## 状态
 *
 * - **params**: 从当前 URL 中获取的参数
 * - **bigFile**: 是否为大文件
 * - **cityes_result**: 使用 useAllcityQuery 钩子获取的城市结果
 * - **cityes**: 存储城市的状态
 * - **fileRef**: 上传文件的 ref
 * - **serverAddress**: 从 Redux store 中获取的服务器地址
 * - **postOne**: 使用 usePostBusinessMutation 钩子获取的函数
 * - **postResult**: 使用 usePostBusinessMutation 钩子获取的结果
 * - **postWhatHave**: 使用 usePostWhatHaveMutation 钩子获取的函数
 * - **whatHaveResult**: 使用 usePostWhatHaveMutation 钩子获取的结果
 * - **hashInfo**: 存储 hash 信息的状态，是一个数组。数组中的每个元素是一个对象。
 * - **nav**: 使用 useNavigate 钩子获取的函数
 * - **code**: 存储验证码的状态
 * - **captchaRef**: 验证码的 ref
 * - **captchaInputRef**: 验证码输入框的 ref
 * - **updateData**: 存储更新数据的状态
 *
 * ## 函数
 *
 * - **checkCaptcha**: 检查验证码是否正确
 * - **random**: 生成随机验证码
 * - **onChangeHandler**: 处理输入内容的双向绑定
 * - **submit**: 提交表单
 * - **getHashInfo**: 获取 hash 信息
 * - **onClickHash**: 点击 hash 时的处理函数
 */

function Upload_Business({ open, width, handleCancel, email }) {
  //获取当前链接和search
  const params = new URLSearchParams(window.location.search);
  const bigFile = Boolean(params.get("big_file"));

  const { data: cityes_result } = useAllcityQuery();

  const [cityes, put_cityes] = useState();

  //是否同意《使用条款》
  const [isChecked, setIsChecked] = useState(false);

  useEffect(
    function 城市结果() {
      if (!cityes_result) return;
      const _tem = new Set(cityes_result.data);
      put_cityes(Array.from(_tem));
    },
    [cityes_result]
  );

  //上传文件的ref

  const fileRef = useRef();

  // useCrisp("left");
  useSaleSmartly();

  //钩子函数

  //勾出API服务器

  const { serverAddress } = useSelector((state) => state.configure);

  //API1.Post Business

  const [postOne, postResult] = usePostBusinessMutation();

  //API2. 解析cap

  const [postWhatHave, whatHaveResult] = usePostWhatHaveMutation();

  const [hashInfo, put_hashInfo] = useState();

  const nav = useNavigate();

  //负责在收到cap文件解析时 进行的第一手操作
  useEffect(
    function 收到cap解析结果() {
      //如果API出错 这是是最先发现的
      if (whatHaveResult.status === "rejected") {
        let description = `提交失败，请求出错，请联系管理员`;

        console.log(whatHaveResult);
        if (whatHaveResult?.error?.data) {
          description = whatHaveResult.error.data;
        } else if (whatHaveResult?.error?.error) {
          description = whatHaveResult.error.error;
        }

        notification.error({
          description: description,
        });
        return;
      }

      if (whatHaveResult?.data?.status === "fail") {
        notification.error({
          message: `解析文件出错：请联系网站管理员报告错误:${whatHaveResult?.data?.reason}`,
          duration: 0,
        });
        return;
      }

      //请求成功 但是没有值
      if (
        whatHaveResult.isSuccess === true &&
        !whatHaveResult?.data?.data?.allHashName
      ) {
        notification.error({
          description: "错误 文件内不包含有效Hash 请重新抓包",
          duration: 0,
        });
        return;
      }

      //把allHashName和fileExtension参数放过去
      if (whatHaveResult?.data?.data?.allHashName) {
        setUpdateData((state) => {
          const tem = { ...state };
          tem.allHashName = whatHaveResult.data.data.allHashName;
          tem.fileExtension = whatHaveResult.data.data.fileExtension;
          return tem;
        });
      }

      //在这里 将hashInfo过滤一遍 相同SSID只保留一个

      if (!whatHaveResult?.data?.data?.hashInfo) return;
      let hashInfo = whatHaveResult.data.data.hashInfo;

      hashInfo = Array.from(hashInfo).reduce((acc, cur, index) => {
        if (
          acc.some(
            (e) => e.SSID === cur.SSID && e.MAC_CLIENT === cur.MAC_CLIENT
          )
        ) {
          return acc;
        } else {
          acc.push(cur);
          return acc;
        }
      }, []);

      //2-5个包
      if (hashInfo.length > 1) {
        notification.warning({
          duration: 10,
          message:
            "此包包含多个不同的SSID 请对应抓包设备的MAC地址正确选择 跑出后不得以HASH为由拒绝付款",
        });
      }

      put_hashInfo(hashInfo);

      hashInfo.forEach((sing) => {
        if (sing.exist) {
          Modal.confirm({
            okText: "立即查看",
            cancelText: "继续提交",
            onOk: () => {
              window.location.href = `/feedback?mac_ap=${sing.MAC_AP.slice(0, sing.MAC_AP.indexOf(" "))}`;
            },
            title: `${sing.SSID}已经被上传`,
            content: (
              <span>
                此WIFI之前已经被上传至曹操wifi，请查看反馈信息判断是否需要继续提交，有疑问{" "}
                <a
                  href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                  target="_blank"
                >
                  查看帮助
                </a>
              </span>
            ),
          });
        } else {
          if (
            String(whatHaveResult?.data?.data?.fileExtension).startsWith(
              "hccap"
            )
          ) {
            Modal.success({
              title: "恭喜你！",
              content:
                "此哈希将享受我们的优惠方案！无需电费任务将自动开始🎉🎉🎉",
            });
          }
        }
      });
    },
    [whatHaveResult]
  );

  //----------验证码----------

  //控制验证码的内容
  const [code, setCode] = useState(random());

  //控制验证码的Ref
  const captchaRef = useRef();

  //控制验证码输入框的Ref

  const captchaInputRef = useRef();

  //检查验证码正确与否
  function checkCaptcha() {
    console.log("you got me ");
  }

  //返回随机数字 作为验证码
  function random() {
    const tem = [];
    const char = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "z",
      "f",
      "h",
      "q",
    ];
    for (let i = 1; i <= 4; i++) {
      tem.push(char[Math.floor(Math.random() * char.length)]);
    }
    return tem.join("");
  }

  //----------输入内容双向绑定与提交----------

  //双向绑定
  const [updateData, setUpdateData] = useState({
    xianyu_name: "",
    city: "",
    wifi_name: "",
    wifi_owner_xing: "",
    wifi_owner_name: "",
    HASHLINE: "",
    HASH_ATTEND: "",
    status: "waitCheck",
    mail: "",
  });

  //客户端页面专用
  useEffect(() => {
    if (email) {
      setUpdateData((state) => {
        return { ...state, mail: email };
      });
    }
  }, [email]);

  //负责所有输入内容的双向绑定
  function onChangeHandler(e) {
    const key = e.target.id;

    //1.是文件的情况 要先检查后缀
    if (key === "file") {
      const file = e.target?.files[0];
      if (!file) return;
      const fileName = file.name;
      const fileSizeMb = file.size / 1024 / 1024;
      const extensions = [
        "cap",
        "pcap",
        "pcapng",
        "hccapx",
        "hccap",
        "hc22000",
      ]; //支持的格式
      const ext = fileName.split(".").pop(); // 提取文件扩展名

      if (bigFile === false && fileSizeMb >= 150) {
        message.info("文件大于150Mb 请联系管理员");
        return;
      }
      if (extensions.includes(ext)) {
        getHashInfo(e.target.files[0]);
      } else {
        notification.open({
          message: "文件格式错误",
          description: (
            <span>
              <p>
                曹操wifi支持cap、pcap、pcapng、hccap、hccapx、hc22000格式，请先确认您的握手包格式，如有疑问点击下方链接或咨询客服。
              </p>
              <a href="https://www.ccwifi.cc/blogs/index.php/category/qa/">
                寻求帮助
              </a>
            </span>
          ),
          duration: 0,
        });
      }
    } else if (key !== "yanzhengma") {
      //2.是文字的情况(默认)

      giveMeKeyAndValue(key, e.target.value);
    } else {
      //3.检查验证码的情况
      giveMeKeyAndValue(key, e.target.value);
      if (e.target.value === code) {
        captchaInputRef.current.style.border = "2px solid green";
      } else {
        captchaInputRef.current.style.border = "2px solid red";
      }
    }

    //附加函数1.给一个键值和内容 自动修改state
    function giveMeKeyAndValue(key, value) {
      setUpdateData((state) => {
        const tem = { ...state };
        tem[key] = value;
        return tem;
      });
    }
  }

  //点击提交按钮
  async function submit() {
    console.log(updateData);
    if (isChecked === false) {
      message.error("请同意《使用条款》");
      return;
    }

    //对比验证码

    if (updateData.yanzhengma !== code) {
      message.warning("验证码不正确");
      return;
    }

    if (!updateData.wifi_name || !updateData.HASHLINE) {
      Modal.warn({
        title: "wifi未选择",
        content:
          "请在上面框中点击一个WIFI 如已点击 则此Wifi无可用HASH 请另外选择 有问题请联系客服",
      });
      return;
    }

    if (
      !updateData.mail ||
      !updateData.city ||
      !/.*\@.*\..*/.test(updateData.mail)
    ) {
      message.warning("请完善邮件 城市信息");
      return;
    }

    // if (!updateData.qq) {
    //   message.warning("请填写QQ");
    //   return;
    // }

    const result = await axios({
      url: `${serverAddress}/business`,
      method: "POST",
      data: { ...updateData },
    });

    if (result.data.status === "scuess") {
      setUpdateData({
        xianyu_name: "",
        city: "",
        wifi_name: "",
        wifi_owner_xing: undefined,
        wifi_owner_name: undefined,
        file: undefined,
        allHashName: "",
      });

      alert("即将打开数据后台 请收藏 关注任务进度");

      /*-------------
      此处存放提交成功后的操作

      




      -------------*/

      const mac_ap = result.data.tem.MAC_AP.MAC;
      window.location = `/feedback?mac_ap=${mac_ap}&new=true`; //new=true 代表新任务，将会自动发送信息
      return;
    }

    if (result.data.status === "fail" && result.data.message) {
      alert(result.data.message);
    } else {
      alert(result.data.data.message);
    }
  }

  //负责在提交cap包后 获取hashinfo

  async function getHashInfo(value) {
    const formData = new FormData();
    formData.append("cap_file", value);
    postWhatHave(formData);
  }

  //负责将点击WIFI图标后的操作
  function onClickHash(e) {
    const theCard = e.target.closest("div"); //通过DOM穿越 锁定Card带有数据的div
    const theHashInfo = JSON.parse(theCard.dataset.data);

    console.log(theHashInfo);

    if (!theHashInfo?.HASHLINE) {
      notification.error({
        message: "不存在数据！",
      });
      return;
    }

    message.success(`选择${theHashInfo.SSID}`);

    setUpdateData((state) => {
      const tem = { ...state };
      tem.HASHLINE = theHashInfo.HASHLINE;
      tem.HASH_ATTEND = JSON.stringify(theHashInfo);
      tem.wifi_name = theHashInfo.SSID;
      tem.exist = theHashInfo?.exist;
      return tem;
    });
  }

  return (
    <>
      <Helmet>
        <title>
          曹操wifi_在线提交握手包跑包
          {bigFile === true ? "_大文件提交专用入口" : ""}
        </title>
        <meta
          name="Description"
          content="曹操Wifi 在线跑wifi握手包跑包，cap跑包，跑金刚包,支持cap格式、hccapx、hc22000、pcapng格式，在线跑包，支持光猫包，金刚包跑包。"
        />
        <meta
          name="Keywords"
          content="曹操Wifi,cap跑包, wifi跑包, hccapx跑包，pcapng跑包，握手包跑包, wifi握手包,  wifi密码跑包, 金刚包,"
        />
        <meta name="author" content="曹操Wifi" />
      </Helmet>
      <Modal open={open} width={width} onOk={submit} onCancel={handleCancel}>
        <section className={classes.container}>
          <h1>曹操Wifi</h1>

          {/* <p className={classes.descript}>—————把跑包做到定制化</p> */}
          <article className={classes.submitContainer}>
            <div className={classes.block}>
              <label>上传Cap文件</label>

              <div
                className={classes.uploadContent}
                style={{
                  display: whatHaveResult.isSuccess === true ? "none" : "block",
                }}
              >
                <Spin
                  spinning={whatHaveResult.isLoading}
                  size="large"
                  style={{
                    display:
                      whatHaveResult?.isLoading === true ? "block" : "none",
                  }}
                />

                <label
                  onDrop={(e) => {
                    //拖动文件
                    const dt = e.dataTransfer;
                    const files = dt.files; //获取拖动过来的文件内容
                    fileRef.current.files = files; //替换给file的files参数
                    onChangeHandler({ target: fileRef.current }); //模拟file被上传
                  }}
                  for="file"
                  className={classes.hover_pointer}
                  style={{
                    display:
                      whatHaveResult?.isUninitialized === true
                        ? "flex"
                        : "none",
                  }}
                >
                  <FileArrowUp size={32} color="blue" />
                  <p id={classes.点击}>点击或拖动上传握手包文件</p>
                  <p id={classes.支持}>
                    支持cap、pcap、pcapng、hccap、hccapx、hc22000格式
                  </p>
                </label>
                <input
                  ref={fileRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeHandler}
                  id="file"
                  file={
                    updateData.file?.files?.[0]
                      ? updateData.file.files[0]
                      : null
                  }
                ></input>
              </div>
            </div>

            {(function 渲染选择ESSID() {
              //负责渲染选择ESSID结果的部分

              let ready_return = (
                <div style={{ marginTop: "-1rem" }}>
                  <p>test</p>

                  <Card
                    title={
                      <p style={{ display: "flex", alignItems: "center" }}>
                        请选择WIFI
                      </p>
                    }
                  >
                    {hashInfo?.map((sing_hash_info) => {
                      if (
                        !sing_hash_info?.HASHLINE ||
                        !sing_hash_info?.MAC_CLIENT
                      ) {
                        return;
                      } else {
                        return (
                          <Card.Grid
                            data-data={JSON.stringify(sing_hash_info)}
                            onClick={onClickHash}
                            className={`${classes.hover_pointer} ${updateData?.wifi_name ? "" : classes.glow_animation}`}
                          >
                            <h4>WIFI名:{sing_hash_info.SSID}</h4>
                            <p>
                              设备MAC:
                              {sing_hash_info?.MAC_CLIENT?.replace(
                                "(Unknown)",
                                ""
                              )}
                            </p>
                          </Card.Grid>
                        );
                      }
                    })}
                  </Card>
                </div>
              );

              if (!hashInfo?.[0]) return;
              return ready_return;
            })()}

            <div className={classes.block}>
              <label>邮箱地址</label>
              <Input
                disabled={email ? true : false}
                type="text"
                id="mail"
                size="small"
                placeholder="请正确填写，将在密码跑出后进行通知"
                // defaultValue={email ? email : ""}
                onChange={onChangeHandler}
                value={updateData.mail}
              />
            </div>

            {/* <div className={classes.block}>
              <label>QQ号</label>
              <Input
                type="text"
                id="qq"
                placeholder="请正确填写，将在密码跑出后进行通知"
                onChange={onChangeHandler}
                value={updateData.qq}
              />
            </div> */}

            {/* <div className={classes.block}>
            <label>手机号</label>
            <Input
              type="phone"
              id="phone"
              placeholder="用于接受通知 选填"
              onChange={onChangeHandler}
              value={updateData.phone}
            />
          </div> */}

            {/* <div className={classes.block}>
            <label>闲鱼昵称</label>
            <Input
              placeholder="如果在闲鱼下单 请填写"
              type="text"
              id="xianyu_name"
              onChange={onChangeHandler}
              value={updateData.xianyu_name}
            />
          </div> */}
            <div className={classes.block}>
              <label>城市</label>
              <AutoComplete
                filterOption={(inputValue, option) => {
                  return String(option.value).includes(inputValue);
                }}
                key="city"
                size="large"
                options={cityes?.map((e) => {
                  return { value: e, label: e };
                })}
                placeholder="填写城市或国家"
                id="city"
                onChange={(value) => {
                  onChangeHandler({ target: { id: "city", value } });
                }}
                onSelect={(value) => {
                  onChangeHandler({ target: { id: "city", value } });
                }}
                value={updateData.city}
              />
            </div>
            <div className={classes.block}>
              <label>Wifi名称</label>
              <Input
                disabled
                placeholder="请点击Wifi名"
                type="text"
                id="wifi_name"
                onChange={onChangeHandler}
                value={updateData.wifi_name}
              />
            </div>

            {/* <div className={classes.block}>
              <label>Wifi户主姓氏</label>

              <Input
                placeholder="补充户主的信息 提高成功率"
                type="text"
                id="wifi_owner_xing"
                onChange={onChangeHandler}
                value={updateData.wifi_owner_xing}
              ></Input>
            </div> */}

            <div className={classes.block}>
              <label style={{ display: "flex", alignItems: "center" }}>
                户主姓名
                <Popover
                  content={
                    <div>
                      <h1>信息在哈希逆向的意义</h1>
                      <List className="password-tips">
                        <List.Item>
                          据{" "}
                          <a
                            href="https://www.ccwifi.cc/blogs/index.php/category/qa/"
                            target="_blank"
                          >
                            统计
                          </a>
                          ，60%的人会使用名字缩写作为密码。如果提供此信息，成功率将大大提高。
                        </List.Item>
                        <List.Item>
                          家用密码一般由8-12位字符组成。仅8位小写大写数字，就有一百万亿种排列方式，一张4090
                          200w/s的速度需要3.4年。
                        </List.Item>
                        <List.Item>
                          尽可能的打听Wifi主人信息，对跑包成功率有帮助。
                          <List
                            className="sub-list"
                            itemLayout="horizontal"
                            dataSource={[
                              {
                                title: "📶提供城市",
                                content: "可以跑城市手机号",
                              },
                              {
                                title: "📶提供Wifi户主名字或姓氏",
                                content: "可以基于此跑姓名+数字的所有组合",
                              },
                              {
                                title: "📶提供Wifi主人曾用密码",
                                content: "可以推测其现用密码组成规律。",
                              },
                              {
                                title: "📶提供Wifi主人手机号",
                                content:
                                  "可以跑姓名+手机号，姓氏+手机号所有可能。",
                              },
                            ]}
                            renderItem={(item) => (
                              <List.Item>
                                <List.Item.Meta
                                  title={<span>{item.title}</span>}
                                  description={<p>{item.content}</p>}
                                />
                              </List.Item>
                            )}
                          />
                        </List.Item>
                      </List>
                    </div>
                  }
                >
                  <Question color="#DDA0DD" size={24} />
                </Popover>
              </label>
              <Input
                placeholder="让成功率达到40%"
                type="text"
                id="wifi_owner_name"
                onChange={onChangeHandler}
                value={updateData.wifi_owner_name}
              ></Input>
            </div>

            <div className={classes.block}>
              <label>备注</label>
              <Input
                placeholder="你知道的其他信息"
                type="text"
                id="note"
                onChange={onChangeHandler}
                value={updateData.note}
              ></Input>
            </div>

            <div className={`${classes.block} `} onChange={checkCaptcha}>
              <label>验证码</label>
              <div className={classes.yanzhengma_block}>
                <input
                  style={{ fontSize: "1.8rem" }}
                  type="text"
                  id="yanzhengma"
                  onChange={onChangeHandler}
                  ref={captchaInputRef}
                  className={classes.dontHaveOutline}
                />

                <Captcha
                  ref={captchaRef}
                  code={code}
                  onClick={() => {
                    const tem = random();
                    setCode(tem);
                  }}
                />
              </div>
            </div>

            <div className={`${classes.block} `} onChange={checkCaptcha}>
              <label>服务须知</label>

              <Checkbox
                onChange={(e) => {
                  // console.log(e);
                  setIsChecked(e);
                }}
                checked={isChecked}
              >
                我已阅读并同意
                <a
                  href="https://www.ccwifi.cc/blogs/index.php/2024/03/28/%e4%bd%bf%e7%94%a8%e6%9d%a1%e6%ac%be/"
                  target="_blank"
                >
                  《使用条款》
                </a>
                <a
                  href="https://www.ccwifi.cc/blogs/index.php/2024/04/18/%e9%9a%90%e7%a7%81%e6%8c%87%e5%8d%97/"
                  target="_blank"
                >
                  《隐私政策》
                </a>
              </Checkbox>
            </div>
          </article>
        </section>
      </Modal>
    </>
  );
}

export default Upload_Business;
