import React, { useState, useEffect } from "react";
import 电脑端主页 from "./主页.desktop";
import 移动端主页 from "./主页.mobile";

const DeviceAwareComponent = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768); // 假设768px为分界点

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    // 清理函数，组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 空数组作为依赖项，意味着这个effect只会在组件挂载时运行一次

  return isDesktop ? <电脑端主页 /> : <移动端主页 />;
};

export default DeviceAwareComponent;
