import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { configure } from "../Stores/configure";

const serverAddress = configure.getInitialState().serverAddress;

export const BusinessApi = createApi({
  reducerPath: "BusinessApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serverAddress + "/business",
    prepareHeaders: (headers, { getState }) => {
      // 尝试从 localStorage 中获取 token
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["business"],
  endpoints(build) {
    return {
      lookForFresh_And_NotBeConfirmed: build.query({
        providesTags: ["business"],
        query() {
          return "lookForFresh_And_NotBeConfirmed";
        },
      }),

      getOne: build.mutation({
        providesTags: ["business"],
        query(stu) {
          return {
            url: `/${stu}`,
            method: "GET",
          };
        },
      }),

      deleteOneBusiness: build.mutation({
        invalidatesTags: ["business"],
        query(stu) {
          return {
            url: `/${stu}`,
            method: "DELETE",
          };
        },
      }),

      updataOneBusiness: build.mutation({
        invalidatesTags: ["business"],
        query(stu) {
          return {
            url: `/${stu._id}`,
            method: "PATCH",
            body: stu,
          };
        },
      }),

      getAllBusinesses: build.query({
        providesTags: ["business"],
        query(search) {
          return `/?search=${search}`;
        },
      }),

      postBusiness: build.mutation({
        query(stu) {
          return {
            url: "/",
            method: "POST",
            body: stu,
          };
        },
      }),

      postWhatHave: build.mutation({
        query(stu) {
          return {
            url: "/whathave",
            method: "POST",
            body: stu,
          };
        },
      }),

      getOneByMac: build.query({
        query({ mac_ap, notrack }) {
          return {
            url: `/getOneByMac/${mac_ap}?${notrack ? "notrack=true" : ""}`,
            method: "get",
          };
        },
      }),

      patchStatus: build.mutation({
        query(stu) {
          return {
            url: `/patchStatus/${stu.id}`,
            method: "PATCH",
            body: {
              status: stu.status,
              charge_type: stu.charge_type,
              confirmed: stu?.confirmed,
            },
          };
        },
      }),
      tongji: build.mutation({
        query({ start_at, end_at, unit, message }) {
          // console.log(start_at, end_at);
          return {
            url: `/tongji/?start_at=${start_at}&end_at=${end_at}&unit=${unit}&message=${message}`,
            method: "GET",
          };
        },
      }),
      tongji_init: build.query({
        query() {
          const start_at = new Date().setHours(0, 0, 0, 0);
          const end_at = new Date().setHours(24, 0, 0, 0);
          const unit = "day";
          const message = "初始化";
          const query = `?start_at=${start_at}&end_at=${end_at}&unit=${unit}&message=${message}`;

          return {
            url: `/tongji/${query}`,
            method: "GET",
          };
        },
      }),

      getByMail: build.query({
        query(mail) {
          if (!mail) return;
          // 邮件地址应该被编码以避免URL问题
          const encodedMail = encodeURIComponent(mail);
          return {
            url: `/getByMail/${encodedMail}`,
            method: "GET",
          };
        },
        // 可选：提供此查询的tag，以便在缓存管理等方面使用
        providesTags: ["business"],
      }),
    };
  },
});

export const {
  useLookForFresh_And_NotBeConfirmedQuery,
  useGetOneMutation,
  useDeleteOneBusinessMutation,
  useUpdataOneBusinessMutation,
  useGetAllBusinessesQuery,
  usePostBusinessMutation,
  usePostWhatHaveMutation,
  useGetOneByMacQuery,
  usePatchStatusMutation,
  useTongjiMutation,
  useTongji_initQuery,
  useGetByMailQuery,
} = BusinessApi;
