import classes from "./HistoricalTasks.module.css";
import {
  Bird,
  Gavel,
  Hammer,
  Package,
  Pencil,
  Question,
} from "@phosphor-icons/react";
import { useGetAllBusinessesQuery } from "../Api/BusinessApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, DatePicker, Skeleton, Table, Tooltip } from "antd";
import Input from "antd/es/input/Input";
import { debounce, throttle } from "lodash";
import { Helmet } from "react-helmet";

/**
 * # 历史任务组件
 *
 * 用来显示历史任务，对任务进行增删改查。
 *
 * ## State
 *
 * - allBusinessData: 获取所有的Business信息
 * - inputValue:搜索框的输入内容
 * - searchValue：等同于inputValue，但是按钮按下后，会将inputValue赋值给searchValue
 *
 * ## 函数
 *
 *
 *
 *
 * @returns
 */
function HistoricalTasks() {
  //-----------钩子函数----------
  //所有的Business信息
  const [allBusinessData, putAllBusinessData] = useState();
  const [inputValue, setInputValue] = useState(""); // 用于存储输入框的实时值
  const [searchValue, setSearchValue] = useState("请输入内容");
  const { data: allBusinessesResult, isFetching } =
    useGetAllBusinessesQuery(searchValue);
  useEffect(() => {
    if (!allBusinessesResult?.data) return;
    putAllBusinessData(
      allBusinessesResult.data.map((sing, index) => ({
        ...sing,
        index: index,
        key: index,
      }))
    );
  }, [allBusinessesResult]);

  const nav = useNavigate();

  // ----------自定义函数----------

  if (!allBusinessData) return <Skeleton />;

  return (
    <div>
      <Helmet>
        <title>历史任务</title>
      </Helmet>
      <div className={classes.input_div}>
        <Input
          placeholder="搜素"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onPressEnter={() => setSearchValue(inputValue)}
        />
        <DatePicker placeholder="开始时间" />
        <DatePicker placeholder="结束时间" />
        <Button
          type="primary"
          loading={isFetching}
          onClick={() => {
            setSearchValue(inputValue);
          }}
        >
          搜索
        </Button>
      </div>

      <Table
        columns={[
          {
            title: "序号",
            dataIndex: "key", // 假设数据源中的每个对象都有一个唯一的'key'字段作为序号
            key: "key",
            width: 60, // 可以设置列的宽度，根据需要调整
          },
          {
            title: "wifi名",
            dataIndex: "wifi_name", // 假设数据源中有'wifi_name'字段表示项目名
            key: "wifiName",
            render: (wifi_name, record) => {
              return (
                <p
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {(() => {
                    switch (record?.charge_type) {
                      case 0:
                        return <Bird size={24} />;

                      case 1:
                        return <Package size={24} />;

                      case 2:
                        return <Hammer size={24} />;

                      case 3:
                        return <Gavel size={24} />;
                      default:
                        return <Question size={24} />;
                    }
                  })()}
                  {record.wifi_name.length > 10
                    ? `${record.wifi_name.slice(0, 10)}...`
                    : record.wifi_name}
                </p>
              );
            },
          },
          // {
          //   title: "咸鱼昵称",
          //   dataIndex: "xianyu_name", // 假设数据源中有'xianyu_name'字段表示咸鱼昵称
          //   key: "xianyuName",
          // },
          {
            title: "时间",
            dataIndex: "create_at", // 假设数据源中有'create_at'字段表示时间
            key: "createAt",
            sorter: (a, b) =>
              new Date(a.create_at).getTime() - new Date(b.create_at).getTime(),
            render: (text) =>
              new Date(text).toLocaleString("zh-CN").slice(0, 9), // 格式化日期显示
            responsive: ["md"],
          },

          {
            title: "状态",
            dataIndex: "status", // 假设数据源中有'status'字段表示状态
            key: "status",
            responsive: ["md"],
            render: (status, record) => {
              console.log("record", record);

              const statusMessages = {
                waitCheck: "⏱️等待启动任务",
                running: "👌运行中",
                finished: {
                  false: "🥲已跑完 没有跑出密码 需要通知客户",
                  true: "🥲已结束 未跑出 已经通知客户",
                },
                solved: {
                  false: "💲已跑出密码 暂未收款",
                  true: "💲已结束 已赚钱",
                },
              };

              // 默认状态
              const defaultMessage = "🤔未知状态";

              // 根据record.status和record.confirmed获取状态消息
              const statusMessage =
                record.status === "finished"
                  ? statusMessages[record.status][record.confirmed] ||
                    defaultMessage
                  : record.status === "solved"
                    ? statusMessages[record.status][record.payed] ||
                      defaultMessage
                    : statusMessages[record.status] || defaultMessage;

              return statusMessage;
            },
          },
          // {
          //   title: "收入",
          //   dataIndex: "income", // 假设数据源中有'income'字段表示收入
          //   key: "income",
          //   render: (income) => `￥${income || 0}`, // 显示收入金额，如果为undefined或null则显示为0
          // },
          {
            title: "备注",
            render: (record) => {
              // 检查备注是否存在
              const hasNotes = record?.note || record?.commercialNote;

              // 构造备注内容
              let noteContent = "";
              if (record?.note) {
                noteContent += `客户备注：${record.note}; `;
              }
              if (record?.commercialNote) {
                noteContent += `商家备注：${record.commercialNote}; `;
              }

              // 根据备注是否存在来渲染 Tooltip
              const TooltipComponent = hasNotes ? (
                <Tooltip title={noteContent}>备注</Tooltip>
              ) : (
                ""
              );

              return TooltipComponent;
            },
          },
          {
            title: "操作",
            key: "operation",
            render: (_, record) => (
              <span>
                <Pencil
                  size={26}
                  onClick={() => {
                    // 处理操作按钮的点击事件，例如导航到编辑页面
                    nav(`/control/xiugairenwu/${record._id}`);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </span>
            ),
          },
        ]}
        dataSource={allBusinessData}
      />
    </div>
  );
}

export default HistoricalTasks;
