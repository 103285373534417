import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { configure } from "../Stores/configure";

const serverAddress = configure.getInitialState().serverAddress;

export const MagicApi = createApi({
  reducerPath: "MagicApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serverAddress + "/magic",
    prepareHeaders: (headers, { getState }) => {
      // 尝试从 localStorage 中获取 token
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(build) {
    return {
      allcity: build.query({
        query() {
          return "/allcity";
        },
      }),
    };
  },
});

export const { useAllcityQuery } = MagicApi;
