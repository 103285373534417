import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { configure } from "../Stores/configure";

const serverAddress = configure.getInitialState().serverAddress;

export const payApi = createApi({
  tagTypes: ["a"],
  reducerPath: "payApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serverAddress + "/pay",
    prepareHeaders: (headers, { getState }) => {
      // 尝试从 localStorage 中获取 token
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(build) {
    return {
      create_order: build.mutation({
        query({ business_id, wifi_name, subject, pay_type }) {
          return {
            url: "",
            method: "POST",
            body: {
              business_id,
              wifi_name,
              subject,
              pay_type,
            },
          };
        },
      }),
      order_status: build.query({
        query(order_no) {
          return order_no;
        },
      }),
      get_all: build.query({
        providesTags: ["a"],
        query(stu) {
          console.log("api", stu);
          return {
            url: `/all${stu}`,
            method: "GET",
          };
        },
      }),
      postone: build.mutation({
        invalidatesTags: ["a"],
        query(body) {
          return {
            url: `/diy`,
            method: "POST",
            body,
          };
        },
      }),

      putone: build.mutation({
        invalidatesTags: ["a"],
        query(stu) {
          return {
            url: `/${stu.id}`,
            method: "PUT",
            body: stu.body,
          };
        },
      }),

      delete: build.mutation({
        invalidatesTags: ["a"],
        query(id) {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useCreate_orderMutation,
  useOrder_statusQuery,
  useGet_allQuery,
  usePostoneMutation,
  usePutoneMutation,
  useDeleteMutation,
} = payApi;
