import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ZidianApi } from "../Api/ZidianApi";
import { BusinessApi } from "../Api/BusinessApi";
import { TaskApi } from "../Api/TaskApi";
import { configure } from "./configure";
import { payApi } from "../Api/PayApi";
import { MagicApi } from "../Api/MagicApi";

const store = configureStore({
  reducer: {
    [ZidianApi.reducerPath]: ZidianApi.reducer,
    [BusinessApi.reducerPath]: BusinessApi.reducer,
    [TaskApi.reducerPath]: TaskApi.reducer,
    [configure.name]: configure.reducer,
    [payApi.reducerPath]: payApi.reducer,
    [MagicApi.reducerPath]: MagicApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(ZidianApi.middleware)
      .concat(BusinessApi.middleware)
      .concat(TaskApi.middleware)
      .concat(payApi.middleware)
      .concat(MagicApi.middleware);
  },
});

export default store;
