import { useCallback, useEffect, useMemo, useState } from "react";
import { Crisp } from "crisp-sdk-web";
import { message } from "antd";

/**
 * 初始化crisp聊天窗口
 */
export function useCrisp(position = "right") {
  // useEffect(function _init_Crisp() {
  //   Crisp.configure("a517ca9b-4583-4e39-8b1f-66cc9bbcd7a4");
  //   Crisp.setPosition(position);
  // }, []);
}

/**
 * 用于加载Salesmartly的在线聊天模块
 */
export function useSaleSmartly(mail) {
  const src =
    "https://assets.salesmartly.com/js/project_52920_55320_1712985211.js";
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [loginInfoSet, setLoginInfoSet] = useState(false);
  const [ssqLoaded, setSsqLoaded] = useState(false); // 新增状态来跟踪ssq是否加载

  // 使用useMemo来确保只在src改变时创建新的脚本元素
  const script = useMemo(() => {
    const scriptElement = document.createElement("script");
    scriptElement.src = src;
    scriptElement.async = true;

    scriptElement.onload = () => {
      setScriptLoaded(true);
      setSsqLoaded(true); // 标记ssq已加载
    };

    scriptElement.onerror = () => {
      console.error(`Script load error for ${src}`);
    };

    return scriptElement;
  }, [src]);

  // 使用useCallback来确保在组件生命周期内，checkAndSetLoginInfo函数保持不变
  const checkAndSetLoginInfo = useCallback(() => {
    if (window.ssq && mail && !loginInfoSet) {
      window.ssq.push("setLoginInfo", {
        user_id: mail,
        user_name: mail,
        email: mail,
        description: "使用曹操wifi网页端登录",
        label_names: ["自动生成"],
      });
      setLoginInfoSet(true);
    }
  }, [mail, loginInfoSet]);

  // 当ssq加载完成时，检查并设置登录信息
  useEffect(() => {
    if (ssqLoaded && mail && !loginInfoSet) {
      checkAndSetLoginInfo();
    }
  }, [ssqLoaded, mail, loginInfoSet, checkAndSetLoginInfo]);

  // 使用useEffect来加载脚本
  useEffect(() => {
    if (!scriptLoaded) {
      document.head.appendChild(script);
    }

    return () => {
      if (script.parentNode) {
        document.head.removeChild(script);
      }
    };
  }, [scriptLoaded, script]);

  return scriptLoaded;
}

/**
 * 特定场景下自动发送信息
 *
 * - 已经出包并查看
 */
export function useSendSaleSmartlyMessage(type, wifi_name) {
  // console.log("isme", type, wifi_name);

  const [isSsqLoaded, setIsSsqLoaded] = useState(false); // 新增状态来跟踪window.ssq是否加载

  // 检查window.ssq是否已经加载
  useEffect(() => {
    const checkSsqLoaded = () => {
      if (window.ssq) {
        setIsSsqLoaded(true);
      } else {
        setTimeout(checkSsqLoaded, 100); // 如果未加载，则稍后再次检查
      }
    };
    checkSsqLoaded();
  }, []); // 空依赖数组意味着此effect仅在组件挂载时执行一次

  // 当type, wifi_name或isSsqLoaded变化时，尝试发送信息
  useEffect(() => {
    const sendTextMessage = () => {
      if (isSsqLoaded && window.ssq && type === "跑出未付款") {
        // 如果条件满足且window.ssq可用，则发送信息
        setTimeout(() => {
          window.ssq.push("chatOpen");
        }, 1000);

        setTimeout(() => {
          // message.success("已自动发送信息给客服");
          // window.ssq.push("sendTextMessage", "it is a new error");

          window.ssq.push(
            "sendTextMessage",
            `我已查看${wifi_name}的未付款订单`
          );
          window.ssq.push("chatOpen");
        }, 3000); // 延迟3000毫秒后发送
      }

      if (isSsqLoaded && window.ssq && type === "新提交" && wifi_name) {
        // 如果条件满足且window.ssq可用，则发送信息
        // message.success("已自动发送新提交");

        setTimeout(() => {
          window.ssq.push("chatOpen");
        }, 1000);

        setTimeout(() => {
          // window.ssq.push("sendTextMessage", "it is a new error");
          window.ssq.push("sendTextMessage", `我新递交${wifi_name}`);
          window.ssq.push("chatOpen");

          setTimeout(() => {
            // 获取当前页面的URL
            const currentUrl = window.location.href;
            const urlSplit = currentUrl.split("?");
            const baseUrl = urlSplit[0]; // 获取不包含查询参数的基础URL
            const queryParams = urlSplit.length > 1 ? urlSplit[1] : ""; // 获取查询参数字符串

            // 创建URLSearchParams实例来修改查询参数
            const urlParams = new URLSearchParams(queryParams);
            urlParams.delete("new"); // 删除'new'参数

            // 生成新的查询字符串
            const newQueryParams = urlParams.toString();

            // 构造新的URL并跳转
            const newUrl = newQueryParams
              ? `${baseUrl}?${newQueryParams}`
              : baseUrl;
            // 构造新的URL并替换当前页面，不留历史记录
            window.location.replace(newUrl);
          }, 5000);
        }, 5000); // 延迟3000毫秒后发送
      }
    };

    sendTextMessage();
  }, [type, wifi_name, isSsqLoaded]); // 依赖数组添加isSsqLoaded
}

/**
 * # 掩码到容量
 *
 * 给予掩码，返回对应的大小、行数，用于Snapchat正确统计
 */
export function calculatePossibilities(mask) {
  let total = 1;
  const pattern = /(\?d|\?l|\?u|\?s|\?h|\?H|\?a)/g;
  let match;

  while ((match = pattern.exec(mask)) !== null) {
    switch (match[0]) {
      case "?d": // 数字
        total *= 10;
        break;
      case "?l": // 小写字母
        total *= 26;
        break;
      case "?u": // 大写字母
        total *= 26;
        break;
      case "?s": // 特殊符号
        total *= 33;
        break;
      case "?h": // 小写十六进制数字
        total *= 16;
        break;
      case "?H": // 大写十六进制数字
        total *= 16;
        break;
      case "?a": // 所有可打印字符
        total *= 95;
        break;
    }
  }

  // console.log(total);

  let Size = (total / 99999999) * 0.8; //GB

  Size = parseFloat(Size.toFixed(2));

  return {
    total,
    Size,
    mask,
  };
}
