import React, { useEffect, useState } from "react";
import {
  useGetDepentQuery,
  useGetDicFileListsQuery,
  usePostOneZidianMutation,
  usePatchOneZidianMutation,
  useDeleteOneZidianMutation,
} from "../Api/ZidianApi";
import Modal from "antd/es/modal/Modal";
import classes from "./DicEditModal.module.css";
import { Input } from "antd";

/**
 * # 用于修改字典内容的模态窗口
 */
function DicEditModal({ whatAmiDo, visiable, toggle, _updateData }) {
  //API1.获得依赖字段
  const { data: depentLists } = useGetDepentQuery();

  //API2.获得字典目录

  const { data: dicFileLists } = useGetDicFileListsQuery();

  //API3.Post数据

  const [postOne, postOneResult] = usePostOneZidianMutation();

  //API4.Patch数据

  const [patchOne] = usePatchOneZidianMutation();

  //API5.Delete数据

  const [deleteOne] = useDeleteOneZidianMutation();

  //双向绑定

  useEffect(() => {
    setUpdateData(_updateData);
  }, [_updateData]);

  const [updateData, setUpdateData] = useState();

  //检测Post数据
  useEffect(() => {
    //console.log(postOneResult);
    if (postOneResult.status === "fulfilled") {
      alert("提交成功");
      setUpdateData({});
    }
  }, [postOneResult]);

  function onChangeHandler(e) {
    setUpdateData((state) => {
      if (e.target.id === "increment" || e.target.id === "auto_enable") {
        const tem = { ...state };
        tem[e.target.id] = e.target.checked;
        return tem;
        return;
      }
      if (e.target.id === "dic") {
        const tem = { ...state };
        tem[e.target.id] = e.target.value;
        tem.zidian_name = e.target.value;
        return tem;
      }

      const tem = { ...state };
      tem[e.target.id] = e.target.value;
      return tem;
    });
  }

  return (
    <Modal
      open={visiable}
      title="字典编辑"
      onCancel={toggle}
      footer={null}
      width={800}
    >
      <div className={classes.modal}>
        <div data-description="负责输入内容" className={classes.inputContent}>
          <div className={classes.block}>
            <label>字典名</label>
            <input
              type="text"
              id="zidian_name"
              onChange={onChangeHandler}
              value={updateData?.zidian_name || ""}
            ></input>
          </div>

          <div className={classes.block}>
            <label>攻击类型</label>
            <select
              id="type"
              onChange={onChangeHandler}
              value={updateData?.type || ""}
            >
              <option unselectable>这里选择类型</option>
              <option value={"0"}>-a 0 字典攻击</option>
              <option value={"1"}>-a 1 组合攻击</option>
              <option value={"3"}>-a 3 掩码攻击</option>
              <option value={"6"}>-a 6 字典+掩码</option>
              <option value={"7"}>-a 7 掩码+字典</option>
              <option value={"X"}>X 特殊类型</option>
            </select>
          </div>

          <div className={classes.block}>
            <label>优先级</label>
            <select
              id="priority"
              onChange={onChangeHandler}
              value={updateData?.priority || ""}
            >
              <option>未指定</option>
              <option value={1}>滞后</option>
              <option value={2}>普通</option>
              <option value={3}>优先</option>
              <option value={4}>紧急</option>
            </select>
          </div>

          <div className={classes.block}>
            <label>依赖项</label>
            <select
              id="depend"
              onChange={onChangeHandler}
              value={updateData?.depend || ""}
            >
              <option>无</option>
              {depentLists?.data?.map((sing) => {
                const paichu = [
                  "file",
                  "create_at",
                  "status",
                  "confirmed",
                  "_id",
                  "__v",
                  "id",
                  "xianyu_name",
                ];
                if (paichu.includes(sing)) return;
                return <option key={sing}>{sing}</option>;
              })}
            </select>
          </div>

          <div className={classes.block}>
            <label>掩码</label>
            <input
              type="text"
              id="mask"
              onChange={onChangeHandler}
              value={updateData?.mask || ""}
            ></input>
          </div>

          <div className={classes.block}>
            <label>总个数</label>
            <input
              type="text"
              id="total"
              onChange={onChangeHandler}
              value={updateData?.total || ""}
            ></input>
          </div>

          <div className={classes.block}>
            <label>容量(GB)</label>
            <input
              type="Number"
              id="Size"
              onChange={onChangeHandler}
              value={updateData?.Size || ""}
            ></input>
          </div>

          <div className={classes.block}>
            <label>字典目录(左)</label>
            <select
              id="dic"
              onChange={onChangeHandler}
              value={updateData?.dic || ""}
            >
              <option>无</option>
              {dicFileLists?.data.map((sing) => {
                return <option key={sing}>{sing}</option>;
              })}
            </select>
          </div>

          <div className={classes.block}>
            <label>字典目录(右)</label>
            <select
              id="dic_others"
              onChange={onChangeHandler}
              value={updateData?.dic_others || ""}
            >
              <option>无</option>
              {dicFileLists?.data.map((sing) => {
                return <option key={sing}>{sing}</option>;
              })}
            </select>
          </div>

          <div className={classes.block}>
            <label>父组合</label>
            <input
              type="text"
              id="parent"
              onChange={onChangeHandler}
              value={updateData?.parent || ""}
            ></input>
          </div>

          <div className={classes.block}>
            <label>开始增量</label>
            <input
              type="checkbox"
              id="increment"
              onChange={onChangeHandler}
              checked={updateData?.increment || ""}
              className={classes.inputModify}
            ></input>
          </div>

          <div className={classes.block}>
            <label>自动添加</label>
            <input
              type="checkbox"
              id="auto_enable"
              onChange={onChangeHandler}
              checked={updateData?.auto_enable}
              className={classes.inputModify}
            ></input>
          </div>

          <div
            className={classes.block}
            style={{
              display: updateData?.increment === true ? "grid" : "none",
            }}
          >
            <label>增量开始于</label>
            <input
              type="text"
              id="increment_min"
              onChange={onChangeHandler}
              value={updateData?.increment_min || ""}
            ></input>
          </div>

          <div
            className={classes.block}
            style={{
              display: updateData?.increment === true ? "grid" : "none",
            }}
          >
            <label>增量结束于</label>
            <input
              type="text"
              id="increment_max"
              onChange={onChangeHandler}
              value={updateData?.increment_max || ""}
            ></input>
          </div>

          <div className={classes.buttonBlock}>
            <button
              className={classes.btn}
              onClick={async () => {
                if (whatAmiDo === "xinjian") {
                  await postOne(updateData);
                } else {
                  await patchOne(updateData);
                }

                toggle();
              }}
              style={{
                textAlign: "center",
                display: `${whatAmiDo ? "block" : "none"}`,
                backgroundColor: "yellowgreen",
                border: "0px",
              }}
            >
              {whatAmiDo === "xinjian" ? "新建" : "修改"}
            </button>

            <button
              className={classes.btn}
              style={{
                display: `${whatAmiDo === "xiugai" ? "block" : "none"}`,
                backgroundColor: "pink",
                border: "0px",
              }}
              onClick={async () => {
                const ifDel = confirm("确定删除吗");
                if (!ifDel) return;

                await deleteOne(updateData);
                alert("删除成功");

                toggle();
              }}
            >
              删除
            </button>
          </div>
        </div>
        <Input.TextArea
          id="note"
          value={updateData?.note}
          onChange={onChangeHandler}
        />
      </div>
    </Modal>
  );
}

export default DicEditModal;
