// import {
//   Modal,
//   Form,
//   Select,
//   RadioGroup,
//   Radio,
//   useFormState,
//   Slider,
//   InputNumber,
// } from "@douyinfe/semi-ui";
import { useGetDicFileListsQuery } from "../Api/ZidianApi";
import { useEffect, useState, useRef } from "react";
import { usePostOneMutation } from "../Api/TaskApi";
import {
  Modal,
  Form,
  Select,
  RadioGroup,
  Radio,
  Input,
  message,
  Table,
} from "antd";
import { Option } from "antd/es/mentions";
import { calculatePossibilities } from "../Client/utils";

/**
 * # Snapchat模态窗口（手动）
 *
 * ## State
 * - DicFileList:字典文件列表
 * - snapchatData:Snapchat数据
 * - tongjiData：用于显示统计数据
 *
 *
 * ## 函数
 * - postTask 用于提交一个task
 * - onChangeHandler 用于改变表单值
 *
 *
 */
function Snapchat({ visiable, toggle, business_id, wifi_name, xianyu_name }) {
  const [snapchatData, putSnapchatData] = useState({});
  const [tongjiData, putTongjiData] = useState({
    total: 0,
    Size: 0,
  });

  //提交新Task

  const [postTask] = usePostOneMutation();

  //用于formRef

  const formRef = useRef();

  // 用于字典列表

  const [DicFileList, putDicFileList] = useState([]);

  const { data: DicFileListResult } = useGetDicFileListsQuery();

  useEffect(() => {
    if (!DicFileListResult?.data) return;

    const dicFileList = DicFileListResult.data;
    putDicFileList(dicFileList);
  }, [DicFileListResult]);

  function onChangeHandler(currentVar, allVar) {
    if (allVar?.mask) {
      putTongjiData(calculatePossibilities(allVar.mask));
      // console.log();
    }

    if (allVar?.canshu) {
      let mask = allVar.canshu;

      switch (allVar?.Snapchat_type) {
        case "pinyin_fan":
          //拼音泛组合
          mask = mask + "?l?l?l?l?l?l";
          break;
        case "shuzi_fan":
          //数字泛组合
          mask = mask + "?d?d?d?d?d?d?d?d";
          break;
        case "teshu_fan":
          //特殊字符泛组合
          mask = mask + "?a?a?a?a";
          break;
      }

      console.log(calculatePossibilities(mask));
      putTongjiData(calculatePossibilities(mask));
    }

    putSnapchatData(allVar);
  }

  return (
    <Modal
      title="Snapchat任务（手动）"
      onCancel={toggle}
      open={visiable}
      onOk={() => {
        // console.log("snapchatData", snapchatData);
        if (!snapchatData?.Snapchat_type) {
          message.error("请选择类型");
          return;
        }

        if (["0", "1", "3", "6", "7"].includes(snapchatData?.Snapchat_type)) {
          //普通任务
          handle_normal_task(snapchatData);
        } else {
          //特殊任务（代号任务）
          handle_special_task(snapchatData);
        }

        //0.处理普通任务
        function handle_normal_task(data) {
          if (!data?.Snapchat_type) {
            message.error("请填写完整");
            return;
          }

          // putTongjiData();

          post({ zidian_name: data.mask, ...data });
        }

        //1.处理特殊任务
        function handle_special_task(data) {
          switch (data.Snapchat_type) {
            case "pinyin_fan":
              //拼音泛组合
              handle_pinyin_fan();
              break;
            case "shuzi_fan":
              //数字泛组合
              handle_shuzi_fan();
              break;
            case "teshu_fan":
              //特殊字符泛组合
              handle_teshu_fan();
              break;
            default:
              message.error("暂不支持该类型");
              return;
          }

          function handle_pinyin_fan() {
            const canshus = handle_大小写(data.canshu);
            canshus.forEach((canshu) => {
              const zidian_name = `${canshu}开头的 0-6位拼音泛组合`;
              const mask = `${canshu}?l?l?l?l?l?l`;

              post({
                ...data,
                zidian_name,
                mask,
                increment: true,
                increment_min: canshu.length + 0,
                increment_max: canshu.length + 6,
              });
            });
          }
          function handle_shuzi_fan() {
            const canshus = handle_大小写(data.canshu);
            canshus.forEach((canshu) => {
              const zidian_name = `${canshu}开头的 0-8位数字泛组合`;
              const mask = `${canshu}?d?d?d?d?d?d?d?d`;

              post({
                ...data,
                zidian_name,
                mask,
                increment: true,
                increment_min: canshu.length + 0,
                increment_max: canshu.length + 8,
              });
            });
          }
          function handle_teshu_fan() {
            const canshus = handle_大小写(data.canshu);
            canshus.forEach((canshu) => {
              const zidian_name = `${canshu}开头的 0-4位特殊泛组合`;
              const mask = `${canshu}?a?a?a?a`;

              post({
                ...data,
                zidian_name,
                mask,
                increment: true,
                increment_min: canshu.length + 0,
                increment_max: canshu.length + 4,
              });
            });
          }
        }

        //2.封装的post 提交任务
        async function post(readyPost) {
          const tem = {
            wifi_name: wifi_name,
            business_object_id: business_id,
            zidian_name: `Snapchat:${readyPost.zidian_name}`,
            xianyu_name: xianyu_name,
            status: "wating",
            priority: 4, //Snapchat任务，具有最高优先级
            total: tongjiData?.total, //定制化任务默认99999999行
            Size: tongjiData?.Size, //定制化任务默认0.8G
            Snapchat: {
              ...readyPost,
              type: "Snapchat",
            },
          };

          console.log(tem);

          await postTask(tem);
          message.success("任务提交成功");
        }

        //3.把参数分成三份;
        function handle_大小写(canshu) {
          const lowercase = String(canshu).toLowerCase(); // 纯小写
          const uppercase = String(canshu).toUpperCase(); // 全大写
          const capitalize =
            String(canshu).charAt(0).toUpperCase() +
            String(canshu).slice(1).toLowerCase(); // 大写开头

          return [lowercase, uppercase, capitalize];
        }
      }}
    >
      <Form
        onValuesChange={onChangeHandler}
        name="Snapchat任务（手动）"
        // initValues={{ mask: "?d?d?d?d?d?d" }}
        // getFormApi={(formApi) => {
        //   formRef.current = formApi;
        // }}
        // onChange={(e) => {
        //   putType(e.values.Snapchat_type);
        //   console.log(theType);
        // }}
      >
        <Form.Item name="Snapchat_type" label="攻击类型">
          <Select>
            <Option value={"0"}>-a 0 字典攻击</Option>
            <Option value={"1"}>-a 1 组合攻击 </Option>
            <Option value={"6"}>-a 6 字典+掩码</Option>
            <Option value={"7"}>-a 7 掩码+字典 </Option>
            <Option value={"3"}>-a 3 掩码攻击 </Option>
            <Option value={"pinyin_fan"}>🚀拼音泛组合</Option>
            <Option value={"shuzi_fan"}>🚀数字泛组合</Option>
            {/* <Option value={"shoujihao_fan"}>🚀手机号泛组合</Option> */}
            <Option value={"teshu_fan"}>🚀特殊泛组合</Option>
          </Select>
        </Form.Item>

        <Form.Item name="wifi_name" label="wifi名称" initialValue={wifi_name}>
          <Input disabled />
        </Form.Item>

        {["0", "1", "3", "6", "7"].includes(snapchatData?.Snapchat_type) && (
          <div data-description="正规掩码任务">
            <Form.Item name="mask" label="掩码">
              <Input />
            </Form.Item>

            <Form.Item name="dic" label="左侧字典">
              <Select>
                {DicFileList.map((dic) => (
                  <Select.Option key={dic} value={dic}>
                    {dic}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="dic_others" label="右侧字典">
              <Select>
                {DicFileList.map((dic) => (
                  <Select.Option key={dic} value={dic}>
                    {dic}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}

        {["pinyin_fan", "shuzi_fan", "shoujihao_fan", "teshu_fan"].includes(
          snapchatData?.Snapchat_type
        ) && (
          <div data-description="前端服务的特殊Snapchat任务">
            <Form.Item name="canshu" label="参数">
              <Input />
            </Form.Item>
          </div>
        )}
      </Form>

      <p>
        掩码： 容量：{tongjiData?.Size}G 行数：{tongjiData?.total}
      </p>
    </Modal>
  );
}

export default Snapchat;
