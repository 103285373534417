import { createSlice } from "@reduxjs/toolkit";

const href = location.href;

export const configure = createSlice({
  name: "configure",
  initialState: {
    serverAddress:
      href.includes("api") ||
      href.includes("localhost") ||
      href.includes("192.168")
        ? "http://api.ccwifi.cc:20000" //开发环境
        : "https://api.ccwifi.cc:10001", //生产环境
    speed: 250000,
  },
  reducers: {},
});

export const { changeServerAddress } = configure.actions;
